<div class="templates">
  <a class="blank-template" [routerLink]="'/wizard/builder'">
    <h4 class="template-title"><br></h4>
    <div mat-ripple class="card">
      <h4>Skip template</h4>
    </div>
  </a>
  <a [routerLink]="['/wizard/builder']" [queryParams]="{template:template.name }" queryParamsHandling="merge" *ngFor="let template of templates">
    <h4 class="template-title">{{template.name}}</h4>
    <img  mat-ripple class="shadow" [src]="template.img" alt="">
  </a>
</div>
