import {AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChildren} from "@angular/core";
import {VIEW_MODE} from "../../../enum";
import {Gallery} from "@mrbeany/stacks_shared/lib/models/card-elements.module";
import {IdService} from "@app/services/id.service";
import {readAndCompressFiles} from "@app/services/upload.service";
import {DialogService} from "@app/services/dialog.service";
import {GalleryEditorComponent} from "@app/card.components/card.elements/gallery/list-editor/gallery-editor.component";
import {StackService} from "@app/card.components/stack/stack.service";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements OnInit, AfterViewInit {
  @Input() gallery!: Gallery;
  @Input() viewMode: VIEW_MODE = VIEW_MODE.DISPLAY;
  @Input() submitted = false;
  galleryId: string | undefined;
  editing = false;


  constructor(
    private idService: IdService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.galleryId = this.idService.getId("img").toString();
  }

  ngAfterViewInit() {

  }

  setAspectRatio(ratio: '21/9' | '16/9' | '5/4' |'1/1'| 'source') {
    this.gallery.config.format = ratio;
  }


  addGallery(files: any) {
    // images to preview
    readAndCompressFiles(files).then((compressedFiles) => {
      const images = compressedFiles
        ?.filter((file) => file instanceof Blob)
        .map((file) => URL.createObjectURL(file as Blob));
      if (images && images.length === files.length) {
        this.gallery.images  = images;
      }
    });
  }

  onDropFiles($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    const files = Array.from($event.dataTransfer.files);
    this.addGallery(files);
  }
  onSelectFiles($event: any) {
    const files: Array<File> = Array.from($event.target["files"]); // images to upload
    this.addGallery(files);
  }

  prevent($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  manageImages() {
    //@ts-ignore
    this.dialogService.openDialog(GalleryEditorComponent, this.gallery.images, (res: string[] | undefined) => {
      if(!res?.length) {
        return
      } else {
        this.gallery.images = res;
      }
    });
  }
}
