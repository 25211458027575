import {AfterViewInit, Component, Input, OnDestroy, OnInit, Signal, signal} from "@angular/core";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ClipboardService} from "@app/services/clipboard.service";
import {SnackbarService} from "@app/services/snackbar.service";
import {patchArrayValues} from "@app/helpers/helpers";
import {ChannelsService} from "@app/services/channels/channels.service";
import {DialogComponent} from "@app/modules/dialog/dialog/dialog.component";
import {DialogService} from "@app/services/dialog.service";
import ChannelDTO = DTOs.ChannelDTO;
import Template = DTOs.Template;
import {tap} from "rxjs/operators";
import {finalize} from "rxjs";
import {ChannelApiService} from "@app/services/channel-api/channel-api.service";
const clone = require("clone");

@Component({
  selector: "app-channel-config",
  templateUrl: "./channel-config.component.html",
  styleUrls: ["./channel-config.component.scss"],
})
export class ChannelConfigComponent implements OnInit, AfterViewInit, OnDestroy {
  private _channel!: ChannelDTO;
  selectedTemplates: Array<Template> = [];
  @Input() set channel(channel: ChannelDTO) {
    if (channel?.config) {
        this.form?.patchValue(channel.config);
        this.updateTemplatesArray(channel.config?.template?.templates);
        this._channel = channel;

    }
  }
  get channel() {
    return this._channel;
  }
  form: FormGroup<any>;
  fb = new FormBuilder();
  configLoading = false;
  deletingChannel = false;
  formBackup: FormGroup | any;
  formChanges$: any;

  constructor(
    private clipboardService: ClipboardService,
    private snackbar: SnackbarService,
    private channelsService: ChannelsService,
    private channelsApi: ChannelApiService,
    private dialogService: DialogService
  ) {
    this.form = this.fb.group({
      name: ["", [Validators.maxLength(42), Validators.required]],
      public: [false],
      template: this.fb.group({
        defaultTemplate: [undefined],
        forceTemplate: [false],
        templates: this.fb.array([]),
      }),
      layout: undefined
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.formBackup = clone(this.form);
  }

  ngOnDestroy() {
    if(!this.formChanges$) {
      return;
    }
    this.formChanges$.unsubscribe();
  }

  updateLayout() {
    const config = this.form.getRawValue()
    this.channelsService.applyConfig({
      ...this.channel,
      config: config
    })
  }

  selectionListChange(selectionList: any) {
    const selection: Array<Template> = selectionList.selectedOptions.selected.map(
      (option: any) => option.value
    );
    this.selectedTemplates = [...selection];
  }

  pasteCardID() {
    if (!this.clipboardService.getCardInfo()) {
      this.snackbar.showSnackbar("First get a link from the card options");
      return;
    }
    const cardID = this.clipboardService.getCardInfo()?.cardId;
    const cardName = this.clipboardService.getCardInfo()?.cardTitle;
    const array = this.form?.get("template.templates") as FormArray;
    array.push(
      new FormControl<Template>({
        cardId: cardID as string,
        name: cardName as string,
      })
    );
    this.form?.markAsDirty();
  }

  updateTemplatesArray(values: any[]) {
    const templatesArray = this.form.get("template.templates") as FormArray;
    if (templatesArray) {
      patchArrayValues(values, templatesArray);
    }
  }

  removeTemplates(selectedTemplates: any) {
    const updatedList = this.form
      ?.get("template.templates")
      ?.value.filter((template: Template) => !selectedTemplates.includes(template));
    this.updateTemplatesArray(updatedList);
    this.form.markAsDirty();
  }

  submitForm(form: FormGroup) {
    this.configLoading = true;
    this.channelsApi
      .submitChannelSettings(
        form.getRawValue(),
        this.channel.id
      )
      .pipe(
        tap((channel) => {
          if (channel.config) {
            const channelConfig = {
              name: channel.config.name,
              public: channel.config.public,
              channelRoles: channel.config.channelRoles,
              template: channel.config.template,
              layout: channel.config.layout
            };
            this.form?.reset({
              name: channel.config.name,
              public: channel.config.public,
              template: {
                defaultTemplate: channel.config.template.defaultTemplate,
                forceTemplate: channel.config.template.forceTemplate,
              },
              layout: channel.config.layout
            });
            this.updateTemplatesArray(channel.config.template.templates);
            this.channelsService.applyConfig(channel);
          }
        }),
        finalize(() => {
          this.configLoading = false;
          this.form.markAsPristine();
        })
      )
      .subscribe();
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.cardId === o2?.cardId;
  }

  deleteChannel(id : string) {
    this.dialogService.openDialog(
      DialogComponent,
      {
        title: "Delete Channel",
        message: "Are you sure you want to delete this channel? This can not be undone.",
      },
      () => {
        this.deletingChannel = true;
        this.channelsService.deleteChannel(id).finally(() => (this.deletingChannel = false));
      }
    );
  }

  onCancel() {
    this.form = clone(this.formBackup);
  }
}
