<div class="wrapper" [class.wrapper--display]="!editing">
  <div #quillToolbar [class.hidden]="!editing">
    <!-- Add buttons as you would before -->
    <button class="ql-header" value="1"></button>
    <button class="ql-header" value="2"></button>
    <button class="ql-header" value="3"></button>
    <button class="ql-bold"></button>
    <button class="ql-italic"></button>
    <button class="ql-link"></button>
    <button class="custom-inset-table"
            [matMenuTriggerFor]="insert_table"
            >
      <svg class="icon">
        <use href="assets/icons/table_add.svg#table_add"></use>
      </svg>
    </button>
    <button [matMenuTriggerFor]="table_options" class="custom-delete-table">
      <svg class="icon">
        <use href="assets/icons/table_more.svg#table_more"></use>
      </svg>
    </button>

    <button class="ql-blockquote"></button>
    <button class="ql-code-block"></button>
    <button class="ql-list" value="bullet" aria-label="list: bullet"></button>
    <button class="ql-list" value="ordered" aria-label="list: ordered"></button>
    <button class="ql-list" value="check" aria-label="list: check"></button>
    <!-- But you can also add your own -->
    <button id="custom-button"></button>
  </div>
  <div #quillContainer [class.ql-container--display]="!editing">
    <!--markdown editor-->

  </div>

</div>
<mat-menu #insert_table="matMenu">
  <app-table-select (createTable)="createTable($event)"></app-table-select>
</mat-menu>
<mat-menu #table_options="matMenu">
  <button (click)="onDeleteTable()">
    <svg class="icon">
      <use href="assets/icons/table_delete.svg#table_delete"></use>
    </svg>
  </button>
  <button (click)="onDeleteColumn()">
    <svg class="icon">
      <use href="assets/icons/column_delete.svg#column_delete"></use>
    </svg>
  </button>
  <button (click)="onAddColumn()">
    <svg class="icon">
      <use href="assets/icons/column_add.svg#column_add"></use>
    </svg>
  </button>
  <button (click)="onAddRow()">
    <svg class="icon">
      <use href="assets/icons/row_add.svg#row_add"></use>
    </svg>
  </button>
  <button (click)="onDeleteRow()">
    <svg class="icon">
      <use href="assets/icons/row_delete.svg#row_delete"></use>
    </svg>
  </button></mat-menu>
