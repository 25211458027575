import {AfterViewInit, ChangeDetectorRef, Component, OnInit, signal, ViewChild} from "@angular/core";
import {Router, RouterOutlet} from "@angular/router";
import {WizardTemplates, WizardService} from "@app/pages/wizard/wizard.service";
import {map, tap} from "rxjs/operators";
import {VIEW_MODE} from "@app/enum";
import {ViewModeStrategy, ViewStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";
import {StackComponent} from "@app/card.components/stack/stack.component";
import {AuthenticationService} from "@app/authentication/authentication.service";
import {UserRole} from "@app/authentication/user";
import {Stack} from "@mrbeany/stacks_shared";
import {createUserStack} from "@app/helpers/helpers";
import {BehaviorSubject, Observable} from "rxjs";

@Component({
  selector: 'app-wizard-builder',
  templateUrl: './wizard-builder.component.html',
  styleUrls: ['./wizard-builder.component.scss']
})
export class WizardBuilderComponent implements OnInit{

  protected readonly VIEW_MODE = VIEW_MODE;
  protected readonly ViewModeStrategy = ViewModeStrategy;

  isLoading = false;

  viewStrat = new BehaviorSubject<ViewStrategy>(ViewModeStrategy.Editor);
  viewStrat$: Observable<ViewStrategy> = this.viewStrat.asObservable();

  @ViewChild(StackComponent) stack!: StackComponent;

  constructor(
    private routerOutlet: RouterOutlet,
    private wizardService: WizardService,
    private router: Router,
  ) {
  }

  stack$ = this.wizardService.selectedTemplate$;


  ngOnInit() {
    this.wizardService.selectedTemplateId.next('none');
    const params = this.routerOutlet.activatedRoute.snapshot.queryParamMap;
    const templateName: any = params?.get('template');
    if (!templateName) {
      this.wizardService.selectedTemplateId.next('none');
      this.viewStrat.next(ViewModeStrategy.Editor);
    } else {
      this.viewStrat.next(ViewModeStrategy.Wizard);
    }
    //@ts-ignore
    const templateId: any = WizardTemplates.find((template) => template?.name === templateName)?.id;
    this.wizardService.selectedTemplateId.next(templateId);
  }




  onSave() {
    openDatabase().then(() => {
      dbSaveStack(this.stack.stack, 'wizard-stack');
      this.router.navigateByUrl('/wizard/share');
    })
  }


  toggeViewStrategy() {
    if (this.viewStrat.getValue() === ViewModeStrategy.Wizard) {
      this.viewStrat.next(ViewModeStrategy.Display)
      return;
    }
    if (this.viewStrat.getValue() === ViewModeStrategy.Display) {
      this.viewStrat.next(ViewModeStrategy.Wizard)
      return;
    }
  }
}
//browser indexed DB
function openDatabase() {
  return new Promise((resolve, reject) => {
    // Open (or create) the database
    const request = indexedDB.open("myDatabase", 1);

    // Create the schema
    request.onupgradeneeded = (event) => {
      //@ts-ignore
      const db = event.target?.result;
      if (!db.objectStoreNames.contains("stacks")) {
        db.createObjectStore("stacks", { keyPath: "id" });
      }
    };

    request.onsuccess = (event) => {
      //@ts-ignore
      resolve(event.target?.result);
    };

    request.onerror = (event) => {
      //@ts-ignore
      reject(`Error opening database: ${event.target?.errorCode}`);
    };
  });
}

/**
 * saving of stack to a local db so the data is not lost when switching routes and logging in or registering a new account
 * @param stack
 * @param id
 */
export async function dbSaveStack(stack: Stack, id: string) : Promise<void> {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    //@ts-ignore
    const transaction = db.transaction(["stacks"], "readwrite");
    const store = transaction.objectStore("stacks");
    const request = store.put({ id: id, stacks: stack });

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = (event: any) => {
      console.error("Error saving blob:", event.target.errorCode);
      reject(`Error saving blob: ${event.target.errorCode}`);
    };
  });
}
/**
 * retrieving of stack from local db
 * @param stack
 * @param id
 */
export async function dbGetStack(id: string) : Promise<Stack> {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    //@ts-ignore
    const transaction = db.transaction(["stacks"], "readonly");
    const store = transaction.objectStore("stacks");
    const request = store.get(id);
    request.onsuccess = (event: any) => {
      const result = event.target.result['stacks'];
      if (result) {
        resolve(result);
      } else {
        reject();
      }
    };

    request.onerror = (event: any) => {
      console.error("Error retrieving blob:", event.target.errorCode);
      reject(`Error retrieving blob: ${event.target.errorCode}`);
    };
  });
}
