import { emailPasswordSignUp } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import * as Sentry from "@sentry/angular-ivy";
//@ts-ignore
export async function signUpClicked(email: string, password: string) {
  try {
    const response = await emailPasswordSignUp({
      formFields: [{
        id: "email",
        value: email
      }, {
        id: "password",
        value: password
      }]
    })

    if (response.status === "FIELD_ERROR") {
      // one of the input formFields failed validaiton
      response.formFields.forEach(formField => {
        if (formField.id === "email") {
          // Email validation failed (for example incorrect email syntax),
          // or the email is not unique.
          window.alert(formField.error)
        } else if (formField.id === "password") {
          // Password validation failed.
          // Maybe it didn't match the password strength
          window.alert(formField.error)
        }
      })
      //@ts-ignore
    } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
      // the reason string is a user friendly message
      // about what went wrong. It can also contain a support code which users
      // can tell you so you know why their sign up was not allowed.
      //@ts-ignore
      window.alert(response.reason);
    } else {
      // sign up successful. The session tokens are automatically handled by
      // the frontend SDK.
      window.location.href = "/wizard"
    }
    return response;
  } catch (err: any) {
    if(err)
      Sentry.captureException(err, {
        extra: err
    });
    if (err.isSuperTokensGeneralError === true) {
      // this may be a custom error message sent from the API by you.
    } else {
    }
  }
}
