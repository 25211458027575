import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {QuillComponent} from "./quill.component";
import {MatMenuModule} from "@angular/material/menu";
import {TableSelectModule} from "@app/custom.components/table-select/table-select.module";



@NgModule({
  declarations: [
    QuillComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    TableSelectModule
  ],
  exports: [
    QuillComponent
  ]
})
export class QuillModule { }
