<form [formGroup]="form" class="element">
  <mat-form-field color="accent">
    <input formControlName="email" type="email" matInput placeholder="Email" />
    <mat-error *ngIf="form.get('email')?.errors?.['required']">Required</mat-error>
    <mat-error *ngIf="form.get('email')?.errors?.['email']">Please enter a valid email address</mat-error>
  </mat-form-field>

  <mat-form-field>
    <input formControlName="password" [type]="showPW.checked ? 'text' :  'password'" placeholder="password" matInput/>
    <mat-error *ngIf="form.get('email')?.errors?.['required']">Required</mat-error>
  </mat-form-field>

  <mat-checkbox #showPW labelPosition="before" color="primary" class="show-password">show password</mat-checkbox>

  <div class='flex-buttons'>
    <button [disabled]="isLoading | async" (submit)="onSignin(form.getRawValue())" mat-flat-button color="primary" (click)='onSignin(form.getRawValue())'>
        <span *ngIf="!(isLoading | async)">
          Login
        </span>
      <app-loading-button-spinner
        *ngIf="isLoading | async"
      ></app-loading-button-spinner>
    </button>
    <div class="navigate">
      Don't have an account yet?
      &nbsp;
      <a role="button" (click)="navigateToSignUp.emit()">Register</a>
    </div>
  </div>
</form>

