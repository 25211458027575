<div class="element-actions" *ngIf="viewMode === VIEW_MODE.EDIT">
  <div class="element-actions-spacer"></div>
  <ng-container *ngIf="!displayMarkdown">
    <div>
      <button
        *ngIf="clickContext?.target?.nodeName !== 'TD' && clickContext?.target?.nodeName !== 'TH'"
        [matMenuTriggerFor]="insert_table"
        mat-icon-button
      >
        <div class="icon add-table-icon"></div>
      </button>
      <!--table actions-->
      <ng-container
        *ngIf="clickContext?.target?.nodeName === 'TD' || clickContext?.target?.nodeName === 'TH'"
      >
        <button mat-icon-button color="warn" (click)="deleteTable()">
          <div class="icon remove-table-icon"></div>
        </button>

        <button mat-icon-button color="warn" (click)="addRow()">
          <div class="icon add-row-icon"></div>
        </button>
        <button mat-icon-button color="warn" *ngIf="clickContext?.row !== 0" (click)="removeRow()">
          <div class="icon remove-row-icon"></div>
        </button>

        <button mat-icon-button color="warn" (click)="addColumn()">
          <div class="icon add-column-icon"></div>
        </button>
        <button mat-icon-button color="warn" (click)="removeColumn()">
          <div class="icon remove-column-icon"></div>
        </button>
      </ng-container>
    </div>
  </ng-container>
  <button *ngIf="displayMarkdown" mat-icon-button (click)="openMarkdownCheatSheet()">
    <mat-icon>info_outlined</mat-icon>
  </button>
  <mat-slide-toggle color="primary" (click)="toggleEditor()" [checked]="this.displayMarkdown">Markdown Editor</mat-slide-toggle>
</div>

<div *ngIf="viewMode === VIEW_MODE.EDIT" class="text-edit-wrapper">
  <!--markdown editor-->
  <textarea
    *ngIf="displayMarkdown"
    #textTextfield
    matInput
    class="inset-shadow"
    type="text"
    placeholder="Your Markdown text goes here... Click the info button to learn more"
    [(ngModel)]="text().text"
    [ngClass]="{'textarea-invalid': !isValid && submitted}"
    cdkTextareaAutosize
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="10"
    (ngModelChange)="renderHTML()"
    autofocus
  >
  </textarea>
  <!--text editor-->
  <div
    #editorText
    *ngIf="!displayMarkdown"
    class="displayed-tex inset-shadow"
    [class.displayed-text--edit]="viewMode === VIEW_MODE.EDIT"
    contenteditable="true"
    autofocus="true"
    (click)="setClickContext($event.target)"
    (keydown)="updateMarkdown()"
    (blur)="updateMarkdown()"
    (mouseleave)="updateMarkdown()"
    [innerHTML]="html"
    (change)="html = editorText.innerHTML"
  ></div>
</div>

<div *ngIf="viewMode === VIEW_MODE.DISPLAY" [innerHTML]="html" class="displayed-text"></div>

<mat-menu #insert_table="matMenu">
  <app-table-select (createTable)="addTable($event)"></app-table-select>
</mat-menu>
