import { NgModule } from '@angular/core';
import {ProductElementComponent} from "./product-element.component";
import {PlaceholderElementModule} from "@app/core/placeholder/placeholder-element.module";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {DirectivesModule} from "@app/directives/directives.module";
import {GalleryModule} from "@app/card.components/card.elements/gallery/gallery.module";
import {PipesModule} from "@app/pipes/pipes.module";
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [ProductElementComponent],
  imports: [
    PlaceholderElementModule,
    CommonModule,
    MatIconModule,
    FormsModule,
    DirectivesModule,
    GalleryModule,
    PipesModule,
  ],
  exports: [ProductElementComponent],
})
export class ProductElementModule {}
