import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-create-network-dialog',
  templateUrl: './create-network-dialog.component.html',
  styleUrls: ['./create-network-dialog.component.scss'],
})
export class CreateNetworkDialogComponent {
  constructor(public dialogRef: MatDialogRef<CreateNetworkDialogComponent>) {}
  close() {
    this.dialogRef.close();
  }
}
