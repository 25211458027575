import {Component, EventEmitter, Input, Output} from "@angular/core";
import {StackService} from "@app/card.components/stack/stack.service";
import {Hyperlink} from "@mrbeany/stacks_shared/lib/models/card-elements/link";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  ConfirmExternalNavigationDialogComponent
} from "@app/card.components/card.elements/hyperlink/confirm-external-navigation-dialog/confirm-external-navigation-dialog.component";
import {DialogService} from "@app/services/dialog.service";
import {CardNavEvent} from "@app/card.components/card/card.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-hyperlink',
  templateUrl: './hyperlink.component.html',
  styleUrls: ['./hyperlink.component.scss']
})
export class HyperlinkComponent {

  @Input({required: true}) hyperlink!: Hyperlink;
  @Output() navigateTo  = new EventEmitter<CardNavEvent>();

  editing = false;
  constructor(
    private stackService: StackService,
  ) {}
  viewStrat$ = this.stackService.viewStrategy$
  protected readonly environment = environment;
}
