import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
  signal,
  ViewChild,
  ViewChildren
} from "@angular/core";
import {EmailSub} from "@mrbeany/stacks_shared/lib/models/card-elements/emailSub";
import {VIEW_MODE} from "../../../enum";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import AddEmailSubDTO = DTOs.AddEmailSubDTO;
import {BehaviorSubject, catchError, combineLatest, finalize, throwError} from "rxjs";
import {map, tap} from "rxjs/operators";
import {SnackbarService} from "@app/services/snackbar.service";
import getEmailSubTable = DTOs.getEmailSubTable;
import {AuthenticationService} from "@app/authentication/authentication.service";
import {FormControl} from "@angular/forms";
import {CustomValidators} from "@app/custom-validators";

@Component({
  selector: 'app-email-sub',
  templateUrl: './email-sub.component.html',
  styleUrls: ['./email-sub.component.scss']
})
export class EmailSubComponent implements OnInit{
  @Input() cardId: string | undefined;
  @Input() cardTitle: string | undefined;
  @Input() emailSubElement!: EmailSub;
  @Input() set cardOwnerName(cardOwnerName : string| undefined) {
    this.ownerName.next(cardOwnerName)
  }
  @ViewChild('title') title?: ElementRef;
  @ViewChild('description') description?: ElementRef;

  editing = false;
  ownerName = new BehaviorSubject<string| undefined>(undefined);
  ownerName$ = this.ownerName.asObservable();
  currentUserName$ = this.authenticationService.user$.pipe(map((user) => user.name))
  isOwner$ = combineLatest(this.ownerName$, this.currentUserName$).pipe(
    map(([ownerName, userName]) => {
      return ownerName === userName
    })
  )
  emailAddress = ''
  validateEmail = false;
  emailFormControl = new FormControl('', CustomValidators.email());
  emailSubLoadState = signal<LoadState>('none');
  csvDownloadState  = signal<LoadState>('none');
  constructor(
    private http: HttpClient,
    private snackbar: SnackbarService,
    private authenticationService: AuthenticationService
  ) {
  }

  setEditing(editing: boolean ) {
    this.editing = editing;
    setTimeout(() => {
      if(this.editing) {
        if(this.title && this.emailSubElement.title) {
          this.title.nativeElement.innerText = this.emailSubElement.title
        }
        if(this.description && this.emailSubElement.description) {
          this.description.nativeElement.innerText = this.emailSubElement.description
        }
     }
    }, 0)
  }

  ngOnInit() {
  }

  downloadEmails() {
    if (!this.emailSubElement.id || !this.cardId) {
      return;
    }
    const dto: getEmailSubTable = {
      cardId: this.cardId,
      fileTitle: this.emailSubElement.title || this.cardTitle || '',
      elementId: this.emailSubElement.id,
    }
    this.csvDownloadState.set('loading');
    this.http.get(environment.baseUrlServer + '/email_sub', {
      //@ts-ignore
      params: dto,
      //@ts-ignore
      responseType: 'blob'
    }).pipe(
      tap((blob) => {
        //trigger download
        const a = document.createElement('a');
        //@ts-ignore
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'mailing-list.csv';
        a.click();
        URL.revokeObjectURL(objectUrl);
      }),
      finalize(() => {
        this.csvDownloadState.set('none');
      })
    )
    .subscribe()
  }

  subscribeEmail(email: string | null) {
    this.validateEmail = true;
    if (this.emailFormControl.invalid || !email || !this.emailSubElement.id) {
      return
    }
    const dto : AddEmailSubDTO = {
      email: email,
      elementId: this.emailSubElement.id
    }
    this.emailSubLoadState.set('loading');
    this.http.post(environment.baseUrlServer + '/email_sub', dto).pipe(
      catchError((err) => {
        this.emailSubLoadState.set('error');
        this.snackbar.showSnackbar('An error occured, please try again');
        return throwError(err);
      }),
      tap((res) => {
        this.emailSubLoadState.set('success');
      }),
    )
    .subscribe()
  }
}
export type LoadState = 'loading' | 'error' | 'none' | 'success'
