<app-channel></app-channel>
<ng-container *ngIf="!(feedIsLoading$ | async)">
  <app-feed [layout]="layout$ | async"></app-feed>
</ng-container>
<div *ngIf="feedIsLoading$ | async" class="spinner-container">
  <mat-spinner class="button-loading-spinner" diameter="50" strokeWidth="4"></mat-spinner>
</div>
<app-requests></app-requests>
<div *ngIf="showSingleCardView" class="mask">
  <div id="singleCardContainer">
    <app-stack [viewStrategy]="ViewModeStrategy.Display"></app-stack>
    <button mat-icon-button id="cancel-button" (click)="showSingleCardView = false">
      <mat-icon> remove_circle </mat-icon>
    </button>
  </div>
</div>

