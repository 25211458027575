import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Button} from "@mrbeany/stacks_shared/lib/models/card-elements/button";
import {ButtonContainer} from "@mrbeany/stacks_shared/lib/models/card-elements/button-container";
import {ClipboardService} from "@app/services/clipboard.service";
import {SnackbarService} from "@app/services/snackbar.service";
import {AuthenticationService} from "@app/authentication/authentication.service";
import {StackService} from "@app/card.components/stack/stack.service";
import {ViewModeStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";
import {CardNavEvent} from "@app/card.components/card/card.component";

@Component({
  selector: "app-button-container",
  templateUrl: "./button-container.component.html",
  styleUrls: ["./button-container.component.scss"],
})
export class ButtonContainerComponent implements OnInit {
  private _buttonContainer!: ButtonContainer;
   get buttonContainer() {
    return this._buttonContainer;
  }
  @Output() navigateTo = new EventEmitter<CardNavEvent>();
  @Input() owner?: string;
  @Input() set buttonContainer(buttonContainer: ButtonContainer) {
    this._buttonContainer = Object.assign(new ButtonContainer(), buttonContainer);
  }
  editing = false;
  button: Button = new Button();
  user$ = this.authenticationService.user$

  constructor(
    private clipboardService: ClipboardService,
    private authenticationService: AuthenticationService,
    private snackbar: SnackbarService,
    private stackService: StackService,
  ) {}
  viewModeStrat$ = this.stackService.viewStrategy$



  ngOnInit(
  ) {

  }

  pasteCardID() {
    if (!this.clipboardService.getCardInfo()) {
      this.snackbar.showSnackbar("First get a link from the card options");
      return;
    }
    const cardID = this.clipboardService.getCardInfo()?.cardId;
    let buttonDescription = this.clipboardService.getCardInfo()?.cardTitle;

    if (buttonDescription === "") {
      buttonDescription = "#" + (this.buttonContainer.buttonList.length + 1);
    }
    this.buttonContainer.pushButton(new Button(buttonDescription, cardID));
  }

  navigateToCard(button: Button) {
    if(this.stackService.viewStrategySnapshot() !== ViewModeStrategy.Display) {
      return;
    };
    this.navigateTo.emit({
      link: button.CardID,
      label: button.description
    });
  }

  preventEvent($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  deleteButton(index: number) {
    this._buttonContainer.buttonList.splice(index, 1);
  }

  // onAddCard() {
  //   if (this.addButtonClicked === false) {
  //     this.addButtonClicked = true;
  //     this.button = new Button();
  //     console.log(this.addButtonClicked, this.button);
  //   } else {
  //     if (this.button.description.length <= 0) {
  //       this.snackbarService.showSnackbar("no button label given");
  //       console.log("no label");
  //     } else {
  //       const card = new Card(this.button.description);
  //       this.createrService.addCard(card);
  //       this.createrService.addButtonToButtonList(
  //         this.button.description,
  //         card.id,
  //         this.index
  //       );
  //       this.addButtonClicked = false;
  //       console.log(this.addButtonClicked, this.button);
  //     }
  //   }
  // }
  linktToNewCard() {
    this.buttonContainer.pushButton(new Button('', undefined, true))
  }

  protected readonly ViewModeStrategy = ViewModeStrategy;
}


