import {Component, OnInit} from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {AuthenticationService} from '../../authentication/authentication.service';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-set-profile-picture-button',
  templateUrl: './set-profile-picture-button.component.html',
  styleUrls: ['./set-profile-picture-button.component.scss'],
})
export class SetProfilePictureButtonComponent implements OnInit {
  uploading = false;
  user$: Subscription | undefined;

  constructor(
    private uploadService: UploadService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authenticationService.user$
      .pipe(
        tap(() => {
          this.uploading = false;
        })
      )
      .subscribe();
  }
  onFileSelected($event: Event) {
    // @ts-ignore
    const file = $event.target['files'][0];
    this.uploading = true;
    // make a proper promise off this method and handle uploading through this and
    // not with the subscription above
    this.uploadService.uploadAvatar(file);
  }
}
