import {Component, EventEmitter, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {signInClicked} from "@app/authentication/signin/supertokens.signin";
import * as Sentry from "@sentry/angular-ivy";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent {

  @Output() isLoading = new EventEmitter<boolean>(false);
  @Output() navigateToSignUp = new EventEmitter<void>;

  form: FormGroup = new FormGroup({
    email: new FormControl(
      '',
      [
        Validators.required,
      ]
    ),
    password: new FormControl(
      '',
      [
        Validators.required,
      ]
    )
  })

  constructor() {
  }
  onSignin(form: {
    email: string,
    password: string
  }) {
    if (this.form.invalid) {
      return;
    }
    this.isLoading.emit(true);
    signInClicked(form.email, form.password).catch((err) => {}).finally(() => {
      this.isLoading.emit(false);
    });
  }
}
