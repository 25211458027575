import {AfterViewInit, Component, OnDestroy, signal} from "@angular/core";
import {ViewChild} from "@angular/core";
import {MatTabGroup} from "@angular/material/tabs";
import {
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  mergeAll,
  mergeMap,
  pairwise,
  startWith,
  tap,
} from "rxjs/operators";
import {ChannelsService} from "@app/services/channels/channels.service";
import {combineLatest, finalize, forkJoin, fromEvent, merge, Observable, of, Subscription, zip} from "rxjs";
import {AppService} from "@app/services/navbar/app.service";
import {NetworkService} from "@app/services/network.service";
import {calculateTake} from "@app/main/home/feed/feed.component";
import {CardEditorService} from "@app/services/card-editor.service";
import {FeedContextService} from "@app/services/feed-context.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {ActivatedRoute, RouterStateSnapshot} from "@angular/router";

@Component({
  selector: "app-mobile-view",
  templateUrl: "./mobile-view.component.html",
  styleUrls: ["./mobile-view.component.scss"],
})
export class MobileViewComponent implements AfterViewInit, OnDestroy {
  @ViewChild("tabGgroup", {static: true}) tabGgroup: MatTabGroup | undefined;
  scrollDir$!: Observable<"up" | "down" | undefined>;
  scrollTop = signal(0);
  showCardCreator = signal<boolean | undefined>(false);
  layout = signal('feed');
  selectedTab = 0;
  virtualScollContainer: any;
  bottomOffset = 1000;
  scroll$ : Subscription | undefined;
  reachedBottom = false;
  loadingNextPage = false;
  feedBreakpoint = true;
  contextLayout$  = this.feedContextService.layout$;
  breakPoint$ = this.breakpoint.observe('(max-width: 600px)').pipe(map((res) => res.matches));
  layout$ = combineLatest([this.contextLayout$, this.breakPoint$]).pipe(
    map(([layout, breakpoint]) => {
      if(breakpoint) {
        return 'feed'
      } else {
        return layout
      }
    })
  )


  canPost$: Observable<boolean | undefined> = this.channelService.selectedChannel$.pipe(
    map((channel) => channel?.channelRole?.permissions?.postCard),
  );
  channelName$ = combineLatest([
    this.channelService.selectedChannel$.pipe(map((channel) => channel?.channelName)),
    this.networkService.selectedNetwork$.pipe(map((network) => network?.config.name)),
  ]).pipe(
    map((res) => {
      const channelName = res[0];
      const networkName = res[1];
      if (channelName) {
        return channelName;
      }
      if (networkName) {
        return networkName;
      }
      return "";
    })
  );
  constructor(
    private channelService: ChannelsService,
    private networkService: NetworkService,
    private cardEditorService: CardEditorService,
    private feedContextService: FeedContextService,
    private breakpoint: BreakpointObserver,
    private route: ActivatedRoute
  ) {}
  ngAfterViewInit() {
    // set scrollDir$
    this.virtualScollContainer = document.getElementsByClassName("mat-mdc-tab-body-content")[1];
    if(this.route.snapshot.queryParamMap.get('channel') ||  this.route.snapshot.queryParamMap.get('network')) {
      if(this.route.snapshot.queryParamMap.get('channel') !== 'my_cards') {
        this.selectedTab = 1;
      }
    }

    this.scrollDir$ = fromEvent(this.virtualScollContainer, "scroll").pipe(
      startWith(undefined),
      map(() => this.virtualScollContainer.scrollTop),
      pairwise(),
      map(([prev, curr]: Event[]) => {
        return curr < prev ? "up" : "down";
      }),
      distinctUntilChanged()
    );
    this.scroll$ = fromEvent(this.virtualScollContainer, "scroll").pipe(
      tap(() => {
        const scrollTopDistance = this.virtualScollContainer.scrollTop;
        const scrollHeight = this.virtualScollContainer.scrollHeight;
        const containerHeight = this.virtualScollContainer.clientHeight;
        const distanceToBottom = scrollHeight - scrollTopDistance - containerHeight;
        const reachedBottom = distanceToBottom - this.bottomOffset <= 0;
        this.scrollTop.set(this.virtualScollContainer.scrollTop)
        //only emits when changed
        if(this.reachedBottom === reachedBottom) {
          return
        }
        this.reachedBottom = reachedBottom;
        if(this.reachedBottom) {
          this.loadNextPage();
        }

      })
    ).subscribe()
  }

  ngOnDestroy() {
      this.scroll$?.unsubscribe();
  }

  createCard() {
    this.virtualScollContainer.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.showCardCreator.set(true);
    this.cardEditorService.showEditor(true);
  }
  refreshFeed() {
    const channel = this.channelService.getSelectedChannelSnapshot();
    if (channel) {
      this.channelService.selectChannel(channel.id);
      this.virtualScollContainer.scrollToOffset(0);
    }
  }

  onCancelCardCreation() {
    this.showCardCreator.set(false);
  }

  loadNextPage() {
    if(this.channelService.getSelectedChannelSnapshot()) {
      this.channelService.nextPage(
        15
      ).pipe(
        tap(() => {
          this.loadingNextPage = true;
        }),
        finalize(() => {
          this.loadingNextPage = false;
        })
      ).subscribe();
      return;
    }
    if(this.networkService.getSelectedNetworkSnapshot()) {
      this.networkService.nextPage(
        15
      ).pipe(
        tap(() => {
          this.loadingNextPage = true;
        }),
        finalize(() => {
          this.loadingNextPage = false;
        })
      ).subscribe();
    }  }

  onNavigateTo() {
    if(this.tabGgroup) {
      this.tabGgroup.selectedIndex = 1;
    }
  }
}
