import {Component, EventEmitter, Input, Output} from "@angular/core";
import {NetworkService} from "@app/services/network.service";
import {SnackbarService} from "@app/services/snackbar.service";
import {ChannelsService} from "@app/services/channels/channels.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {tap} from "rxjs/operators";
import {catchError, finalize, of} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {NetworkApiService} from "@app/services/network-api/network-api.service";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ChannelDTO = DTOs.ChannelDTO;

@Component({
  selector: "app-create-channel-form",
  templateUrl: "./create-channel-form.component.html",
  styleUrls: ["./create-channel-form.component.scss"],
})
export class CreateChannelFormComponent {
  @Output() success = new EventEmitter<void>();
  @Input() networkId: string | undefined;
  saving = false;
  form: FormGroup;
  usernameTaken = false;
  constructor(
    private channelService: ChannelsService,
    private snackbarService: SnackbarService,
    private networkAPI: NetworkApiService,
    private networkService: NetworkService
  ) {
    this.form = new FormBuilder().group({
      name: ["", [Validators.maxLength(42), Validators.required]],
    });
  }

  submit(rawValue: {name: string}, networkId: string | undefined) {
    this.saving = true;
    this.channelService
      .createChannel(rawValue.name, this.networkId)
      .pipe(
        tap((res) => {
          this.success.emit();
          this.channelService.addChannel(res);
          if (networkId) {
            this.addChannelToNetwork(res, networkId);
          }
          this.snackbarService.showSnackbar("Successfully created " + rawValue.name);
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.error === "SEC-001") {
            this.usernameTaken = true;
          }
          this.snackbarService.showSnackbar("An unknown error occured while creating the Channel");
          return of(err);
        }),
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe();
  }

  addChannelToNetwork(channel: ChannelDTO, networkId: string) {
    this.networkAPI
      .addChannelsToNetwork(networkId, [channel.id])
      .pipe(
        tap(() => {
          this.networkService.addChannelsToNetwork([channel], networkId);
        }),
        catchError((err) => {
          this.snackbarService.showSnackbar("Error while linking Channel To Network");
          return of(err);
        })
      )
      .subscribe();
  }
}
