<ng-container *ngIf="editing">
  <div *ngIf="!spotify.src" class="spotify-input-wrapper">
    <img ngSrc="assets/icons/social/spotify.svg" width="40" height="40" alt="">
    <div class="input-container">
      <input [(ngModel)]="src" (ngModelChange)="updateEmbedSrc()" placeholder="Spotify Link" type="text">
    </div>
    <button (click)="openHelpDialog()" mat-icon-button>
      <mat-icon>info_outlined</mat-icon>
    </button>
  </div>
  <iframe *ngIf="spotify.src" style="border-radius:12px" [src]="spotify.src | safe" width="100%" [height]="tag !== 'track' ? 400 : 80" frameBorder="0" allowfullscreen="" allow="clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe>
</ng-container>

<ng-container *ngIf="!editing">
    <iframe *ngIf="spotify.src" style="border-radius:12px" [src]="spotify.src | safe" width="100%" [height]="tag !== 'track' ? 400 : 80" frameBorder="0" allowfullscreen="" allow="clipboard-write; encrypted-media; picture-in-picture" loading="lazy"></iframe>
<!--    <div *ngIf="!loadSpofify" class="spotify-placeholder" (click)="loadSpofify = true">
      <span class="button">
        Load Spotify
      </span>
    </div>-->
</ng-container>

