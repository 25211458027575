<mat-tab-group
  #tabGgroup
  headerPosition="below"
  animationDuration="200ms"
  [selectedIndex]="selectedTab"
  mat-stretch-tabs
>
  <mat-tab label="Channel">
    <ng-template mat-tab-label>
      <mat-icon>list</mat-icon>
    </ng-template>
    <ng-template matTabContent>
      <app-channel (channelSelected)="onNavigateTo()" class="mobile-page"></app-channel>
    </ng-template>
  </mat-tab>

  <mat-tab label="Home">
    <ng-template mat-tab-label>
      <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template
      matTabContent
    >
      <app-feed
        [showCreateCard]="showCardCreator()"
        (cancelCreateCard)="onCancelCardCreation()"
        [loadingNextPage]="loadingNextPage"
        [layout]="layout$ |async"
        class="mobile-page"
      ></app-feed>
      <app-feed-overlay
        class="click-ignore"
        (createCard)="createCard()"
        (refreshFeed)="refreshFeed()"
        [channelName]="channelName$ | async"
        [showCreateCardButton]="
          ((canPost$ | async) && (scrollDir$ | async) === 'up') ||
          (!(scrollDir$ | async) && !showCardCreator() && (canPost$ | async))
        "
        [showRefreshFeed]="(scrollDir$ | async) === 'up' && scrollTop() > 300"
        [showBreadCrumbs]="(scrollDir$ | async) === 'up' || (scrollDir$ | async) == undefined"
      ></app-feed-overlay>
    </ng-template>
  </mat-tab>

  <mat-tab label="Create">
    <ng-template mat-tab-label>
      <mat-icon>settings</mat-icon>
    </ng-template>
    <ng-template matTabContent>
      <app-requests class="mobile-page app-requests--mobile"></app-requests>
    </ng-template>
  </mat-tab>
</mat-tab-group>
