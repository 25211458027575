  <ng-container *ngIf="(viewStrat$ | async) && stack$ | async as stack">
    <div class="content-wrapper" *ngIf="stack && stack !== 'loading' && stack !== 'error'">
      <app-stack
        [stack]="stack"
        [hideCreaterButtons]="true"
        [isLoading]="!(stack$ | async)"
        [viewStrategy]="viewStrat$ | async"
      >
      </app-stack>
      <div class="stack-actions">
        <button *ngIf="(viewStrat$ | async) === ViewModeStrategy.Wizard" (click)="toggeViewStrategy()" mat-button mat-ripple>
          <mat-icon>visibility</mat-icon>
          Preview
        </button>
        <button  *ngIf="(viewStrat$ | async) === ViewModeStrategy.Display" (click)="toggeViewStrategy()" mat-button mat-ripple>
          <mat-icon>edit</mat-icon>
          Edit
        </button>
        <button (click)="onSave()" class="shadow" mat-fab>
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </div>
    <div class="spinner-background" *ngIf="stack === 'loading'">
      <mat-spinner strokeWidth="5" diameter="60"></mat-spinner>
      <h4>
        Loading Template
      </h4>
    </div>
  </ng-container>
