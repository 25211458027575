import {Injectable} from "@angular/core";
import {BehaviorSubject, fromEvent, shareReplay, Subject} from "rxjs";
import {Card} from "@mrbeany/stacks_shared";
import {Observable} from "../../../../node_modules/rxjs/dist/types";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map, startWith} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AppService {
  private clipboardID: number | undefined;
  private clipboardTitle: string | undefined;
  public _editingState = false;
  width$!: Observable<number>;
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait, Breakpoints.WebPortrait])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  private settingsIsActive = new BehaviorSubject<boolean>(false);
  private editingState = new Subject<boolean>();
  private showTempStack = new BehaviorSubject<boolean>(false);
  private triggerSingleView = new Subject<Card>();
  scrollDirection = new Subject();

  settingsIsActive$ = this.settingsIsActive.asObservable();
  editingState$ = this.editingState.asObservable();
  showTempStack$ = this.showTempStack.asObservable();
  triggerSingleView$ = this.triggerSingleView.asObservable();
  scrollDirection$ = this.scrollDirection.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.width$ = fromEvent(window, 'resize').pipe(
      map(() => window.innerWidth),
      startWith(window.innerWidth)
    );
  }

  callToggleEditState() {
    this._editingState = !this._editingState;
    this.editingState.next(this._editingState);
  }

  callToggleSettings() {
    this.settingsIsActive.next(!this.settingsIsActive.value);
  }

  callShowTempStack(show: boolean) {
    this.showTempStack.next(show);
  }

  callShowSingleCard(card: Card) {
    this.triggerSingleView.next(card);
  }

  // copy and paste Card IDs to Clipboaard
  setCardInfoToClipboard(ID: number, title: string) {
    this.clipboardID = ID;
    this.clipboardTitle = title;
  }
  getCardID() {
    return this.clipboardID;
  }
  getCardTitle() {
    return this.clipboardTitle;
  }
}
