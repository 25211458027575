<div class="wrapper">
  <span mat-dialog-title>
    Invite
  </span>

  <div mat-dialog-content class="content">
    <div class="url">
      <mat-form-field appearance="outline">
        <input disabled="true" type="text" matInput placeholder="loading url" [(ngModel)]="url" />
        <button
          *ngIf="!isLoading"
          color="primary"
          mat-icon-button
          matSuffix
          [disabled]="!url"
          (click)="copyLinkToClipbaord(url)"
        >
        <mat-icon>content_copy</mat-icon>
        </button>
        <app-loading-button-spinner matSuffix *ngIf="isLoading"></app-loading-button-spinner>
      </mat-form-field>
    </div>
    <p>Everyone with a link will be able to join. The link will be valid for 24h.</p>
  </div>
</div>
