import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import axios from 'axios';
import resizer from 'image-resizer-js';
import {AuthenticationService} from '../authentication/authentication.service';
import {environment} from '../../environments/environment';
import {readAndCompressImage} from 'browser-image-resizer';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
  }

  uploadFile(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const req = new HttpRequest('POST', 'uploadUrl', formData, {
      reportProgress: true,
      responseType: 'json',
    });
    return this.http.request(req);
  }

  uploadAvatar(file: File | Blob) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.addEventListener('loadend', (prog) => {
      resizeAvatar(prog.target?.result as ArrayBuffer)
        .then((res) => {
        const imgBlob = new Blob([new Uint8Array(res as ArrayBuffer)]);
        const formData: FormData = new FormData();
        formData.append('avatar', imgBlob);
        axios
          .post(environment.baseUrlServer + '/users/avatar', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.authenticationService.updateCurrentUser();
          });
      });
    });
  }
}

export function resizeAvatar(image: ArrayBuffer): Promise<void | ArrayBuffer> {
  const options = {
    maxWidth: 100,
    maxHeight: 100,
    quality: 1,
    rotate: undefined,
    silent: false,
  };
  return resizer(image, options).then((buffer) => {
    // image resized
    return buffer;
  });
}

export function readAndCompressFile(file: any): Promise<Blob | void> {
  const config = {
    quality: 0.8,
    maxWidth: 1024,
    maxHeight: 1024,
  };
  return readAndCompressImage(file, config);
}

export function readAndCompressProductImage(file: any): Promise<Blob | void> {
  const config = {
    quality: 0.8,
    maxWidth: 500,
    maxHeight: 300,
  };
  return readAndCompressImage(file, config);
}


export function readAndCompressFiles(files: Blob[]): Promise<Awaited<Blob | void>[] | void> {
  return Promise.all(files.map((file: Blob) => readAndCompressFile(file)));
}

export function readAndCompressProductFiles(files: Blob[]): Promise<Awaited<Blob | void>[] | void> {
  return Promise.all(files.map((file: Blob) => readAndCompressProductImage(file)));
}

export function resizeGalleryImage(image: ArrayBuffer): Promise<void | ArrayBuffer> {
  const options = {
    maxWidth: 1024,
    maxHeight: 1024,
    quality: 1,
    rotate: undefined,
    silent: false,
  };
  return resizer(image, options).then((buffer) => {
    // image resized
    return buffer;
  });
}

export function resizeGalleryImages(images: ArrayBuffer[]): Promise<(void | ArrayBuffer)[]> {
  return Promise.all(images.map((img: ArrayBuffer) => resizeGalleryImage(img))).then((res) => {
    return res;
  });
}
