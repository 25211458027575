import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ExpansionPanel} from "@mrbeany/stacks_shared/lib/models/card-elements/expansion-panel";
import {readAndCompressFiles} from "../../../services/upload.service";
import {CardElements} from "@mrbeany/stacks_shared";
import {VIEW_MODE} from "../../../enum";
import {CardNavigationService} from "../../../services/card-navigation/card-navigation.service";
import {IdService} from "../../../services/id.service";
import {CardElementComponent} from "../card-element/card-element.component";
import {Button} from "@mrbeany/stacks_shared/lib/models/card-elements/button";
import {CardNavEvent} from "@app/card.components/card/card.component";

@Component({
  selector: "app-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  styleUrls: ["./expansion-panel.component.scss"],
})
export class ExpansionPanelComponent {
  @Input() expansionPanel!: ExpansionPanel;
  @Input() title!: string;
  @Output() navigateTo = new EventEmitter<CardNavEvent>();

  editing = false;
  CARD_ELEMENT_TYPE = CardElements.CARD_ELEMENT_TYPE;
  loadingCard$ = this.cardNavService.loadingCard$;
  imageInputId = this.idService.getId("imageInput").toString();



  constructor(private cardNavService: CardNavigationService, private idService: IdService) {}

  protected readonly ExpansionPanel = ExpansionPanel;
}
