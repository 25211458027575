<div class="left">
  <a routerLink="/privacy-policy">Privacy Policy</a>
  <div class="vertical-divider"></div>
  <a routerLink="/legal-notice">Legal Notice</a>
  <div class="vertical-divider"></div>
  <a routerLink="/terms-of-use">Terms of use</a>
</div>
<div class="right">
 COPYRIGHT © 2024 - Lars Vink, all rights reserved
</div>

