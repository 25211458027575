<div class="wrapper" [class.wrapper--mobile]="isHandset$ | async">
  <a routerLink="/">
    <img *ngIf="!(isHandset$ | async)" class="logo" src="assets/images/logo.svg" alt="">
  </a>
  <div class="progress-bar">
    <div class="header-background">
      <header>
        <div routerLinkActive routerLink="/wizard/templates"  #rla1="routerLinkActive" >
          <h2 *ngIf="rla1.isActive">
            <button (click)="naviagteBack()" *ngIf="isHandset$ | async" mat-icon-button>
              <mat-icon>
                navigate_before
              </mat-icon>
            </button>
            Pick a Template
          </h2>
        </div>
        <div routerLinkActive routerLink="/wizard/builder"  #rla2="routerLinkActive" >
          <h2 *ngIf="rla2.isActive">
            <button (click)="naviagteBack()" *ngIf="isHandset$ | async" mat-icon-button>
              <mat-icon>
                navigate_before
              </mat-icon>
            </button>
            Build
          </h2>
        </div>

        <div routerLinkActive routerLink="/wizard/share"  #rla3="routerLinkActive" >
          <h2 *ngIf="rla3.isActive">
            <button  (click)="naviagteBack()" *ngIf="isHandset$ | async" mat-icon-button>
              <mat-icon>
                navigate_before
              </mat-icon>
            </button>
            Share
          </h2>
        </div>
      </header>

      <div class="steps">
        <a class="step" routerLink="/wizard/templates" routerLinkActive="step--active">
          1
        </a>
        <div class="divider">
        </div>
        <a class="step" [class.click-ignore]="rla1.isActive"  routerLink="/wizard/builder" routerLinkActive="step--active">
          2
        </a>
        <div class="divider"></div>
        <a class="step" [class.click-ignore]="rla1.isActive || rla2.isActive" routerLink="/wizard/share" routerLinkActive="step--active">
          3
        </a>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>

</div>
