import {Component, Input} from "@angular/core";
import {CARD_ELEMENT_TYPE} from "@mrbeany/stacks_shared/lib/models/card-elements.module";
import {CardChat} from "@mrbeany/stacks_shared/lib/models/card-elements/chat";

@Component({
  selector: 'app-card-chat',
  templateUrl: './card-chat.component.html',
  styleUrls: ['./card-chat.component.scss']
})
export class CardChatComponent {
  @Input() chat: CardChat =
  {
    config: {},
    type: CARD_ELEMENT_TYPE.CHAT,
    messages: [
      {
        userId: 'Max Mustermann',
        avatar: 'assets/images/image1.jpg',
        usernname: 'Max Mustermann',
        message: 'Hi! How are you? Great weather today 🙂'
      },
      {
        userId: 'Maria Musterfrau',
        avatar: 'assets/images/image2.jpg',
        usernname: 'Maria Musterfrau',
        message: 'Wow this is amazing. Should have brought my umbrella ☔🌦️'
      },
    ]
  }
}
