import {Component, EventEmitter, Input, Output, signal} from "@angular/core";
import {VIEW_MODE} from "../../../enum";

@Component({
  selector: "app-card-element",
  templateUrl: "./card-element.component.html",
  styleUrls: ["./card-element.component.scss"],
})
export class CardElementComponent {
  @Output() delete = new EventEmitter();
  @Output() moveUp = new EventEmitter();
  @Output() moveDown = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Output() edit = new EventEmitter();
  showEditActions = signal(false);
  canEdit = false;

  setShowEditActions(show: boolean) {
    this.showEditActions.set(show)
  }

  onEdit() {
    this.setShowEditActions(true);
    this.edit.emit();
  }
  deleteElement() {
    this.delete.emit();
  }

  onConfirm() {
    this.setShowEditActions(false);
    this.confirm.emit();
  }

  onMoveUp() {
    this.moveUp.emit();
  }

  onMoveDown() {
    this.moveDown.emit();
  }
}
