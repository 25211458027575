<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<ng-container *ngIf="editing">
  <div class="products">
    <div class="product" *ngFor="let product of product.products; let i = index">
      <div class="header header--edit">
        <mat-icon class="link-icon rotate45" *ngIf="product?.link && product?.link?.includes(environment.baseUrlGui)">route</mat-icon>
        <mat-icon class="link-icon rotate-45" *ngIf="product?.link && !product?.link?.includes(environment.baseUrlGui)">link</mat-icon>
        <label
          [for]="imgInputId + i"
          class="img-wrapper pointer"
          (dragover)="$event.preventDefault(); $event.stopPropagation();"
          (drop)="onDropFiles($event, i)"
        >
          <img *ngIf="product?.img" [src]="product.img  | handleImageSrc | safe" alt="">
          <img class="img-placeholder"  *ngIf="!product?.img" src="assets/icons/img-add.svg">
          <input
            (change)="onSelectFiles($event, i)"
            type="file"
            [id]="imgInputId + i"
            [name]="imgInputId + i"
            multiple
            accept=".jpg, .jpeg, .png, .webp"
            class="visually-hidden"
          />
        </label>
        <div #price
             class="price"
             contenteditable="true"
             dir="ltr"
             [class.price--placeholder]="!product.price"
             (click)="product.price ? '' : product.price = ''"
             (blur)="product.price ? '' : product.price = '$'"
             (input)="product.price = price.innerText"
        >
        </div>
      </div>
      <div class="content">
        <textarea class="title" placeholder="Title" [(ngModel)]="product.title"></textarea>
        <textarea  class="description" placeholder="Description" [(ngModel)]="product.description"></textarea>
        <input class="link" placeholder="www.apps.lu/my-product" [(ngModel)]="product.link">
      </div>
    </div>
    <app-placeholder-element class="product pointer" (click)="addProduct()">
      <mat-icon>add</mat-icon>
    </app-placeholder-element>

  </div>
</ng-container>

<ng-container  *ngIf="!editing">
  <div class="products">
    <ng-container *ngFor="let product of product.products; let i = index">
      <a [appConfirmLink]="product?.link" (navigateTo)="navigateTo.emit({link: $event})" class="product">
        <div class="header">
          <mat-icon class="link-icon rotate45" *ngIf="product?.link && product?.link?.includes(environment.baseUrlGui)">route</mat-icon>
          <mat-icon class="link-icon rotate-45" *ngIf="product?.link && !product?.link?.includes(environment.baseUrlGui)">link</mat-icon>
          <img *ngIf="product?.img" [src]="product.img | handleImageSrc | safe" alt="">
          <div *ngIf="product?.price" class="price">
            {{ product.price }}
          </div>
        </div>
        <div class="content">
          <p *ngIf="product?.title" class="title line-clamp-2">{{product.title}}</p>
          <p *ngIf="product?.description" class="description line-clamp-3">{{product.description}}</p>
        </div>
      </a>
    </ng-container>
  </div>
</ng-container>
