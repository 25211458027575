import {Component, Input} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-message',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {
  @Input() message = '';
  @Input() title = '';

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
  ) {
  }
  onClose() {
    this.dialogRef.close();
  }
}
