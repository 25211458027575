<body>
  <button mat-button [routerLink]="['/']" id="back-button">
    <mat-icon color="primary"> keyboard_arrow_left </mat-icon>
    Back
  </button>

  <div>Settings</div>
  <div class="gridplaceholder"></div>

  <div class="wrapper" class="card">
    <mat-expansion-panel [ngClass]="{'mat-expansion-panel-expanded': isExpanded === true}">
      <mat-expansion-panel-header
        [collapsedHeight]="'36px'"
        [expandedHeight]="'36px'"
        (click)="toggleExpanded()"
      >
        <div id="panel-title">Change Password</div>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <button mat-stroked-button>Delete Account</button>

    <button mat-flat-button color="primary">Log Out</button>
  </div>

  <body></body>
</body>
