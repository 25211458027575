<a *ngIf="!(isHandset$ | async)" class="logo" routerLink="/">
  <img src="/assets/images/logo.svg" alt="">
</a>
  <ng-container *ngIf="stack$ | async as stack">
    <app-stack
      [loadSpotify]="true"
      [stack]="stack"
      (menuNavigationEvent)="onScrollTop()"
      [viewStrategy]="ViewModeStrategy.Display">
    </app-stack>
    <p class="powered-by" *ngIf="(isHandset$ | async)">
      powered by <a style="display: block" routerLink="/">  <img src="/assets/images/logo.svg" alt="">
    </a>
    </p>
  </ng-container>
  <div
    *ngIf="!(stack$ | async)"
    class="loading-screen"
  >
    <mat-spinner color="primary" diameter="64" [strokeWidth]="6"></mat-spinner>
</div>
<app-footer
  *ngIf="stack$ | async as stack"
></app-footer>
