import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren
} from "@angular/core";
import {VIEW_MODE} from "../../../enum";

import {IdService} from "../../../services/id.service";
import {CardElementComponent} from "../card-element/card-element.component";
import {CardElements} from "@mrbeany/stacks_shared";
import {
  CardElement,
  CardFile, Contact,
  QuillElement
} from "@mrbeany/stacks_shared/lib/models/card-elements.module";
import {distinctUntilChanged, map, tap} from "rxjs/operators";
import {arrayItemMoveDown, arrayItemMoveUp} from "../../../helpers/helpers";
import {CardText, Gallery} from "@mrbeany/stacks_shared/lib/models/card-elements.module";
import {ExpansionPanel} from "@mrbeany/stacks_shared/lib/models/card-elements/expansion-panel";
import {ButtonContainer} from "@mrbeany/stacks_shared/lib/models/card-elements/button-container";
import {CardElementService} from "../card-element/card-element.service";
import {Spotify} from "@mrbeany/stacks_shared/lib/models/card-elements/spotify";
import {CardChat} from "@mrbeany/stacks_shared/lib/models/card-elements/chat";
import {User} from "@sentry/angular-ivy";
import {Button} from "@mrbeany/stacks_shared/lib/models/card-elements/button";
import {EmailSub} from "@mrbeany/stacks_shared/lib/models/card-elements/emailSub";
import {Social} from "@mrbeany/stacks_shared/lib/models/card-elements/socials";
import {StackService} from "@app/card.components/stack/stack.service";
import {Observable, skip} from "rxjs";
import {ViewModeStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";
import {Hyperlink} from "@mrbeany/stacks_shared/lib/models/card-elements/link";
import {ProductElement} from "@mrbeany/stacks_shared/lib/models/card-elements/product";
import {CardNavEvent} from "@app/card.components/card/card.component";


@Component({
  selector: "app-element-list",
  templateUrl: "./element-list.component.html",
  styleUrls: ["./element-list.component.scss"],
  providers: [CardElementService],
})
export class ElementListComponent implements AfterViewInit{
  @Input() elements!: Array<CardElement>;
  @Input() loadSpotify = false;
  @Input() excludeElements: Array<CardElements.CARD_ELEMENT_TYPE> | undefined;
  @Input() cardOwnerName?: string;
  @Input() cardId?: string;
  @Input() cardTitle?: string;
  @Output() navigateTo = new EventEmitter<CardNavEvent>();


  @ViewChildren("cardElementRef") cardElementRefs!: QueryList<any>;
  @ViewChildren("cardElementContainerRef") cardElementContainerRefs!: QueryList<any>;

  protected VIEW_MODE = VIEW_MODE;
  protected readonly ViewModeStrategy = ViewModeStrategy;

  CARD_ELEMENT_TYPE = CardElements.CARD_ELEMENT_TYPE;

  constructor(
    private stackService: StackService,
    private cdr: ChangeDetectorRef
  ) {}

  viewStrat$ = this.stackService.viewStrategy$;


  editElement(elementIndex: number) {
    const strategy = this.stackService.viewStrategySnapshot();
    strategy.editElement(elementIndex, this.cardElementRefs, this.cardElementContainerRefs);
    this.cdr.detectChanges();
  }
  confirmChanges(elementIndex: number) {
    this.cardElementRefs.forEach((element: CardElementComponent, index) => {
      const elementRef = this.cardElementRefs.get(index);
      if (elementRef?.type === this.CARD_ELEMENT_TYPE.TEXT && element.canEdit === true) {
        if(elementRef.displayMarkdown) {
          elementRef.renderHTML();
        } else {
          elementRef.updateMarkdown();
          elementRef.renderHTML();
        }
      }
    });
    this.cardElementRefs.get(elementIndex).editing = false;
    this.cardElementContainerRefs.get(elementIndex).showEditActions.set(false);
  }
  ngAfterViewInit() {
    this.cardElementContainerRefs.changes
      .pipe(
        map((res) => res.length),
        distinctUntilChanged(),
        tap((res) => {
          const strategy = this.stackService.viewStrategySnapshot();
          strategy.initElementViewMode(this.cardElementRefs, this.cardElementContainerRefs);
          if(strategy === ViewModeStrategy.Editor) {
            strategy.editElement(this.cardElementRefs.length - 1, this.cardElementRefs, this.cardElementContainerRefs);
          }
        })
      )
      .subscribe();
    this.stackService.viewStrategy$.pipe(
      tap((strategy) => {
        strategy.initElementViewMode(this.cardElementRefs, this.cardElementContainerRefs);
      })
    ).subscribe()
  }

  onNavigateToCard($event: CardNavEvent) {
    this.navigateTo.emit($event);
  }
  onRemoveElement(index: number) {
    this.elements.splice(index, 1);
  }
  onMoveUp(index: number) {
    arrayItemMoveUp(index, this.elements);
  }
  onMoveDown(index: number) {
    arrayItemMoveDown(index, this.elements);
  }
  onAddExpansionPanel() {
    this.elements.push(new ExpansionPanel());
  }
  onAddButtonContainer() {
    this.elements.push(new ButtonContainer());
  }
  onAddGallery() {
    const gallery = new Gallery();
    gallery.config.format = '21/9';
    this.elements.push(gallery);
  }

  onAddSpotify() {
    this.elements.push(new Spotify());
  }

  onAddQuill() {
    this.elements.push(new QuillElement());
  }

  onAddEmailSub() {
    this.elements.push(new EmailSub());
  }

  onAddSocials() {
    this.elements.push(new Social());
  }
  onAddHyperlink() {
    this.elements.push(new Hyperlink());
  }

  onAddFile() {
    // @ts-ignore
    this.elements.push(
      new CardFile(),
    );
  }
  onAddChat() {
    this.elements.push(new CardChat());
  }


  onAddProduct() {
    const newProductElement = new ProductElement();
    newProductElement.addProduct();
    this.elements.push(newProductElement);
  }

  onAddContact() {
    this.elements.push(new Contact());
  }
}
