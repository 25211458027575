<div class="wrapper" [class.wrapper--active]="showEditActions()" >
  <div *ngIf="canEdit" class="element-actions shadow" [class.element-actions--open]="showEditActions()">
    <button *ngIf="!showEditActions()" (click)="onEdit()"  mat-icon-button>
      <mat-icon
        class="edit-button"
        class="clear">
        edit
      </mat-icon>
    </button>
    <ng-container *ngIf="showEditActions()">
      <button (click)="deleteElement()" color="warn" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
      <button (click)="onMoveUp()" mat-icon-button>
        <mat-icon>expand_less</mat-icon>
      </button>
      <button (click)="onMoveDown()" mat-icon-button>
        <mat-icon>expand_more</mat-icon>
      </button>
      <button style="color: mediumseagreen" (click)="onConfirm()" mat-icon-button>
        <mat-icon>check</mat-icon>
      </button>
    </ng-container>
  </div>
  <ng-content></ng-content>
</div>
