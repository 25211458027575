import {Component, Input} from "@angular/core";
import {CardFile} from "../../../../../node_modules/@mrbeany/stacks_shared/lib/models/card-elements/file";
import {VIEW_MODE} from "@app/enum";

@Component({
  selector: "app-file",
  templateUrl: "./file.component.html",
  styleUrls: ["./file.component.scss"],
})
export class FileComponent {
  @Input() file!: CardFile;
  @Input() viewMode: VIEW_MODE = VIEW_MODE.DISPLAY;
  progress: number | undefined = undefined;
  VIEW_MODE = VIEW_MODE;

  startDownload() {
    this.progress = 1;
    setInterval(() => {
      if (this.progress && this.progress < 100) {
        this.progress = this.progress + 11;
      }
    }, 100);
  }
}
