import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {VIEW_MODE} from "../../../../enum";
import {Card, Stack} from "@mrbeany/stacks_shared";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import {ChannelsService} from "@app/services/channels/channels.service";
import {map, tap} from "rxjs/operators";
import {CardService} from "@app/services/card.service";
import {AuthenticationService} from "@app/authentication/authentication.service";
import {CardEditorService} from "@app/services/card-editor.service";
import Template = DTOs.Template;
import {ViewModeStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";

@Component({
  selector: 'app-card-editor',
  templateUrl: './card-editor.component.html',
  styleUrls: ['./card-editor.component.scss'],
})
export class CardEditorComponent implements OnInit, OnDestroy {

  @Input() show = false;
  @Output() cancelEdit = new EventEmitter<void>();

  VIEW_MODE = VIEW_MODE;
  isLoading = false;

  stack$ = this.cardEditorService.stack$;
  templateConfig$ = this.channelService.selectedChannel$.pipe(
    map((channel) => channel?.config?.template)
  );
  user$ : any;
  showEditor$ : any;
  constructor(
    private channelService: ChannelsService,
    private cardService: CardService,
    private authService: AuthenticationService,
    private cardEditorService: CardEditorService
  ) {}

  ngOnInit(): void {
    this.cardEditorService.init();
    this.user$  = this.authService.user$.pipe(
      tap((res) => {
        this.cardEditorService.updateUser(res);
      })
    ).subscribe()
    this.showEditor$ = this.cardEditorService.showEditor$.pipe(
      tap((res) => {
        this.show = res;
      })
    ).subscribe()
}

  compareObjects(o1: any, o2: any): boolean {
    return o1.cardId === o2.cardId;
  }

  selectTemplate($event: Template) {
    this.isLoading = true;
    this.cardEditorService.init($event?.cardId).finally(() => {
      this.isLoading = false;
    })
  }

  showEditor() {
    this.show = true;
  }

  onCancelEdit() {
    this.cancelEdit.emit();
    this.cardEditorService.init();
    this.show = false;
  }

  ngOnDestroy() {
    this.user$.unsubscribe();
    this.showEditor$.unsubscribe();
  }

  protected readonly ViewModeStrategy = ViewModeStrategy;
}
