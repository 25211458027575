import {Component, EventEmitter, Input, Output} from "@angular/core";
import {map, mergeMap, finalize, pairwise} from "rxjs/operators";
import {ChannelsService} from "../../../services/channels/channels.service";
import {combineLatest, forkJoin, merge} from "rxjs";
import {NetworkService} from "@app/services/network.service";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ChannelDTO = DTOs.ChannelDTO;
import NetworkDTO = DTOs.NetworkDTO;

@Component({
  selector: "app-feed-overlay",
  templateUrl: "./feed-overlay.component.html",
  styleUrls: ["./feed-overlay.component.scss"],
})
export class FeedOverlayComponent {
  @Input() channelName!: string | undefined | null;
  @Input() showCreateCardButton: boolean | null | undefined;
  @Input() showRefreshFeed = false;
  @Input() showBreadCrumbs = true;

  @Output() refreshFeed = new EventEmitter<void>();
  @Output() createCard = new EventEmitter<void>();

  joiningChannel = false;
  canJoin$ = combineLatest([
    this.channelService.selectedChannel$,
    this.networkService.selectedNetwork$,
  ]).pipe(
    map((res: Array<ChannelDTO | NetworkDTO | undefined>) => {
      const channel = res[0] as ChannelDTO;
      const network = res[1] as NetworkDTO;
      if (channel) {
        return !channel?.channelRole && channel?.config?.public;
      }
      if (network && !channel) {
        return !network?.networkRole && network?.config?.public;
      }
      return;
    })
  );
  constructor(private channelService: ChannelsService, private networkService: NetworkService) {}

  onRefreshFeed() {
    this.refreshFeed.emit();
  }

  onCreateCard() {
    this.createCard.emit();
  }

  joinChannel() {
    this.joiningChannel = true;
    if (this.channelService.getSelectedChannelSnapshot()) {
      this.channelService
        .joinChannel({
          channelId: this.channelService.getSelectedChannelSnapshot()?.id as string,
          key: undefined,
        })
        .pipe(finalize(() => (this.joiningChannel = false)))
        .subscribe();
    }
    if (this.networkService.getSelectedNetworkSnapshot()) {
      this.networkService
        .joinNetwork({
          networkId: this.networkService.getSelectedNetworkSnapshot()?.id as string,
          key: undefined,
        })
        .pipe(finalize(() => (this.joiningChannel = false)))
        .subscribe();
    }
  }
}
