import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonContainerComponent} from "./button-container.component";
import {ButtonContainerButtonComponent} from "./button.component";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {DirectivesModule} from "@app/directives/directives.module";



@NgModule({
  declarations: [
    ButtonContainerComponent,
    ButtonContainerButtonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    FormsModule,
    MatButtonModule,
    DirectivesModule
  ],
  exports: [
    ButtonContainerComponent
  ]
})
export class ButtonContainerModule { }
