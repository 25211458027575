import {Component, Input, OnInit} from "@angular/core";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ContactInformation = DTOs.ContactInformation;
import {FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {tap} from "rxjs/operators";
import LocationInformation = DTOs.LocationInformation;

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  @Input() contact!: ContactInformation;
  contactForm: FormGroup = new FormGroup({});
  showLocationForm = false;
  showPhone = false;
  showEmail = false;
  editing = false;

  constructor() {

  }

  ngOnInit() {
    this.contactForm = new FormGroup({
      location: new FormGroup({
        name: new FormControl(this.contact.location?.name || ''),
        address1: new FormControl(this.contact.location?.address1 || ''),
        address2: new FormControl(this.contact.location?.address2 || ''),
        province: new FormControl(this.contact.location?.province || ''),
        city: new FormControl(this.contact.location?.city || ''),
        state: new FormControl(this.contact.location?.state || ''),
        postalCode: new FormControl(this.contact.location?.postalCode || ''),
        country: new FormControl(this.contact.location?.country || ''),
      }),
      phone: new FormControl(this.contact.phone || ''),
      email: new FormControl(this.contact.email || ''),
    });
    this.contactForm.valueChanges
      .pipe(
        tap((res) => {
          this.contact.phone = res?.phone;
          this.contact.email = res?.email;
          this.contact.location = res?.location;
          console.log(this.contact);
        })
      )
      .subscribe();
  }

  deleteLocationForm() {
    this.showLocationForm = false;
    this.contactForm?.get("location")?.reset();
  }

  addLocationForm() {
    this.showLocationForm = true;
  }

  addPhone() {
    this.showPhone = true;
  }

  deletePhone() {
    this.showPhone = false;
    this.contactForm?.get("phone")?.reset();
  }
  addEmail() {
    this.showEmail = true;
  }

  deleteEmail() {
    this.showEmail = false;
    this.contactForm?.get("email")?.reset();
  }
}
