import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SigninComponent} from "./signin/signin.component";
import {SignupComponent} from "./signup/signup.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoadingButtonSpinnerModule} from "@app/custom.components/loading-button-spinner/loading-button-spinner.module";
import { AuthCardComponent } from './auth-card/auth-card.component';
import {DividerModule} from "@app/custom.components/divider/divider.module";
import {AuthenticationComponent} from "@app/authentication/authentication.component";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {TermsOfUseComponent} from "@app/legal/terms-of-use/terms-of-use.component";
import {AppModule} from "@app/app.module";
import {PrivacyPolicyComponent} from "@app/legal/privacy-policy/privacy-policy.component";
@NgModule({
  declarations: [SigninComponent, SignupComponent, AuthCardComponent, AuthenticationComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingButtonSpinnerModule,
    DividerModule,
    MatDialogModule,
    TermsOfUseComponent,
    PrivacyPolicyComponent
  ],
  exports: [SigninComponent, SignupComponent, AuthCardComponent, AuthenticationComponent
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }
  ]
})
export class AuthenticationModule {}
