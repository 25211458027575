import {Card, CardElements, Stack} from "@mrbeany/stacks_shared";
import {User} from "../authentication/user";
import {CardHeader} from "@mrbeany/stacks_shared/lib/models/card-elements/cardheader";
import {STACK_TYPE} from "@mrbeany/stacks_shared/lib/models/stack";
import {CARD_ELEMENT_TYPE} from "@mrbeany/stacks_shared/lib/models/card-elements.module";
import {ICard} from "@mrbeany/stacks_shared/lib/models/card";
import {FormArray, FormControl} from "@angular/forms";
import {ProductElement} from "@mrbeany/stacks_shared/lib/models/card-elements/product";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ImageFormDataBundle = DTOs.ImageFormDataBundle;
import {isCard} from "@app/services/feed/feed.service";



export function arrayItemMoveUp(index: number, array: any[]) {
  if (index > 0) {
    array.splice(index - 1, 0, array[index]);
    array.splice(index + 1, 1);
  }
}

export function arrayItemMoveDown(index: number, array: any[]) {
  if (index < array.length + 1) {
    array.splice(index + 2, 0, array[index]);
    array.splice(index, 1);
  }
}

export function patchArrayValues(values: any[], formArray: FormArray): FormArray {
  const arrayValues = values;
  formArray.clear();
  if (arrayValues) {
    arrayValues.forEach((value) => {
      formArray.push(new FormControl(value));
    });
  }
  return formArray;
}

export function createUserStack(user: User): Stack {
  return new Stack(undefined, STACK_TYPE.SINGLE, [createUserCard(user)]);
}
export function createUserCard(user: User): Card {
  const newHeader = new CardHeader("", user.name, user.avatarImg);
  const newCard = new Card(undefined, newHeader, [], false);
  return newCard;
}

export function getKeysOfType(
  obj: any,
  matchFn: (arg: any) => boolean,
  parentKey?: string
): Array<string> {
  const keys = [];

  // tslint:disable-next-line:forin
  for (const k in obj) {
    if (matchFn(obj[k])) {
      keys.push(parentKey ? parentKey + "." + k : k);
    }
    if (typeof obj[k] === "object" && obj[k] !== null) {
      // determines if we can go a level deeper
      keys.push(...getKeysOfType(obj[k], matchFn, parentKey ? parentKey + "." + k : k));
    }
  }
  return keys;
}

export function getImagePaths(stack: Stack): string[] {
  return [
    ...getGalleryImagePaths(stack),
    ...getProductImagesPaths(stack)
  ];
}


function getGalleryImagePaths(stack: Stack) : string[] {
  const paths = getKeysOfType(stack, hasGalleryImages);
  const galleries: CardElements.Gallery[] = paths.map((path) => getDeepValue(stack, path));
  const imagePaths: string[] = [];
  galleries.forEach((gallery, i) => {
    const path = paths[i];
    const _imagePaths = gallery?.images?.map((image, j) => {
      return path + ".images." + j;
    });
    if (_imagePaths) {
      imagePaths.push(..._imagePaths);
    }
  });
  return imagePaths;
}

function getProductImagesPaths(stack: Stack) : string[] {
  const paths = getKeysOfType(stack, hasProductImages);
  const products: CardElements.ProductElement[] = paths.map((path) => getDeepValue(stack, path));
  const imagePaths: string[] = [];
  products.forEach((product, i) => {
    const path = paths[i];
    const _imagePaths = product?.products?.map((product, j) => {
      return path + ".products." + j + ".img";
    });
    if (_imagePaths) {
      imagePaths.push(..._imagePaths);
    }
  });
  return imagePaths;
}

/**
 * get a property of an object based on the path
 * @param obj any object
 * @param path a string to the targetet property. example 'object.array.0'
 */
export function getDeepValue(obj: any, path: string) {
  for (let i = 0, _path = path.split("."), len = _path.length; i < len; i++) {
    obj = obj[_path[i]];
  }
  return obj;
}
export function hasGalleryImages(arg: any) {
  const hasImages = arg?.type === CARD_ELEMENT_TYPE.GALLERY;
  return hasImages
}
export function hasProductImages(arg: any) {
  const hasProduct = arg?.type === CARD_ELEMENT_TYPE.PRODUCT;
  return hasProduct
}

