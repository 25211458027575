import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, NgZone,
  Output,
  QueryList, Renderer2, ViewChild,
  ViewChildren
} from "@angular/core";
import {VIEW_MODE} from "../../enum";
import {Feed, LoadingState} from "@app/services/feed/feed.service";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {fromEvent, throttleTime} from "rxjs";
import {ViewModeStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";

@Component({
  selector: 'app-masonry',
  templateUrl: './masonry.component.html',
  styleUrls: ['./masonry.component.scss']
})
export class MasonryComponent implements AfterViewInit {

  private isSyncing = false;
  private sourceIndex: number | null = null;

  @Input() columns?: number | null;
  @Input() feed?: LoadingState<Feed>
  @Input() isLoading: boolean = false;
  @Output() loadNextPage = new EventEmitter<void>();
  @ViewChildren(CdkVirtualScrollViewport) scrollViewports!: QueryList<CdkVirtualScrollViewport>;
  nativeScrollViewports: any;
  @ViewChild('scrollFooter') scrollFooter!: ElementRef;

  protected readonly VIEW_MODE = VIEW_MODE;
  trackByFn = (index: number, item: any) => item.id;
  ngAfterViewInit() {
    this.nativeScrollViewports = this.scrollViewports.map((viewport) => viewport.getElementRef().nativeElement)

    this.scrollViewports.forEach((viewport, index) => {
      const element = viewport.getElementRef().nativeElement;

      setTimeout(() => {
        element.scrollTop = 0
      } , 0)
      this.ngZone.runOutsideAngular(() => {
        fromEvent(element, 'scroll').pipe().subscribe(() => {
          this.onScroll(element, index);
        });
/*        fromEvent(element, 'wheel').pipe(throttleTime(10)).subscribe(() =>{ this.onScroll(element, index);});
        fromEvent(element, 'touchmove').pipe(throttleTime(10)).subscribe(() => { this.onScroll(element, index);});*/
      });
    });

  }
  constructor(private ngZone: NgZone, private renderer: Renderer2) {
  }
  onScroll(viewport: any, index: number) {
    if (this.isSyncing) {
      return;
    }
    this.isSyncing = true;
    const maxScrollTop = viewport.scrollHeight - viewport.clientHeight;
    const scrollTop = viewport.scrollTop;
    this.nativeScrollViewports.forEach((vp: any, idx: number) => {
      if(idx === index) {
        return;
      }
      if( Math.abs(scrollTop - vp.scrollTop) < 1 ) {
          return;
        }
      if (scrollTop >= maxScrollTop) {
        vp.scrollTop = maxScrollTop;
      } else {
        vp.scrollTop = scrollTop;
      }
    });

    this.isSyncing = false;
    this.sourceIndex = null;
  }

  protected readonly ViewModeStrategy = ViewModeStrategy;
}
