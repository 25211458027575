import {Injectable} from '@angular/core';
import {Card, DefaultChannels, Stack} from "@mrbeany/stacks_shared";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import axios from 'axios';
import {environment} from '../../environments/environment';
import {AuthenticationService} from '@app/authentication/authentication.service';
import {getDeepValue, getImagePaths} from '@app/helpers/helpers';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ChannelsService} from '@app/services/channels/channels.service';
import {StackCacheService} from '@app/services/card-loader/stack-cache.service';
import {map, tap} from 'rxjs/operators';
import {ICard} from '@mrbeany/stacks_shared/lib/models/card';
import ImageFormDataBundle = DTOs.ImageFormDataBundle;
import ShareWithChannelDTO = DTOs.ShareWithChannelDTO;
import RemoveContentDTO = DTOs.RemoveContentDTO;

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private http: HttpClient,
    private stackCacheService: StackCacheService
  ) {}

  getStacks(ids: string[], loadToCache?: boolean): Observable<Stack[]> {
    const cachedStacks = this.stackCacheService.checkCache(ids);
    const missingStackIds: Array<string> = cachedStacks.filter(
      (item) => typeof item === 'string'
    ) as string[];
    if (missingStackIds.length === 0) {
      return of(cachedStacks as Stack[]);
    }
    return this.http
      .get<Stack[]>(environment.baseUrlServer + '/stacks', {
        params: {
          cardIds: missingStackIds,
        },
      })
      .pipe(
        map((_stacks: Stack[]) => {
          const newStacks = _stacks.map((_stack: Stack) => {
            const newStack = Object.assign(new Stack(), _stack);
            return newStack;
          });
          return newStacks;
        }),
        //@ts-ignore
        map((_stacks: Stack[]) => {
          return cachedStacks.map((item) => {
            if (typeof item === 'string') {
              return _stacks.find((stack: Stack) => stack.id === item);
            }
            return item;
          });
        }),
        tap((stacks) => {
          if (loadToCache) {
            this.stackCacheService.add(stacks);
          }
        })
      )
  }

  getContentByUrl(url: string) {
    return this.http.get<Stack>(environment.baseUrlServer + '/content-by-address', {
      params: {
        url: url
      }
    })
  }

  async postStack(stack: Stack, channelId?: string) {
    console.log('posting stack');
    const formData: FormData = new FormData();
    const imageBundles: ImageFormDataBundle[] = getImagePaths(stack)
      .map((path) => {
        console.log('path', path);
        const img = getDeepValue(stack, path);
        return {
          image: img,
          imagePath: path,
        };
      })
      .filter((imageBundle) => imageBundle.image.startsWith('blob:'));
    const imageBlobs = await Promise.all(
      imageBundles.map((res) => fetch(res.image).then((r) => r.blob()))
    );
    imageBlobs.forEach((imgBlob, index) => {
      const path = imageBundles[index].imagePath;
      formData.append(path, imgBlob);
    });
    const payload: string = JSON.stringify(stack);
    formData.append('stack', payload);
    return axios.post(environment.baseUrlServer + '/channel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        channelId: channelId || DefaultChannels.cards.id,
      },
    });
  }

  async editCard(card: Stack) {
    // push stack to backend
    const formData: FormData = new FormData();

    // @ts-ignore
    const imageBundles: ImageFormDataBundle[] = getImagePaths(card)
      .map((path) => {
        const img = getDeepValue(card, path);
        return {
          image: img,
          imagePath: path,
        };
      })
      .filter((imageBundle) => imageBundle.image.startsWith('blob:'));
    const imageBlobs = await Promise.all(
      imageBundles.map((res) => fetch(res.image).then((r) => r.blob()))
    );
    imageBlobs.forEach((imgBlob, index) => {
      const path = imageBundles[index].imagePath;
      formData.append(path, imgBlob);
    });
    const payload: string = JSON.stringify(card);
    formData.append('stack', payload);
    return axios.post(environment.baseUrlServer + '/channel/my_stacks/edit', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  /**
   * removes card from channel
   *
   */
  removeCard(dto: RemoveContentDTO) {
    return axios.delete(environment.baseUrlServer + '/channel/content', {
      params: dto,
    });
  }

  fetchContentAddress(id: string) {
    return this.http.get<{address: string}>(environment.baseUrlServer + '/content-address', {
      params: {
        id: id
      }
    })
  }

  claimAddress(url: string, contentId: string) {
    return this.http.post(environment.baseUrlServer + "/claim-url", {
      url: url,
      contentId: contentId
    })
  }

  shareCard(shareDTO: ShareWithChannelDTO) {
    return axios.post(environment.baseUrlServer + '/share/channel', {
      dto: shareDTO,
    });
  }
}
// todo: make this using axios, fetch is not supported by older browsers
export function fetchImages(urls: string[]): Promise<any> {
  return Promise.all(urls.map((url) => fetch(url)));
}
export function getCardLink(cardId?: string, contentUrl?: string) : string | undefined {
  console.log('making card id', cardId,contentUrl)
  if (contentUrl) {
    return environment.baseUrlGui + '/' + contentUrl;
  }
  if (cardId) {
    return environment.baseUrlGui + "/card/" + cardId;
  }
  return undefined;
}
