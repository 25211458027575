import 'hammerjs';
import {enableProdMode, inject} from "@angular/core";
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from './environments/environment';
import {AppModule} from './app/app.module';
import SuperTokens from 'supertokens-web-js/lib/build/supertokens';
import Session from 'supertokens-web-js/recipe/session';
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import {IdentityProvider} from "@app/services/supertoken.service";
import * as Sentry from "@sentry/angular-ivy";


Sentry.init({
  dsn: "https://3422d342d7d9717c00f06baa57448589@o4507141689245696.ingest.de.sentry.io/4507141695930448",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [environment.baseUrlServer, environment.baseUrlGui],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

SuperTokens.init({
  appInfo: {
    apiDomain: environment.baseUrlServer,
    appName: 'dndbois',
    apiBasePath: '/auth',
  },
  recipeList: [
    ThirdPartyEmailPassword.init(),
    Session.init({
      tokenTransferMethod: "header" // or "cookie"
    })
  ],
});
if (environment.production) {
  enableProdMode();
}

console.log('alpha v.0.1');
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
export async function SupertokenSocialSigninUp(provider: IdentityProvider, redirect: string) {
  // ?channel=my_cards
  try {
        const authUrl = await ThirdPartyEmailPassword.getAuthorisationURLWithQueryParamsAndSetState({
      thirdPartyId: provider.thirdPartyId,
      // This is where Google should redirect the user back after login or error.
      // This URL goes on the Google's dashboard as well.
      frontendRedirectURI: redirect,
    });
    /*
    Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
    */

    // we redirect the user to google for auth.
    window.location.assign(authUrl);
  } catch (err: any) {
    if (err.isSuperTokensGeneralError === true) {
      // this may be a custom error message sent from the API by you.
      return err;
    } else {
      return err;
    }
  }
}
