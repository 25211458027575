import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup, UntypedFormControl, Validators, UntypedFormGroup} from '@angular/forms';
import {CustomValidators} from "@app/custom-validators";
import {Subscription} from "rxjs";
import {tap} from "rxjs/operators";
import {identityProviders} from "@app/services/supertoken.service";
import {signUp} from "supertokens-web-js/lib/build/recipe/emailpassword";
import {signUpClicked} from "@app/authentication/signup/supertokens.signup";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnDestroy{

  @Output() isLoading = new EventEmitter<boolean>(false);
  @Output() navigateToSignIn = new EventEmitter<void>;
  @Output() showLegal = new EventEmitter<false | undefined | 'terms' | 'privacy'>();

  form: FormGroup = new FormGroup({
    email: new FormControl(
      '',
      [
        Validators.required,
        Validators.email
      ]
    ),
    password: new FormControl(
      '',
      [
        Validators.required,
        Validators.minLength(8),
        CustomValidators.hasNumber(),
        CustomValidators.lowerCase()
      ]
    ),
    acceptTerms: new FormControl(
      false,
      [Validators.requiredTrue]
    )
  })
  formStatus$ = this.form.statusChanges.pipe(
    tap(() => {
      if (this.form.valid) {
        this.showErrorMssg = false;
      }
    })
  ).subscribe()

  showErrorMssg = false;
  constructor() {
  }
  onSignup(form: {
    email: string,
    password: string
  }) {
    if (this.form.invalid) {
      this.showErrorMssg = true;
      return;
    }
    this.isLoading.emit(true);
    signUpClicked(form.email, form.password).catch().finally(() => {
      this.isLoading.emit(false);
    });
  }

  ngOnDestroy() {
    this.formStatus$.unsubscribe();
  }

}

