import { Component } from '@angular/core';

@Component({
  selector: 'app-markdown-cheatsheet',
  templateUrl: './markdown-cheatsheet.component.html',
  styleUrls: ['./markdown-cheatsheet.component.scss']
})
export class MarkdownCheatsheetComponent {

}
