import {CanActivateFn, Router} from "@angular/router";
import {AuthenticationService, ProviderCallback} from "../authentication/authentication.service";
import {inject} from "@angular/core";
import {SupertokenService} from "../services/supertoken.service";
import {SnackbarService} from "../services/snackbar.service";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import UserDTO = DTOs.UserDTO;
import {map, switchMap, tap} from "rxjs/operators";
import {catchError, from, Observable, of} from "rxjs";
import {LoadingService} from "@app/pages/loading/loading.service";

export const providerRedirectGuard: CanActivateFn = (route, state) => {
  const snackbarService = inject(SnackbarService);
  const authenticationService = inject(AuthenticationService);
  const loadingService = inject(LoadingService)
  const isProviderRedirect = (route.queryParamMap.get('code') && route.queryParamMap.get('state'));
  if(!isProviderRedirect) {
    return true;
  }
  loadingService.isLoading.next(true);
  const providerCallback = new ProviderCallback(snackbarService).handleCallback();
  const providerCallback$ = from(providerCallback);
  //@ts-ignore
  const userIsLoggedIn$: Observable<boolean> = providerCallback$.pipe(

    catchError((err) => {
      snackbarService.showSnackbar('An error occurred during signup, please try again');
      return of(err)
    }),
    switchMap((res) => {
      return authenticationService.getCurrentUser().pipe(
        tap((res) => {
        }),
        catchError((err) => {
          snackbarService.showSnackbar('An error occurred during signup, please try again');
          return of(err)
        }),
        switchMap((user: UserDTO) => {
          authenticationService.setDTOUser(user);
          return of(authenticationService.isLoggedIn()).pipe(
            tap((res) => {
              loadingService.isLoading.next(false);
            })
          )
        })
      )
    })
  )
  return userIsLoggedIn$;
};
