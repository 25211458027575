<div class="face">
  <img routerLink="/welcome" class="logo pointer" src="assets/images/logo.svg" alt="">
</div>

<app-card-editor *ngIf="!(isHandset$ | async)"></app-card-editor>
<ng-container *ngIf="channelList$ | async as channelList">
  <div class="channel-list" [class.channel-list--mobile]="isHandset$ | async">
    <h4 class="my-space">My Space</h4>
    <ng-container  *ngFor="let mySpace of mySapces$ | async; let i = index">
      <div
        *ngIf="mySpace.id !== defaultChannels.stacks.id"
        class="channel-list-item"
        [attr.data-index]="i"
      >
        <a
          mat-button
          (click)="selectChannel(mySpace.id); channelSelected.emit()"
          [ngClass]="{
          'button--selected':
            (activeChannelId$ | async) === mySpace.id && !(selectedNetworkId$ | async)
        }"
          [routerLink]="['/home']"
          [queryParams]="{channel: mySpace.id}"
        >
          {{ mySpace.channelName }}
        </a>
      </div>
    </ng-container>

    <div class="channel-header">
      <h4>Networks</h4>
      <button (click)="createNetwork()" mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="networks$ | async as networks">
      <div class="network-list">
        <div
          class="network-list-item"
          *ngFor="let network of networks; let i = index"
          [attr.data-index]="i"
        >
          <mat-expansion-panel
            togglePosition="before"
            [expanded]="(selectedNetworkId$ | async) === network.id"
          >
            <mat-expansion-panel-header>
              <a
                (click)="stopEventProp($event); selectNetwork(network); channelSelected.emit()"
                [routerLink]="['/home']"
                [ngClass]="{
                  'button--selected':
                    (selectedNetworkId$ | async) === network.id
                    && !(activeChannelId$ | async)
                }"
                [queryParams]="{network: network.id}"
              >
                {{ network.config.name }}
              </a>
              <button
                mat-icon-button
                [matMenuTriggerFor]="network_actions"
                [matMenuTriggerData]="{
                  canDeleteNetwork: network.networkRole?.permissions?.configureNetwork,
                  networkId: network.id
                }"
                (click)="stopEventProp($event)"
              >
                <mat-icon>more_horiz</mat-icon>
              </button></mat-expansion-panel-header
            >
            <div class="channel-list">
              <div class="channel-list-item" *ngFor="let channel of network.channels">
                <a
                  mat-button
                  (click)="selectChannel(channel.id); channelSelected.emit()"
                  [ngClass]="{
                    'button--selected'
                    : (activeChannelId$ | async) === channel.id
                    && (selectedNetworkId$ | async)
                  }"
                  [routerLink]="['/home']"
                  [queryParams]="{network: network.id, channel: channel.id}"
                >
                  {{ channel.channelName }}
                </a>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="channel_actions"
                  [matMenuTriggerData]="{
                    channelId: channel.id,
                    canInvite: channel?.channelRole?.permissions?.inviteUser,
                    channelPublic: channel?.config?.public,
                    canManageChannels: network.networkRole?.permissions?.manageChannels,
                    networkId: network.id
                  }"
                >
                  <mat-icon>more_horiz</mat-icon>
                </button>
              </div>
              <div
                *ngIf="network?.networkRole?.permissions?.manageChannels"
                class="channel-list-item"
              >
                <button mat-button (click)="addChannel(network.id)">
                  <mat-icon>add</mat-icon>

                  Add Channel
                </button>
              </div>
            </div>
          </mat-expansion-panel>

          <!--      <button
            mat-icon-button
            [matMenuTriggerFor]="channel_actions"
            [matMenuTriggerData]="{
              networkId: network.id,
              canInvite: network?.networkRole?.permissions?.inviteUser,
              channelPublic: network?.public
            }"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>-->
        </div>
      </div>
    </ng-container>
    <div class="channel-header">
      <h4>Channel</h4>
      <button (click)="createChannel()" mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
    </div>    <div
      *ngFor="let channel of channelList; let i = index"
      class="channel-list-item"
      [attr.data-index]="i"
    >
      <a
        mat-button
        (click)="selectChannel(channel.id); channelSelected.emit()"
        [ngClass]="{
          'button--selected':
            (activeChannelId$ | async) === channel.id && !(selectedNetworkId$ | async)
        }"
        [routerLink]="['/home']"
        [queryParams]="{channel: channel.id}"
      >
        {{ channel.channelName }}
      </a>
      <button
        mat-icon-button
        [matMenuTriggerFor]="channel_actions"
        [matMenuTriggerData]="{
          channelId: channel.id,
          canInvite: channel?.channelRole?.permissions?.inviteUser,
          channelPublic: channel?.config?.public
        }"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
    </div>
  </div>
</ng-container>

<app-footer></app-footer>




<mat-menu #channel_actions="matMenu">
  <ng-template
    matMenuContent
    let-canInvite="canInvite"
    let-channelId="channelId"
    let-channelPublic="channelPublic"
    let-networkId="networkId"
    let-canManageChannels="canManageChannels"
  >
    <button
      *ngIf="canInvite && !channelPublic"
      mat-menu-item
      (click)="onInviteToChannel(channelId)"
    >
      <mat-icon>share</mat-icon>
      Get Invite Link
    </button>
    <button *ngIf="channelPublic" mat-menu-item (click)="copyChannelLink(channelId)">
      <mat-icon>share</mat-icon>
      Copy Link
    </button>
    <button mat-menu-item (click)="onLeaveChannel(channelId)">
      <mat-icon>logout</mat-icon>
      Leave Channel
    </button>
    <button
      mat-menu-item
      *ngIf="networkId && canManageChannels"
      (click)="removeChannelFromNetwork(channelId, networkId)"
    >
      <mat-icon>close</mat-icon>
      Remove Channel
    </button>
    <button mat-menu-item (click)="onAddChannelToNetwork(channelId)">
      <mat-icon>lan</mat-icon>
      Add To Network
    </button>
  </ng-template>
</mat-menu>

<mat-menu #network_actions="matMenu">
  <ng-template mat-mdc-menu-content mdc-list matMenuContent let-networkId="networkId" let-canDeleteNetwork="canDeleteNetwork">
    <button mat-menu-item (click)="onLeaveNetwork(networkId)">
      <mat-icon>logout</mat-icon>
      Leave Network
    </button>
    <button mat-menu-item *ngIf="canDeleteNetwork" (click)="onDeleteNetwork(networkId)">
      <mat-icon>delete</mat-icon>
      Delete Network
    </button>
  </ng-template>
</mat-menu>
