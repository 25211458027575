<div class="card">
  <div class="element">
    <div class="cardheader-wrapper">
      <span class="cardheader-title">User Settings</span>
    </div>
  </div>
  <div class="card-elements">
    <app-set-profile-picture-button>Upload Profile Picture</app-set-profile-picture-button>
    <mat-expansion-panel [ngClass]="{'mat-expansion-panel-expanded': isExpanded === true}">
      <mat-expansion-panel-header (click)="toggleExpanded()">
        <span class="expansion-panel-title">Change Username</span>
      </mat-expansion-panel-header>

      <form [formGroup]="userNameForm">
        <mat-form-field class="element">
          <input
            (change)="usernameTaken = false"
            matInput
            placeholder="Username"
            formControlName="name"
          />
          <mat-hint *ngIf="usernameTaken" style="color: red"> Name is already taken </mat-hint>
        </mat-form-field>
        <button
          mat-flat-button
          class="loading-button"
          color="primary"
          [disabled]="userNameForm.invalid || savingUsername"
          (click)="onSaveUsername(userNameForm.getRawValue().name)"
        >
          <app-loading-button-spinner *ngIf="savingUsername"></app-loading-button-spinner>
          <span [class.hidden]="savingUsername">Save</span>
        </button>
      </form>
    </mat-expansion-panel>
  </div>
</div>
