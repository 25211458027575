import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  private cardInfo: CardClipboardInfo | undefined;

  constructor() {}

  // copy and paste Card IDs
  setCardInfo(cardInfo: CardClipboardInfo) {
    this.cardInfo = cardInfo;
    if (cardInfo.cardId) {
      navigator.clipboard.writeText(cardInfo.cardId);
    }
  }
  getCardInfo(): CardClipboardInfo | undefined {
    return this.cardInfo;
  }
}
export interface CardClipboardInfo {
  cardId: string | undefined;
  cardTitle: string | undefined;
}
