import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from "@angular/core";
import {
  ConfirmExternalNavigationDialogComponent
} from "@app/card.components/card.elements/hyperlink/confirm-external-navigation-dialog/confirm-external-navigation-dialog.component";
import {MatDialogRef} from "@angular/material/dialog";
import {DialogService} from "@app/services/dialog.service";
import {environment} from "../../environments/environment";

@Directive({
  selector: "[appConfirmLink]",
})
export class ConfirmLinkDirective implements OnInit {
  ngOnInit() {
    if(this.link) {
      this.el.nativeElement.classList.add("pointer");
    }
  }

  @Input("appConfirmLink") link?: string; // Link input for the directive
  @Output() navigateTo = new EventEmitter<string>();

  constructor(private dialogService: DialogService, private el: ElementRef) {}

  @HostListener("click", ["$event"])
  openConfirmLink(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault(); // Prevent the default action (if it's a link)
    if (!this.link) {
      return; // If no link is provided, do nothing
    }
    if (this.link.includes(environment.baseUrlGui)) {
      this.navigateTo.emit(this.link);
      return;
    } else {
      this.el.nativeElement.href = "this.link";
    }
    const dialogRef: MatDialogRef<ConfirmExternalNavigationDialogComponent> =
      this.dialogService.openDialog(ConfirmExternalNavigationDialogComponent, (res: boolean) => {
        if (res && event.target) {
          event.target.dispatchEvent(new Event("click"));
        }
      });
    // Set the url property on the dialog component instance
    dialogRef.componentInstance.url = this.link;
  }
}
