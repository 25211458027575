import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IdService {
  private Ids: Map<string, number> = new Map();

  constructor() {}

  /**
   * If the key is not registered, it will be added to the map so the id can be retrieved later with the same key
   * @param key
   */
  getId(key: string): number {
    if (this.Ids.has(key)) {
      const id = (this.Ids.get(key) as number) + 1;
      this.Ids.set(key, id);
      return id;
    } else {
      const id = 0;
      this.Ids.set(key, id);
      return id;
    }
  }

  getRandomID() {
    return Math.floor(Math.random() * 1000000000).toString()
  }
}
