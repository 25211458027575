import {Component, EventEmitter, Output} from "@angular/core";

@Component({
  selector: "app-table-select",
  templateUrl: "./table-select.component.html",
  styleUrls: ["./table-select.component.scss"],
})
export class TableSelectComponent {
  @Output() createTable = new EventEmitter<{rows: number; columns: number}>();
  table = [
    ["x", "x", "x", "x"],
    ["x", "x", "x", "x"],
    ["x", "x", "x", "x"],
    ["x", "x", "x", "x"],
    ["x", "x", "x", "x"],
    ["x", "x", "x", "x"],
  ];
  highlightRow: number | undefined;
  highlightCol: number | undefined;

  highlight(i: number | undefined, j: number | undefined) {
    this.highlightRow = i;
    this.highlightCol = j;
  }
  onCreate(rows: number, columns: number) {
    this.createTable.emit({
      rows: rows,
      columns: columns,
    });
  }
}
