import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {ChannelsService} from "../../../services/channels/channels.service";
import {AuthenticationService} from "@app/authentication/authentication.service";
import {CardService} from "@app/services/card.service";
import {catchError, forkJoin, Observable, of} from "rxjs";
import {DefaultChannels} from "@mrbeany/stacks_shared";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, tap} from "rxjs/operators";
import {DialogService} from "@app/services/dialog.service";
import {ChannelInviteComponent} from "@app/custom.components/dialogs/channel-invite/channel-invite.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {SnackbarService} from "@app/services/snackbar.service";
import {CreateNetworkDialogComponent} from "@app/custom.components/dialogs/create-network-dialog/create-network-dialog.component";
import {NetworkService} from "@app/services/network.service";
import {CreateChannelDialogComponent} from "@app/custom.components/dialogs/create-channel-dialog/create-channel-dialog.component";
import {SelectNetworkDialogComponent} from "@app/custom.components/dialogs/select-network-dialog/select-network-dialog.component";
import {SelectChannelDialogComponent} from "@app/custom.components/dialogs/select-channel-dialog/select-channel-dialog.component";
import {ChannelApiService} from "@app/services/channel-api/channel-api.service";
import {NetworkApiService} from "@app/services/network-api/network-api.service";
import ChannelDTO = DTOs.ChannelDTO;
import {AppService} from "@app/services/navbar/app.service";
import NetworkDTO = DTOs.NetworkDTO;
import {FeedService} from "@app/services/feed/feed.service";
import {calculateTake} from "@app/main/home/feed/feed.component";

@Component({
  selector: "app-channel",
  templateUrl: "./channel.component.html",
  styleUrls: ["./channel.component.scss"],
})
export class ChannelComponent implements OnInit {
  @Output() channelSelected = new EventEmitter<void>();

  editFollowed = false;
  channelList$: Observable<ChannelDTO[]>;
  activeChannel$;
  activeChannelId$ = this.route.queryParams.pipe(map((params) => params["channel"]));
  networks$ = this.networkService.networks$;
  mySapces$: Observable<ChannelDTO[]>;
  selectedNetworkId$ = this.networkService.selectedNetwork$.pipe(map((network) => network?.id));
  isHandset$ = this.appService.isHandset$;
  defaultChannels = DefaultChannels;

  constructor(
    private channelService: ChannelsService,
    private appService: AppService,
    private networkService: NetworkService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private snackbar: SnackbarService,
    private networkAPI: NetworkApiService,
  ) {
    this.channelList$ = this.channelService.channelList$.pipe(
      map((channels) => channels.filter((channel) => !Object.values(this.defaultChannels).map((_channel : any) => _channel.id).includes(channel.id)))
    );
    this.mySapces$ = this.channelService.channelList$.pipe(
      map((channels) => channels.filter((channel) => Object.values(this.defaultChannels).map((_channel : any) => _channel.id).includes(channel.id)))
    );
    this.activeChannel$ = this.channelService.selectedChannel$;
  }

  ngOnInit() {}


  createChannel(networkId?: string) {
    this.dialogService.openDialog(CreateChannelDialogComponent, networkId ? networkId : undefined);
  }
  addChannel(networkId: string) {
    this.dialogService.openDialog(SelectChannelDialogComponent, networkId);
  }

  removeChannelFromNetwork(channelId: string, networkId: string) {
    this.networkAPI
      .removeChannelsFromNetwork(networkId, [channelId])
      .pipe(
        tap((res) => {
          this.networkService.removeChannelFromNetwork(channelId, networkId);
        }),
        catchError((err) => {
          this.snackbar.showSnackbar("Error while removing Channel from Network");
          return of(err);
        })
      )
      .subscribe();
  }

  selectChannel(id: string) {
    if (this.channelService.getSelectedChannelSnapshot()?.id === id) {
      this.channelService.loadFeed(
        id,
        calculateTake(this.channelService.getSelectedChannelSnapshot()?.config?.layout
      ));
    }
  }
  selectNetwork(network: NetworkDTO) {
    if (
      this.networkService.getSelectedNetworkSnapshot()?.id === network.id &&
      !this.channelService.getSelectedChannelSnapshot() //only load a new feed if only the network has been selected
    ) this.networkService.loadFeed(
      network.id,
      calculateTake(this.channelService.getSelectedChannelSnapshot()?.config?.layout)
    );
  }

  onLeaveChannel(id: string) {
    this.channelService
      .leaveChannel(id)
      .pipe(
        catchError((err) => {
          this.snackbar.showSnackbar("Error while leaving channel");
          return of(err);
        })
      )
      .subscribe();
  }

  onInviteToChannel(channelId: string) {
    const config: MatDialogConfig = {
      height: "auto",
    };
    this.dialogService.openDialog(
      ChannelInviteComponent,
      {channelId: channelId},
      undefined,
      config
    );
  }

  createNetwork() {
    this.dialogService.openDialog(CreateNetworkDialogComponent);
  }

  copyChannelLink(channelId: string) {
    navigator.clipboard.writeText(environment.baseUrlGui + "/?channel=" + channelId);
    this.snackbar.showSnackbar("Link Copied To Clipboard");
  }

  onAddChannelToNetwork(channelId: string) {
    this.dialogService.openDialog(SelectNetworkDialogComponent, channelId);
  }

  onLeaveNetwork(networkId: string) {
    this.networkService
      .leaveNetwork(networkId)
      .pipe(
        tap(() => {
          this.snackbar.showSnackbar("Successfully left Network");
        }),
        catchError((err) => {
          this.snackbar.showSnackbar("Error while removing Network");
          return of(err);
        })
      )
      .subscribe();
  }

  onDeleteNetwork(networkId: string) {
    this.networkAPI
      .deleteNetwork(networkId)
      .pipe(
        tap((res) => {
          // delete network
          // this.snackbar.showSnackbar("Error while removing Channel from Network");
        }),
        catchError((err) => {
          this.snackbar.showSnackbar("Error while removing Network");
          return of(err);
        })
      )
      .subscribe();
  }

  stopEventProp($event: MouseEvent) {
    $event.stopPropagation();
  }

  protected readonly DefaultChannels = DefaultChannels;
}
