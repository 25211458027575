export function buttonLink(state: any, silent: any) {
  let attrs;
  let ref;
  let label;
  let code;
  let href = "";
  let title = "";
  const oldPos = state.pos;
  const max = state.posMax;
  let start = state.pos;
  let parseReference = true;
  const labelStartMarker = 0x2b; // +
  const labelEndMarker = 0x2b; // +
  const addressStartMarker = 0x28; // (
  const addressEndMarker = 0x29; // )

  if (state.src.charCodeAt(state.pos) !== labelStartMarker /* > */) {

    return false;
  }

  const labelStart = state.pos + 1;
  const labelEnd = parseLinkLabel(state, labelStartMarker, labelEndMarker, state.pos, true);

  // parser failed to find ']', so it's not a valid link
  if (labelEnd < 0) {
    return false;
  }

  let pos = labelEnd + 1;
  // link ending character '<'
  if (pos < max && state.src.charCodeAt(pos) === addressStartMarker /* ( */) {
    //
    // Inline link
    //

    // might have found a valid shortcut link, disable reference parsing
    parseReference = false;

    // [link](  <href>  "title"  )
    //        ^^ skipping these spaces
    pos++;
    for (; pos < max; pos++) {
      code = state.src.charCodeAt(pos);
      if (!isSpace(code) && code !== 0x0a) {
        break;
      }
    }
    if (pos >= max) {
      return false;
    }

    // [link](  <href>  "title"  )
    //          ^^^^^^ parsing link destination
    start = pos;
    let res = state.md.helpers.parseLinkDestination(state.src, pos, state.posMax);
    if (res.ok) {
      href = state.md.normalizeLink(res.str);
      if (state.md.validateLink(href)) {
        pos = res.pos;
      } else {
        href = "";
      }

      // [link](  <href>  "title"  )
      //                ^^ skipping these spaces
      start = pos;
      for (; pos < max; pos++) {
        code = state.src.charCodeAt(pos);
        if (!isSpace(code) && code !== 0x0a) {
          break;
        }
      }

      // [link](  <href>  "title"  )
      //                  ^^^^^^^ parsing link title
      res = state.md.helpers.parseLinkTitle(state.src, pos, state.posMax);
      if (pos < max && start !== pos && res.ok) {
        title = res.str;
        pos = res.pos;

        // [link](  <href>  "title"  )
        //                         ^^ skipping these spaces
        for (; pos < max; pos++) {
          code = state.src.charCodeAt(pos);
          if (!isSpace(code) && code !== 0x0a) {
            break;
          }
        }
      }
    }

    if (pos >= max || state.src.charCodeAt(pos) !== addressEndMarker /* ) */) {
      // parsing a valid shortcut link failed, fallback to reference
      parseReference = true;
    }
    pos++;
  }

  if (parseReference) {
    //
    // Link reference
    //
    if (typeof state.env.references === "undefined") {
      return false;
    }

    if (pos < max && state.src.charCodeAt(pos) === labelStartMarker /* [ */) {
      start = pos + 1;
      pos = state.md.helpers.parseLinkLabel(state, pos);
      if (pos >= 0) {
        label = state.src.slice(start, pos++);
      } else {
        pos = labelEnd + 1;
      }
    } else {
      pos = labelEnd + 1;
    }

    // covers label === '' and label === undefined
    // (collapsed reference link and shortcut reference link respectively)
    if (!label) {
      label = state.src.slice(labelStart, labelEnd);
    }

    ref = state.env.references[normalizeReference(label)];
    if (!ref) {
      state.pos = oldPos;
      return false;
    }
    href = ref.href;
    title = ref.title;
  }

  //
  // We found the end of the link, and know for a fact it's a valid link;
  // so all that's left to do is to call tokenizer.
  //

  if (!silent) {
    state.pos = labelStart;
    state.posMax = labelEnd;

    let token = state.push("card_link_open", "a", 1);
    token.attrs = attrs = [
      ["class", "card-text-link"],
      ["href", href],
      ["name", "button"],
    ];
    attrs.push();
    if (title) {
      attrs.push(["title", title]);
    }
    state.linkLevel++;
    state.md.inline.tokenize(state);
    state.linkLevel--;

    token = state.push("card_link_close", "a", -1);
  }
  state.pos = pos;
  state.posMax = max;
  return true;
}

function parseLinkLabel(
  state: any,
  startMarker: number,
  endMarker: number,
  start: number,
  disableNested: boolean
) {
  let level;
  let found;
  let marker;
  let prevPos;
  let labelEnd = -1;
  const max = state.posMax;
  const oldPos = state.pos;

  state.pos = start + 1;
  level = 1;

  while (state.pos < max) {
    marker = state.src.charCodeAt(state.pos);
    if (marker === endMarker) {
      level--;
      if (level === 0) {
        found = true;
        break;
      }
    }

    prevPos = state.pos;
    state.md.inline.skipToken(state);
    if (marker === startMarker) {
      if (prevPos === state.pos - 1) {
        // increase level if we find text `startmarker`, which is not a part of any token
        level++;
      } else if (disableNested) {
        state.pos = oldPos;
        return -1;
      }
    }
  }

  if (found) {
    labelEnd = state.pos;
  }

  // restore old state
  state.pos = oldPos;

  return labelEnd;
}

function isSpace(code: number) {
  switch (code) {
    case 0x09:
    case 0x20:
      return true;
  }
  return false;
}
function normalizeReference(str: string) {
  str = str.trim().replace(/\s+/g, " ");
  return str.toLowerCase().toUpperCase();
}

export function buttonLinkPlugin(md: any, options: any) {
  md.inline.ruler.push("button_link", buttonLink);
}
