import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FileComponent} from "./file.component";
import {MatIconModule} from "@angular/material/icon";
import {MaterialModule} from "../../../material.dependencies";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    FileComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MaterialModule,
    FormsModule
  ],
  exports: [
    FileComponent
  ]
})
export class FileModule { }
