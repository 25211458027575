import {Component, Input, OnInit} from "@angular/core";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import NetworkDTO = DTOs.NetworkDTO;
import {FormBuilder, FormGroup} from "@angular/forms";
import {Validators} from "../../../../node_modules/@angular/forms";
import {DialogComponent} from "@app/modules/dialog/dialog/dialog.component";
import {DialogService} from "@app/services/dialog.service";
import {NetworkService} from "@app/services/network.service";
import {tap} from "rxjs/operators";
import {catchError, finalize, of} from "rxjs";
import {SnackbarService} from "@app/services/snackbar.service";
import NetworkConfigDTO = DTOs.NetworkConfigDTO;
import {NetworkApiService} from "@app/services/network-api/network-api.service";

@Component({
  selector: "app-network-config",
  templateUrl: "./network-config.component.html",
  styleUrls: ["./network-config.component.scss"],
})
export class NetworkConfigComponent implements OnInit {
  private _network!: NetworkDTO;
  @Input() set network(network: NetworkDTO) {
    if (network.config) {
      this._network = network;
      if(this.form) {
        this.form.patchValue(network.config);
        return;
      }
      this.form = this.buildForm(network);
    }
  }
  get network() {
    return this._network;
  }
  form: FormGroup | undefined;

  deletingNetwork = false;
  configLoading = false;

  constructor(
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private networkApi: NetworkApiService,
    private networksService: NetworkService
  ) {}

  ngOnInit() {
    this.form = this.buildForm(this.network);
  }

  buildForm(network: NetworkDTO) {
    return new FormBuilder().group({
      name: [network.config.name, [Validators.maxLength(42), Validators.required]],
      public: [network.config.public],
      layout: [network.config.layout]
    });
  }
  updateLayout() {
    const config = this.form?.getRawValue();
    if(!config) {
      return;
    }
    this.networksService.applyConfig({
      ...this.network,
      config: config
    })
  }
  onCancel() {
    this.form = this.buildForm(this.network);
  }

  submitForm(config: NetworkConfigDTO) {
    this.configLoading = true;
    this.networkApi.saveConfig(config, this.network.id)
    .pipe(
      tap((network) => {
        this.networksService.applyConfig(network)
      }),
      finalize(() => {
        this.configLoading = false;
        this.form?.markAsPristine();
      })
    ).subscribe();
  }

  deleteNetwork() {
    this.dialogService.openDialog(
      DialogComponent,
      {
        title: "Delete Network",
        message: "Are you sure you want to delete this Network? This can not be undone.",
      },
      () => {
        this.deletingNetwork = true;
        this.networkApi
          .deleteNetwork(this.network.id)
          .pipe(
            tap(() => {
              this.snackbarService.showSnackbar("Successfully deleted Network");
            }),
            catchError((err) => {
              this.snackbarService.showSnackbar("An error occurred while deleting this Network");
              return of(err);
            }),
            finalize(() => (this.deletingNetwork = false))
          )
          .subscribe();
      }
    );
  }
}
