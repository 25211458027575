import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-cardheader-title',
  templateUrl: './cardheader-title.component.html',
  styleUrls: ['./cardheader-title.component.scss'],
})
export class CardheaderTitleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
