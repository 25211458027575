import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {catchError, finalize, Observable, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AuthenticationService} from '@app/authentication/authentication.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css'],
})
export class UserSettingsComponent implements OnInit {
  isExpanded = false;
  userNameForm: FormGroup;
  savingUsername = false;
  usernameTaken = false;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    private authenticationService: AuthenticationService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.userNameForm = new FormGroup<any>({
      name: new FormControl<string | undefined>(this.authenticationService.getUser()?.name, [
        Validators.maxLength(24),
        Validators.required,
      ]),
    });
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  onSaveUsername(username: string) {
    this.savingUsername = true;
    this.authenticationService
      .setUserName(username)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error === 'SEC-001') {
            this.usernameTaken = true;
          }
          return throwError(() => err);
        }),
        tap(() => {
          this.authenticationService.setDTOUser({
            ...this.authenticationService.getCurrentUser(),
            username: username,
          });
        }),
        finalize(() => {
          this.savingUsername = false;
        })
      )
      .subscribe();
  }

  ngOnInit() {}
}
export interface PwdForm {
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
}
