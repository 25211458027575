import {Component, ElementRef, Input, OnInit} from "@angular/core";
import {VIEW_MODE} from "@app/enum";
import {DialogService} from "@app/services/dialog.service";
import {MessageDialogComponent} from "@app/custom.components/dialogs/message/message-dialog.component";
import {Spotify} from "@mrbeany/stacks_shared/lib/models/card-elements/spotify";

@Component({
  selector: 'app-spotify',
  templateUrl: './spotify.component.html',
  styleUrls: ['./spotify.component.scss']
})
export class SpotifyComponent implements OnInit{
  @Input() spotify!: Spotify;
  @Input() loadSpofify = false;
  src: string | undefined;
  tag : string | undefined;
  editing = false;
  constructor(private dialog: DialogService) {

  }

  ngOnInit() {
    if(this.spotify.src) {
      this.tag = getTag(this.spotify.src, true);
    }
  }

  protected readonly VIEW_MODE = VIEW_MODE;

  openHelpDialog() {
    this.dialog.openDialog(MessageDialogComponent, undefined, undefined, undefined, [
      {name: 'title', value: 'Embed Spotify Link'},
      {
        name: 'message',
        value: `To embed a Spotify track, playlist, album or artist go to  
        <br>
        <br>
        Spotify > track > share > "Copy Song Link"`
      }
    ]);
  }


  updateEmbedSrc() {
    if(!this.src) {
      return
    }
    // https://open.spotify.com/playlist/17NAp9DrszWOuZyFL4EAxN?si=ef077187a6544730
    //                         ^
    const tag = getTag(this.src, false);
    this.tag = tag;
    if(this.src) {
      this.spotify.src = convertToEmbed(this?.src, tag);
    }
  }

  updateSrc(event: any) {
    this.src =  event.target.value;
    // this.updateEmbedSrc();
  }
}

function getTag(src: string, embed: boolean) {
  let tagSartIndex;
  if(embed) {
    tagSartIndex = 'https://open.spotify.com/embed/'.length;
  }
  if(!embed) {
    tagSartIndex = 'https://open.spotify.com/'.length;
  }
  // playlist/17NAp9DrszWOuZyFL4EAxN?si=ef077187a6544730
  const sub = src.slice(tagSartIndex);
  // playlist/17NAp9DrszWOuZyFL4EAxN?si=ef077187a6544730
  //         ^
  const tagEndIndex = sub.indexOf('/');
  // playlist
  const tag = sub.slice(0, tagEndIndex);
  return tag;
}

function convertToEmbed(src: string, tag: string) : string {
  const playlistIdStart = src.indexOf(`/${tag}/`) + `/${tag}/`.length;
  const playlistIdEnd = src.indexOf('?si=');
  const embedLink =`https://open.spotify.com/embed/${tag}/${src.slice(playlistIdStart, playlistIdEnd)}`;
  return embedLink;
}
