<form [formGroup]="form">
  <mat-form-field>
    <input matInput formControlName="name" />
    <mat-hint *ngIf="usernameTaken" style="color: red"> Name is already taken </mat-hint>
    <mat-label>Channel Name</mat-label>
    <mat-error *ngIf="form?.hasError('maxLength')">Max 42 characters</mat-error>
    <mat-error *ngIf="form?.hasError('required')">Required</mat-error>
  </mat-form-field>
  <button
    [disabled]="saving"
    mat-flat-button
    color="primary"
    (click)="submit(form.getRawValue(), networkId)"
  >
    <mat-icon *ngIf="!saving">save</mat-icon>
    <mat-icon *ngIf="saving">
      <mat-spinner [diameter]="24" [strokeWidth]="3"></mat-spinner>
    </mat-icon>
    Submit
  </button>
</form>
