<div class="element-actions shadow" *ngIf="editing">
  <button *ngIf="gallery?.images && gallery?.images?.length === 1" mat-icon-button [matMenuTriggerFor]="aspectRatioMenu" aria-label="Menu to set the desired aspect ration of the image">
    <mat-icon>aspect_ratio</mat-icon>
  </button>
  <button *ngIf="gallery.images && gallery!.images.length > 0" (click)="manageImages()" mat-icon-button>
    <mat-icon>playlist_remove</mat-icon>
  </button>
</div>
<ng-container *ngIf="editing">
  <div *ngIf="(!gallery?.images || gallery.images?.length === 0)" class="image-dropper">
  <div
    (dragover)="prevent($event)"
    (drop)="onDropFiles($event)"
    class="image-dropper-foreground">
    <span style="font-size: 24px">Drag & Drop Images</span>
    <label mat-menu-item class="icon-button gallery-button" [for]="galleryId">
      <span>Select</span>
    </label>
    <input
      (change)="onSelectFiles($event)"
      type="file"
      [id]="galleryId"
      [name]="galleryId"
      multiple
      accept=".jpg, .jpeg, .png, .webp"
      class="visually-hidden"
    />
  </div>
  <div class="image-dropper-background">
    <div class="border"></div>
  </div>
  </div>
</ng-container>
<ng-container *ngIf="gallery?.images">
  <div
    class="image-container"
    [class.image-grid--2images]="gallery!.images?.length === 2"
    [class.image-grid]="gallery!.images!.length > 2"
    gallerize="{{ galleryId }}"
  >
    <!--using src for blobs since ngSrc can't handle those-->
    <ng-container *ngFor="let image of gallery.images; let i = index">
      <ng-container>
        <img
          *ngIf="i <= 1"
          [style.aspectRatio]="gallery.config.format !== 'source' ? gallery.config.format : 'unset'"
          [class.ratio--2-1]="gallery.images?.length === 1"
          [src]="image | handleImageSrc | safe" width="200" height="200" alt="img not found"/>

        <div *ngIf="i === 2" class="more-images-container">
          <img [src]="image | handleImageSrc | safe" width="100" height="100" alt="img not found"/>
          <div *ngIf="gallery!.images!.length > 3" class="more-images-overlay">
            <span> + {{ gallery!.images!.length - 3 }} </span>
          </div>
        </div>
        <div [style.display]="'none'" *ngIf="i > 2" class="more-images-container">
          <img [src]="image | handleImageSrc | safe" width="50" height="50" alt="img not found" />
          <div *ngIf="gallery!.images!.length > 2" class="more-images-overlay">
            <span> + {{ gallery!.images!.length - 3 }} </span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>


<mat-menu #aspectRatioMenu="matMenu">
  <button mat-menu-item aria-label="21:9" (click)="setAspectRatio('21/9')">
    21:9
  </button>
  <button mat-menu-item aria-label="16:9" (click)="setAspectRatio('16/9')">
    16:9
  </button>
  <button mat-menu-item aria-label="5:4" (click)="setAspectRatio('5/4')">
    5:4
  </button>
  <button mat-menu-item aria-label="1:1" (click)="setAspectRatio('1/1')">
    1:1
  </button>
  <button mat-menu-item aria-label="original" (click)="setAspectRatio('source')">
    original
  </button>
</mat-menu>
