import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import NetworkDTO = DTOs.NetworkDTO;
import {catchError, Observable, throwError} from "rxjs";
import NetworkConfigDTO = DTOs.NetworkConfigDTO;
import createNetworkDTO = DTOs.CreateNetworkDTO;
import addChannelsToNetworkDTO = DTOs.AddChannelsToNetworkDTO;
import removeChannelsFromNetworkDTO = DTOs.RemoveChannelsFromNetworkDTO;
import {environment} from "../../../environments/environment";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import {Card, Stack} from "../../../../node_modules/@mrbeany/stacks_shared";
import {HttpErrorResponse} from "../../../../node_modules/@angular/common/http";
import {STACK_TYPE} from "../../../../node_modules/@mrbeany/stacks_shared/lib/models/stack";
import {map} from "rxjs/operators";
import {isCard} from "@app/services/feed/feed.service";
import JoinNetworkDTO = DTOs.JoinNetworkDTO;
@Injectable({
  providedIn: "root",
})
export class NetworkApiService {
  constructor(private http: HttpClient) {}

  getNetwork(networkId: string): Observable<NetworkDTO> {
    return this.http.get<NetworkDTO>(environment.baseUrlServer + "/network", {
      params: {
        networkId: networkId,
      },
    });
  }

  joinNetwork(dto: JoinNetworkDTO) {
    return this.http.post(environment.baseUrlServer + "/network/membership", {
      dto: dto,
    });
  }

  getNetworkCards(networkId: string, page: number, take: number): Observable<Array<Stack>> {
    return this.http
      .get<Array<Card | Stack>>(environment.baseUrlServer + "/network/content", {
        params: {
          networkId: networkId,
          page: page,
          take: take,
        },
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        }),
        map((res) =>
          res.map((content) => {
            if (isCard(content)) {
              return new Stack(content.id, STACK_TYPE.SINGLE, [content]);
            }
            return content;
          })
        )
      );
  }
  createNetwork(name: string, channelIds?: Array<string> | string | undefined) {
    const dto: createNetworkDTO = {
      channelIds: channelIds,
      config: {
        public: true,
        name: name,
        layout: 'feed'
      },
    };
    return this.http.post<NetworkDTO>(environment.baseUrlServer + "/network", dto);
  }
  leaveNetwork(networkId: string) {
    let params = new HttpParams();
    params = params.append("networkId", networkId);
    return this.http.delete(environment.baseUrlServer + "/network/membership", {params});
  }

  saveConfig(dto: NetworkConfigDTO, networkId: string) {
    return this.http.post<NetworkDTO>(environment.baseUrlServer + "/network/config", dto, {
      params: {
        networkId: networkId
      }
    });
  }

  getUserNetworks(): Observable<Array<NetworkDTO>> {
    return this.http.get<Array<NetworkDTO>>(environment.baseUrlServer + "/user/network");
  }

  addChannelsToNetwork(networkId: string, channelIds: Array<string> | string) {
    const dto: addChannelsToNetworkDTO = {
      networkId: networkId,
      channelIds: channelIds,
    };
    return this.http.post(environment.baseUrlServer + "/network/channels", dto);
  }
  removeChannelsFromNetwork(networkId: string, channelIds: Array<string> | string) {
    const dto: removeChannelsFromNetworkDTO = {
      networkId: networkId,
      channelIds: channelIds,
    };
    const httpOptions = {
      body: dto,
    };
    return this.http.delete(environment.baseUrlServer + "/network/channels", httpOptions);
  }

  deleteNetwork(id: string) {
    let params = new HttpParams();
    params = params.append("id", id);
    return this.http.delete(environment.baseUrlServer + "/network", {params});
  }
}
