<div class="wrapper">
  <button class="close" (click)="close()" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <span mat-dialog-title>
    {{ title }}
  </span>
  <div mat-dialog-content class="content">
    {{ message }}
  </div>

  <div class="button-wrapper">
    <button mat-flat-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button [mat-dialog-close]="true" color="primary" (click)="onConfirm()">
      Confirm
    </button>
  </div>
</div>
