import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CardElementsModule} from "../card.elements/card-elements.module";
import {ElementListComponent} from "./element-list/element-list.component";
import {FormsModule} from "@angular/forms";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {CardElementComponent} from "@app/card.components/element-list/card-element/card-element.component";
import {PipesModule} from "@app/pipes/pipes.module";
import {MatExpansionModule} from "@angular/material/expansion";
import {
  ExpansionPanelComponent
} from "@app/card.components/element-list/expansion-panel-element/expansion-panel.component";
import {MaterialModule} from "@app/material.dependencies";
import {MatRippleModule} from "@angular/material/core";
import {PrivacyPolicyComponent} from "@app/legal/privacy-policy/privacy-policy.component";



@NgModule({
  declarations: [ElementListComponent, CardElementComponent, ExpansionPanelComponent],
  imports: [
    CommonModule,
    CardElementsModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    PipesModule,
    MatExpansionModule,
    MaterialModule,
    MatRippleModule,
    PrivacyPolicyComponent,
  ],
  exports: [ElementListComponent, ExpansionPanelComponent],
})
export class ElementListModule {}
