<div class="requests-wrapper">
  <div class="site-options">
    <ng-container *ngIf="(userAvatar$ | async) as userAvatar">
      <img
        [src]="imgPrefix + userAvatar"
        alt="avatar image"
        class="avatar"
        (click)="showUserSettings = !showUserSettings"
        mat-icon-button
      />
    </ng-container>
    <img
      *ngIf="!(userAvatar$ | async)"
      src="assets/images/image1.jpg"
      alt="avatar image"
      class="avatar"
      (click)="showUserSettings = !showUserSettings"
      appRoleGuard
      mat-icon-button
    />
    <span>{{ userName$ | async }}</span>

    <button *ngIf="user$ | async" (click)="onLogout()" mat-icon-button>
      <mat-icon>logout</mat-icon>
    </button>
    <button *ngIf="!(user$ | async)" [routerLink]="'auth/signin'" mat-icon-button>
      <mat-icon>login</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>

  <!--
  <app-set-username *ngIf="!(userName$ | async) && isLoggedIn$ | async"></app-set-username>
-->
  <button
    *ngIf="canJoin$ | async"
    class="button"
    mat-flat-button
    appRoleGuard
    color="primary"
    (click)="joinChannel()"
  >
    <span *ngIf="!joiningChannel"> Join Channel </span>
    <app-loading-button-spinner *ngIf="joiningChannel"></app-loading-button-spinner>
  </button>
  <ng-container *ngIf="(user$ | async) as user">
    <div class="card" *ngIf="!user.avatarImg">
      <div class="cardheader-wrapper">
        <div class="cardheader-title">Upload Profile Picture</div>
      </div>
      <div class="card-elements">
        <app-set-profile-picture-button>Select File</app-set-profile-picture-button>
      </div>
    </div>
    <app-user-settings *ngIf="showUserSettings"></app-user-settings>
    <app-set-username *ngIf="!user?.name"></app-set-username>
  </ng-container>

  <ng-container
    *ngIf="selectedFeed$ | async as selectedFeed"
    [ngSwitch]="isNetworkDTO(selectedFeed)"
  >
    <ng-container *ngSwitchCase="true">
      <ng-container *ngIf="network$ | async as network">
        <app-network-config
          *ngIf="network?.networkRole?.permissions?.configureNetwork"
          [network]="network"
        ></app-network-config>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <ng-container *ngIf="channel$ | async as channel">
        <app-channel-config
          *ngIf="channel?.channelRole?.permissions?.configureChannel"
          [channel]="channel"
        ></app-channel-config>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
