// Note: calling these methods with untrusted USER DATA exposes your application to XSS security risks!

import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'handleImageSrc',
})
export class HandleImageSrcPipe implements PipeTransform {
  private imgPrefix = environment.baseUrlServer + '/images/';
  constructor() {}
  transform(string: string) {
    if(string.startsWith('blob')) {
      return string;
    }
    if(string.startsWith('assets')) {
      return string;
    }
    if(string.startsWith('data:image/')) {
      return string;
    }
    else {
      return this.imgPrefix + string;
    }
  }
}
