import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {DialogService} from "../../services/dialog.service";
import {tap} from "rxjs/operators";
import {LoadingPageComponent} from "./loading-page/loading-page.component";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = new BehaviorSubject(false);
  isLoading$ = this.isLoading.asObservable().pipe(
    tap((isLoading) => {
      if(isLoading) {
        this.dialogService.openDialog(LoadingPageComponent)
      } else {
        this.dialogService.dialog.closeAll();
      }
    })
  ).subscribe();

  constructor(
    private dialogService: DialogService
  ) { }
}
