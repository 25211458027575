<img routerLink="/" class="logo pointer" src="assets/images/logo.svg" alt="">

<h1>Legal Notice</h1>
<p><em>Last Updated: 28.08.2024</em></p>

<h2>Owner and Operator:</h2>
<p>
  Lars Vink<br>
  8, rue de la syre<br>
  L-5377 Uebersyren<br>
  Luxembourg<br>
  Email: <a href="mailto:contact@vinkweb.lu">contact@vinkweb.lu</a>
</p>

<h2>Platform Description:</h2>
<p>This platform is owned and operated by Lars Vink as a sole proprietor. The platform provides software-as-a-service (SaaS) that enables users to create and share micro applications using various blocks, including text, images, links to external websites, email subscriptions, Spotify, and social media links.</p>

<h2>Content and Liability Disclaimer:</h2>
<p>The information provided on this platform is for general informational purposes only. Lars Vink assumes no responsibility for errors or omissions in the content provided on the platform. Users are responsible for the content they create and share on the platform and must comply with all applicable laws and the platform's Terms of Use.</p>

<h2>Intellectual Property:</h2>
<p>All intellectual property rights in the platform and its content are owned by Lars Vink. Users may not use, copy, reproduce, or distribute any content from the platform without the prior written consent of Lars Vink.</p>

<h2>Governing Law:</h2>
<p>This Legal Notice is governed by the laws of Luxembourg. Any disputes arising out of or in connection with this Legal Notice shall be subject to the exclusive jurisdiction of the courts of Luxembourg.</p>

<p>If you have any questions or concerns about this Legal Notice, please contact us at <a href="mailto:contact@vinkweb.lu">contact@vinkweb.lu</a>.</p>
