import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmClickDirective } from './confirm-click.directive';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";



@NgModule({
  declarations: [ConfirmationDialogComponent, ConfirmClickDirective],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  exports: [ConfirmClickDirective, ConfirmationDialogComponent],
})
export class ConfirmationModule {}
