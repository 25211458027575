import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {CardChatComponent} from "./card-chat.component";
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [
    CardChatComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    NgOptimizedImage
  ],
  exports: [
    CardChatComponent
  ]
})
export class CardChatModule { }
