// Note: calling these methods with untrusted USER DATA exposes your application to XSS security risks!

import {Pipe, PipeTransform} from '@angular/core';
import {ExpansionPanel} from '@mrbeany/stacks_shared/lib/models/card-elements/expansion-panel';

@Pipe({
  name: 'objectAssign',
})
export class ExpansionPanelAssign implements PipeTransform {
  constructor() {}
  transform(object: any) {
    const obg = Object.assign(new ExpansionPanel(), object);
    return obg;
  }
}
