import {Injectable} from "@angular/core";
import {User, UserRole} from "./user";
import {BehaviorSubject, catchError, throwError} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {SupertokenService} from "../services/supertoken.service";
import {map, tap} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {SnackbarService} from "@app/services/snackbar.service";
import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import * as Sentry from "@sentry/angular-ivy";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import UserDTO = DTOs.UserDTO;

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  user = new BehaviorSubject<User>(guestUser);
  user$ = this.user.asObservable();
  isLoggedIn$ = this.user$.pipe(
    map((user) => {
      return !!user;
    })
  );


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private supertokenService: SupertokenService,
    private http: HttpClient,
    private snackbarService: SnackbarService
  ) {}

  getUser() {
    return this.user.getValue();
  }
  async isLoggedIn() {
    return this.supertokenService.isLoggedIn();
  }
  setDTOUser(user: DTOs.UserDTO) {
    if (user) {
      this.user.next(new User(user.username ? user.username : "", user.avatar, UserRole.Standard));
    }
  }

  logout() {
    this.supertokenService
      .signout()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.snackbarService.showSnackbar("Something went wrong during signout");
          return throwError(() => err);
        }),
        tap(() => {
          this.user.next(guestUser);
          this.router.navigateByUrl("/welcome");
        })
      )
      .subscribe();
  }

  /**
   * Gets the current user from the db and updates the state
   */
  updateCurrentUser() {
    this.getCurrentUser()
      .pipe(
        tap((user: UserDTO) => {
          this.setDTOUser(user);
        })
      )
      .subscribe();
  }

  getCurrentUser(): any {
    return this.http.get(environment.baseUrlServer +"/users/current").pipe(
      catchError((err) => {
        if (err.status === 404) {
          this.user.next(
            guestUser
          )
        }
        return throwError(() => err);
      })
    );
  }

  setUserName(userName: string): any {
    return this.http.post(environment.baseUrlServer + "/users/username", {
      userName: userName,
    });
  }
}

export const guestUser = new User('Guest', 'assets/images/image1.jpg', UserRole.Guest);
export class ProviderCallback {
  constructor(
    private snackbarService: SnackbarService
  ) {}

  async handleCallback() {
    try {
      const response = await ThirdPartyEmailPassword.thirdPartySignInAndUp();
      if (response.status === "OK") {
        if (response.createdNewRecipeUser && response.user.loginMethods.length === 1) {
          // sign up successful
        } else {
          // sign in successful
        }
      } else if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign in / up was not allowed.
        window.alert(response.reason)
      } else {
        // SuperTokens requires that the third party provider
        // gives an email for the user. If that's not the case, sign up / in
        // will fail.

        // As a hack to solve this, you can override the backend functions to create a fake email for the user.

        window.alert("No email provided by social login. Please use another form of login");
        window.location.assign("/auth"); // redirect back to login page
      }
    } catch (err: any) {
      if(err)
        Sentry.captureException(err, {
          extra: err
        });
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
      } else {
      }
      this.snackbarService.showSnackbar('Oops, something went wrong');
    }
  }
}
