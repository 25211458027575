<div class="card">
  <div class="cardheader-wrapper">
    <div class="cardheader-title">Markdown Cheatsheet</div>
  </div>
  <div class="card-elements">
    <div class="app-text">
      <table>
        <thead></thead>
        <tbody>
          <!-- Add table rows for markdown shortcuts -->
          <tr>
            <td># Header 1</td>
            <td>
              <h1>Header 1</h1>
            </td>
          </tr>
          <tr>
            <td>## Header 2</td>
            <td>
              <h2>Header 2</h2>
            </td>
          </tr>
          <tr>
            <td>**bold**</td>
            <td>
              <strong> Bold text </strong>
            </td>
          </tr>
          <tr>
            <td>*italic*</td>
            <td>
              <i> Italic text </i>
            </td>
          </tr>
          <tr>
            <td>[Internet Link Text](http://a.com)</td>
            <td><a href="http://a.com">Internet Link Text</a></td>
          </tr>
          <tr>
            <td>+Card Link Text+(cardID)</td>
            <td><span class="card-text-link">Card Link Text</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer">
      For a complete list of chommands check
      <a target="_blank" href="https://www.markdownguide.org/cheat-sheet/">the markdown guide</a>
    </div>
  </div>
</div>
