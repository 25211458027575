// Note: calling these methods with untrusted USER DATA exposes your application to XSS security risks!

import {Pipe, PipeTransform, signal} from '@angular/core';

@Pipe({
  name: 'signalify',
})
export class SignalifyPipe implements PipeTransform {
  constructor() {}
  transform(obj: any) {
    return signal(obj);
  }
}
