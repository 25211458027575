<ng-container *ngIf="editing">
  <div class="link--edit">
    <div class="decorator">
      <mat-icon class="link-icon rotate45" *ngIf="hyperlink.link && hyperlink.link.includes(environment.baseUrlGui)">route</mat-icon>
      <mat-icon class="link-icon rotate-45" *ngIf="hyperlink.link && !hyperlink.link.includes(environment.baseUrlGui)">link</mat-icon>
      <mat-icon class="link-icon rotate45" [svgIcon]="'route_closed'" *ngIf="!hyperlink.link"></mat-icon>
    </div>

  <div class="links">
      <input type="text" placeholder="Link Title" [(ngModel)]="hyperlink.label">
      <input type="text" placeholder="www.appsy.lu/your-appsy"  [(ngModel)]="hyperlink.link">
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!editing">
  <a class="link--display" [appConfirmLink]="hyperlink?.link" (navigateTo)="navigateTo.emit({link: $event})" >
    <div class="decorator">
      <mat-icon class="link-icon rotate45" *ngIf="hyperlink.link && hyperlink.link.includes(environment.baseUrlGui)">route</mat-icon>
      <mat-icon class="link-icon rotate-45" *ngIf="hyperlink.link && !hyperlink.link.includes(environment.baseUrlGui)">link</mat-icon>
      <mat-icon class="link-icon rotate45" [svgIcon]="'route_closed'" *ngIf="!hyperlink.link"></mat-icon>
    </div>
    <h4>{{hyperlink.label}}</h4>
  </a>
</ng-container>
