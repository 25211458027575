import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ScrolledBottomDirective} from "./scrolled-bottom.directive";
import {RoleGuardDirective} from "./role-guard.directive";
import { ConfirmLinkDirective } from './confirm-link.directive';
import { ElementWidthDirective } from './element-width.directive';



@NgModule({
  declarations: [
    ScrolledBottomDirective,
    RoleGuardDirective,
    ConfirmLinkDirective,
    ElementWidthDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ScrolledBottomDirective,
    RoleGuardDirective,
    ConfirmLinkDirective,
    ElementWidthDirective,
  ]
})
export class DirectivesModule { }
