<ng-container *ngIf="show">
  <ng-container *ngIf="templateConfig$ | async as templateConfig">
    <mat-form-field *ngIf="templateConfig.templates.length > 0">
      <mat-label>Template</mat-label>
      <mat-select
        (valueChange)="selectTemplate($event)"
        [compareWith]="compareObjects"
        [value]="templateConfig.defaultTemplate"
        placeholder="Templates"
      >
        <mat-option [value]="template" *ngFor="let template of templateConfig.templates">
          {{ template?.name }}
        </mat-option>
        <mat-option [value]="undefined">
          {{ '- none -' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="stack$ | async as stack">
    <app-stack (cancelEdit)="onCancelEdit()" [isLoading]="isLoading" [stack]="stack" [viewStrategy]="ViewModeStrategy.Editor"></app-stack>
  </ng-container>
</ng-container>
<app-placeholder-element class="pointer" type="button" role="button"  mat-ripple  *ngIf="!show" (click)="show = true">
  <div class="add-element-container">
    <mat-icon height="38">add</mat-icon>
    <span>New Appsy</span>
  </div>
</app-placeholder-element>
