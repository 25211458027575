<nav>
  <img routerLink="/welcome" class="logo" src="assets/images/logo.svg" alt="">
  <button class="page-menu" [matMenuTriggerFor]="pageMenu">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="page-links">
    <a routerLink="/wizard/templates">Templates</a>
    <a routerLink="/home">Home</a>
<!--    <a href="/pricing">Pricing</a>-->
  </div>
  <div class="auth-buttons">
    <button (click)="signIn()" class="sign-in">Login</button>
    <button (click)="signUp()" class="sign-up shadow">Register</button>
  </div>
</nav>
<section>
  <div class="column column-left">
    <header [class.header--mobile]="isHandest$ | async">
      <h1>
        All you create,
      </h1>
      <h1>
        one click away.
      </h1>
      <div class="subtitle">
        <span #messageEl1 class="message1 transition-in">{{message1}}&nbsp;</span>
        <a routerLink="/wizard/templates">
          <div #messageEl2  class="transition-in message2">
            {{message2}}
          </div>
        </a>
      </div>
    </header>
    <p>
      With an Appsy your audience is only
      one click away from everything you sell, create or want to share. Build your own from a growing list of building blocks to express yourself in just the way you need.
    </p>
    <div class="input-container">
      <span>appsy.lu/</span>
      <input  #urlInput (keyup.enter)="claimAppsy(urlSlug)"  (input)="updateUrlSlug($event)" [(ngModel)]="urlSlug" placeholder="your-appsy" type="text">
      <button class="button flat-button" (click)="claimAppsy(urlSlug)" mat-ripple>
        Claim
      </button>
    </div>
  </div>
  <div class="column column-right">
    <img class="hero-img hero-img--mobile" ngSrc="assets/images/hero--mobile.webp" width="559" height="420" alt="">
  </div>
</section>

<app-footer></app-footer>
<mat-menu #pageMenu="matMenu">
  <a mat-menu-item routerLink="/wizard/templates">
    Templates
  </a>
  <a mat-menu-item routerLink="/home">
    Home
  </a>
<!--  <a mat-menu-item routerLink="/pricing">
    Pricing
  </a>-->
</mat-menu>
