<div class="wrapper">
  <header>
    <div class="title-header">
      <h2 [innerHTML]="title || defaultTitle">
      </h2>
      <p [innerHTML]="description || defaultDescription">
      </p>
    </div>
    <div class="img" #qrcontainer></div>
  </header>

  <footer class="content">
    <div class="url-container">
      <div class="url">{{ link }}</div>
      <button mat-icon-button (click)="copyLink(link)">
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
    <div
      *ngIf="!address && !fetchingAddress"
      class="input-container"
      [class.error-taken]="addressStatus === 'taken'"
    >
      <span class="error-message" *ngIf="addressStatus === 'taken'">Already taken</span>
      <span>appsy.lu/</span>
      <input
        #urlInput
        (keyup.enter)="claimAddress(urlSlug)"
        (input)="updateUrlSlug($event)"
        [disabled]="addressStatus === 'loading'"
        placeholder="your-appsy"
        type="text">
      <button (click)="claimAddress(urlSlug)" class="button flat-button" mat-ripple *ngIf="addressStatus !== 'loading'">
        Claim
      </button>
      <app-loading-button-spinner *ngIf="addressStatus === 'loading'"></app-loading-button-spinner>
    </div>
    <app-loading-button-spinner *ngIf="fetchingAddress"></app-loading-button-spinner>
  </footer>
</div>

