import {Component, Inject, Input, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "@app/modules/dialog/dialog/dialog.component";



@Component({
  selector: 'app-gallery-editor',
  templateUrl: './gallery-editor.component.html',
  styleUrls: ['./gallery-editor.component.scss']
})
export class GalleryEditorComponent implements OnInit{
  _images: {
    src: string,
    checked: boolean
  }[] = [];
  @Input() set images(images: string[]) {
    this._images = images.map((img) => {
      return {
        src: img,
        checked: false
      }
    })
  }

  constructor(
    public dialogRef: MatDialogRef<GalleryEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[]
  ) {
  }

  ngOnInit() {
    this._images = this.data.map((img) => {
      return {
        src: img,
        checked: false
      }
    })
  }

  selectImage(i: number) {
    if(!this._images) {
      return
    }
    this._images[i].checked = !this._images[i].checked;
  }


  onCancel() {
    this.dialogRef.close()
  }
  onSave() {
    const newImages = this._images.filter((img) => !img.checked).map((img) => img.src)
    this.dialogRef.close(newImages);
  }
}
