import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardComponent } from './wizard/wizard.component';
import { WizardTemplatesComponent } from './wizard-templates/wizard-templates.component';
import { WizardBuilderComponent } from './wizard-builder/wizard-builder.component';
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {StackModule} from "@app/card.components/stack/stack.module";
import {DirectivesModule} from "@app/directives/directives.module";
import {AuthenticationModule} from "@app/authentication/authentication.module";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MaterialModule} from "@app/material.dependencies";
import { WizardShareComponent } from './wizard-share/wizard-share.component';
import {MatRippleModule} from "@angular/material/core";



@NgModule({
  declarations: [
    WizardComponent,
    WizardTemplatesComponent,
    WizardBuilderComponent,
    WizardShareComponent
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    StackModule,
    DirectivesModule,
    AuthenticationModule,
    MatButtonModule,
    MatIconModule,
    MaterialModule,
    MatRippleModule
  ],
  exports: [
    WizardComponent,
  ]
})
export class WizardModule { }
