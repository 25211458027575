import {Component, OnDestroy, OnInit, signal} from "@angular/core";
import {VIEW_MODE} from '@app/enum';
import {AppService} from '@app/services/navbar/app.service';
import {AfterViewInit, ElementRef, ViewChild} from '../../../../../node_modules/@angular/core';
import {combineLatest, fromEvent, merge, Observable, Subscription} from "rxjs";
import {distinctUntilChanged, filter, map, pairwise, tap} from "rxjs/operators";
import {NetworkService} from "@app/services/network.service";
import {ChannelsService} from "@app/services/channels/channels.service";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ChannelDTO = DTOs.ChannelDTO;
import NetworkDTO = DTOs.NetworkDTO;
import {FeedContextService} from "@app/services/feed-context.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {FeedService} from "@app/services/feed/feed.service";
import {ViewModeStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";

@Component({
  selector: 'app-desktop-view',
  templateUrl: './desktop-view.component.html',
  styleUrls: ['./desktop-view.component.scss'],
})
export class DesktopViewComponent implements OnInit, OnDestroy {
  nativeScrollDirection$: Subscription | undefined;
  showSingleCardView = false;
  channel$: Observable<ChannelDTO | undefined> = this.channelService.selectedChannel$;
  network$: Observable<NetworkDTO | undefined> = this.networkService.selectedNetwork$;
  contextLayout$  = this.feedContextService.layout$;
  feedIsLoading$ = this.feed.feed$.pipe(
    map((feed) => feed.loading),
  )
  breakPoint$ = this.breakpoint.observe('(max-width: 1800px)').pipe(
    map((res) => res.matches),
  );
  layout$ = combineLatest([this.contextLayout$, this.breakPoint$]).pipe(
    map(([layout, breakpoint]) => {
      if(breakpoint) {
        return 'feed'
      } else {
        return layout
      }
    })
  )

  triggerSingleView$ : any;
    protected readonly VIEW_MODE = VIEW_MODE;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  loadingFeed: boolean = false;

  constructor(
    private networkService: NetworkService,
    private channelService: ChannelsService,
    private feedContextService: FeedContextService,
    private feed: FeedService,
    private breakpoint: BreakpointObserver,
    private appService: AppService) {
  }

  ngOnInit() {
    this.triggerSingleView$ = this.appService.triggerSingleView$.subscribe((stack) => {
      this.showSingleCardView = true;
    });
  }

  isNetworkDTO(obj: any): obj is NetworkDTO {
    return "id" in obj && "config" in obj && "channels" in obj;
  }

  ngOnDestroy() {
    this.triggerSingleView$?.unsubscribe();
  }

  protected readonly ViewModeStrategy = ViewModeStrategy;
}

