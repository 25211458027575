import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from "@angular/core";
import {fromEvent, Subscription} from "rxjs";
import {tap} from "rxjs/operators";

@Directive({
  selector: '[appScrolledBottom]'
})
export class ScrolledBottomDirective implements AfterViewInit, OnDestroy {

  @Input() appScrolledBottomOffset : number = 0;
  @Output() appScrolledBottomTrigger = new EventEmitter<boolean>();
  scrollBottom$ : Subscription | undefined;
  reachedBottom = false;
  constructor(private el: ElementRef) {

  }

  ngAfterViewInit() {
    this.scrollBottom$ = fromEvent(this.el.nativeElement, "scroll").pipe(
      tap(() => {
        const scrollTopDistance = this.el.nativeElement.scrollTop;
        const scrollHeight = this.el.nativeElement.scrollHeight;
        const containerHeight = this.el.nativeElement.clientHeight;
        const distanceToBottom = scrollHeight - scrollTopDistance - containerHeight;
        const reachedBottom = distanceToBottom - this.appScrolledBottomOffset <= 0;
        //only emits when changed
        if(this.reachedBottom !== reachedBottom) {
          this.reachedBottom = reachedBottom;
          this.appScrolledBottomTrigger.emit(reachedBottom);
        }

      })
    ).subscribe()
  }

  ngOnDestroy() {
    this.scrollBottom$?.unsubscribe();
  }

}
