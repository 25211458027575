import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChannelsService} from "@app/services/channels/channels.service";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ChannelDTO = DTOs.ChannelDTO;
import {DialogService} from "@app/services/dialog.service";
import {CreateChannelDialogComponent} from "@app/custom.components/dialogs/create-channel-dialog/create-channel-dialog.component";
import {filter, map, tap} from "rxjs/operators";
import {SnackbarService} from "@app/services/snackbar.service";
import {catchError, finalize, of} from "rxjs";
import {NetworkService} from "@app/services/network.service";
import {NetworkApiService} from "@app/services/network-api/network-api.service";

@Component({
  selector: "app-select-channel-dialog",
  templateUrl: "./select-channel-dialog.component.html",
  styleUrls: ["./select-channel-dialog.component.scss"],
})
export class SelectChannelDialogComponent {
  channels$;
  selectedChannelIds = [];
  networkId: string;
  loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private networkService: NetworkService,
    private networkApi: NetworkApiService,
    public dialogRef: MatDialogRef<SelectChannelDialogComponent>,
    private channelService: ChannelsService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService
  ) {
    this.networkId = this.data;
    this.channels$ = this.channelService.channelList$.pipe(
      map((channels) => {
        //filter out channels that are already present in the network
        const newChannels = channels.filter((channel) => {
          const network = this.networkService.getNetworkById(this.networkId);
          const networkChannels = network?.channels;
          //true if channel is present in network, false if not
          if (!networkChannels || networkChannels?.length < 1) {
            return true;
          }
          //check if networkChannels already includes this channel
          return !networkChannels?.map((_channel) => _channel.id).includes(channel.id);
        });
        return newChannels;
      })
    );
  }

  addChannelsToNetwork(channelIds: string[], networkId: string) {
    this.loading = true;
    this.networkApi
      .addChannelsToNetwork(networkId, channelIds)
      .pipe(
        tap(() => {
          const channels = channelIds.map((id) => {
            return this.channelService.getChannelById(id) as ChannelDTO;
          });
          this.networkService.addChannelsToNetwork(channels, networkId);
          this.snackbarService.showSnackbar("Successfully added Channels");
        }),
        catchError((err) => {
          this.snackbarService.showSnackbar("Could not add Channels");
          return of(err);
        }),
        finalize(() => {
          this.loading = false;
          this.dialogRef.close();
        })
      )
      .subscribe();
  }

  createChannel() {
    this.dialogService.openDialog(CreateChannelDialogComponent, this.networkId);
  }

  selectionListChange(selectionList: any) {
    const selectionIds: Array<string> = selectionList.selectedOptions.selected.map(
      (option: any) => option.value.id
    );
    // @ts-ignore
    this.selectedChannelIds = selectionIds;
  }
}
