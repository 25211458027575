import {Component, Input, OnInit, Output, EventEmitter, ElementRef} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {tap} from "rxjs/operators";
import {StackCacheService} from "@app/services/card-loader/stack-cache.service";
import {Card} from "@mrbeany/stacks_shared";
import {IdService} from "@app/services/id.service";
import {Button} from "@mrbeany/stacks_shared/lib/models/card-elements/button";
import {StackService} from "@app/card.components/stack/stack.service";

@Component({
  selector: "app-button-container-button",
  template: `
    <ng-container *ngIf="button">
      <div *ngIf="!(edit$ | async)" class="display-button flat-button">
        <button  (click)="navigateTo.emit(button.CardID)" mat-flat-button color="primary">
          <span>{{ button.description }}</span>
          <mat-icon class="rotate45" >route</mat-icon>
        </button>
        <button class="edit-icon shadow" mat-icon-button [matMenuTriggerFor]="edit_actions" >
          <mat-icon>settings</mat-icon>
        </button>
      </div>

      <div class="edit-button flat-button shadow" *ngIf="edit$ | async">
        <input class="input" (keyup.enter)="onConfirm()" autofocus [(ngModel)]="button.description"/>
        <button (click)="onConfirm()" mat-icon-button>
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </ng-container>

    <mat-menu #edit_actions="matMenu">
      <button mat-menu-item (click)="_edit.next(true); preventEvent($event)">
        <mat-icon>edit</mat-icon>
        Rename
      </button>
      <button mat-menu-item (click)="deleteButton.emit(index)">
        <mat-icon color="warn">delete</mat-icon>
        Delete
      </button>
    </mat-menu>

  `
})
export class ButtonContainerButtonComponent implements OnInit {


  @Input() button?: Button;
  @Input() index!: number;
  @Output() navigateTo = new EventEmitter<string | undefined>();
  @Output() deleteButton = new EventEmitter<number>();
  @Input() set edit(edit: boolean | undefined) {
    this._edit.next(edit);
  }

  constructor(
    private el: ElementRef,
  ) {
  }

  _edit = new BehaviorSubject<boolean | undefined>(false);
  edit$ = this._edit.asObservable().pipe(
    tap((res) => {
      if(this.button) {
        this.button.edit = res;
      };
      if(res) {
        setTimeout(() => {
          const input : HTMLInputElement | undefined = this.el.nativeElement.querySelector("input");
          if(input) {
            input.focus();
          }
        }, 0)
      }
    })
  );

  preventEvent($event: MouseEvent) {
    $event.stopPropagation()
  }

  onConfirm() {
    this._edit.next(false);
  }

  ngOnInit() {
  }


}
