import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CardComponent} from "./card.component";
import {CardElementsModule} from "../card.elements/card-elements.module";
import {LoadingCardBodyComponent} from "./loading-card-body/loading-card-body.component";
import {ElementListModule} from "../element-list/element-list.module";



@NgModule({
  declarations: [
    CardComponent,
    LoadingCardBodyComponent
  ],
  imports: [
    CommonModule,
    CardElementsModule,
    ElementListModule
  ],
  exports: [
    CardComponent
  ]
})
export class CardModule { }
