import {Component, Inject, Input, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {CardService} from '@app/services/card.service';
import {ChannelsService} from '@app/services/channels/channels.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  @Input() channels = this.channelService.channelList$;
  cardId!: string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShareData,
    public dialogRef: MatDialogRef<ShareComponent>,
    private cardService: CardService,
    private channelService: ChannelsService
  ) {}

  ngOnInit(): void {
    this.cardId = this.data.cardID;
  }

  close() {
    this.dialogRef.close();
  }

  sendCardToChannel(cardId: string, channelId: string) {
    this.close();
    this.cardService.shareCard({
      cardId: cardId,
      channelId: channelId,
    });
  }
}
export interface ShareData {
  cardID: string;
}
