import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {LoadingButtonSpinnerComponent} from "./loading-button-spinner.component";



@NgModule({
  declarations: [
    LoadingButtonSpinnerComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    LoadingButtonSpinnerComponent
  ]
})
export class LoadingButtonSpinnerModule { }
