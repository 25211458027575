<div class="top-feed-overlay">
  <div *ngIf="showBreadCrumbs" class="breadcrumbs">
    <span class="title">{{ channelName }}</span>
    <div class="breadcrumbs-actions">
      <button
        *ngIf="canJoin$ | async"
        appRoleGuard
        class="button"
        mat-button
        color="accent"
        (click)="joinChannel()"
      >
        <span *ngIf="!joiningChannel"> Join </span>
        <app-loading-button-spinner *ngIf="joiningChannel"></app-loading-button-spinner>
      </button>
    </div>
  </div>
  <button
    *ngIf="showRefreshFeed"
    mat-stroked-button
    class="refresh-feed-button"
    (click)="onRefreshFeed()"
  >
    <span>Load New</span>
  </button>
</div>
<div class="bottom-feed-overlay">
  <button mat-fab color="primary" appRoleGuard *ngIf="showCreateCardButton" (click)="onCreateCard()">
    <mat-icon>add</mat-icon>
  </button>
</div>
