<div class="card">
  <div class="cardheader-wrapper">
    <app-cardheader-title> Network Settings </app-cardheader-title>
  </div>
  <form *ngIf="form" [formGroup]="form" class="card-elements">
    <mat-checkbox formControlName="public" color="primary">Public</mat-checkbox>
    <mat-form-field appearance="outline">
      <input matInput formControlName="name" />
      <mat-label>Network Name</mat-label>
      <mat-error *ngIf="form.hasError('maxLength')">Max 24 characters</mat-error>
      <mat-error *ngIf="form.hasError('required')">Max 24 characters</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Layout</mat-label>
      <mat-select
        formControlName="layout"
      >
        <mat-option value="feed" (click)="updateLayout()">
          Feed
        </mat-option>
<!--        <mat-option value="grid" (click)="updateLayout()">
          Grid
        </mat-option>-->
        <mat-option value="masonry" (click)="updateLayout()">
          Masonry
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="center" mat-button color="warn" (click)="deleteNetwork()">
      <span *ngIf="!deletingNetwork"> Delete Network </span>
      <app-loading-button-spinner *ngIf="deletingNetwork"></app-loading-button-spinner>
    </button>
  </form>
</div>
<div *ngIf="form?.dirty" class="creater-buttons-wrapper">
  <button
    [disabled]="!form?.dirty || form?.invalid || configLoading"
    mat-button
    (click)="onCancel()"
  >
    <mat-icon>close</mat-icon>
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!form?.dirty || form?.invalid || configLoading"
    (click)="submitForm(form?.getRawValue())"
  >
    <mat-icon *ngIf="!configLoading">save</mat-icon>
    <mat-icon *ngIf="configLoading">
      <mat-spinner [diameter]="24" [strokeWidth]="3"></mat-spinner>
    </mat-icon>
    <span> Save Settings </span>
  </button>
</div>
