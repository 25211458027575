<!--If you ever want to refactor this: cdk-virtual-scroll-viewport is present 2 times
because we use the scrollWindow directive on the mobile version.
There is currently no way to insert this directive conditionally.
We also can't use ng-template to get rid of the duplicate *cdk-VirtualFor. -->


<!--<div
  *ngIf="!(feed$ | async) && !(channelInvite$ | async)"
  class="spinner-container loading-feed-spinner"
>
  <mat-spinner diameter="50" strokeWidth="4"></mat-spinner>
</div>-->

<ng-container *ngIf="feed$ | async as feed">
  <div
    class="join-channel-container"
    *ngIf="!(channelRole$ | async) && (channelInvite$ | async) as channelInvite"
  >
    <h3>You have been invited to join</h3>
    <h1>{{ channelName$ | async }}</h1>
    <button
      *ngIf="!joiningChannel"
      mat-flat-button
      color="primary"
      appRoleGuard
      (click)="acceptChannelInvite(channelInvite.channelId, channelInvite.inviteKey)"
    >
      Join Channel
    </button>
    <app-loading-button-spinner *ngIf="joiningChannel"></app-loading-button-spinner>
  </div>
  <div *ngIf="feed.loading" class="spinner-container">
    <mat-spinner class="button-loading-spinner" diameter="50" strokeWidth="4"></mat-spinner>
  </div>
  <ng-container *ngIf="!(isHandset$ | async)">
    <app-masonry *ngIf="layout === 'masonry'" [feed]="feed" [columns]="columns$ | async" (loadNextPage)="loadNextPage()" [isLoading]="loadingNextPage"></app-masonry>
    <cdk-virtual-scroll-viewport
      *ngIf="layout !== 'masonry'"
      appScrolledBottom
      [appScrolledBottomOffset]="1000"
      (appScrolledBottomTrigger)="loadNextPage()"
      [class.layout--grid]="layout === 'grid'"
      [class.layout--feed]="layout === 'feed'"
      autosize
      appendOnly
      minBufferPx="100"
      maxBufferPx="250"
      class="scroll-viewport"
    >
      <!--
      hiding with ngIf instead of class.hidden messes up the virtual viewport for some reason
    -->
      <div
        class="content"
        [class.hidden]="feed.loading"
        *cdkVirtualFor="let stack of feed?.state?.stacks!; let i = index; trackBy: trackByFn"
      >
        <app-stack *ngIf="i % 2 === 0 || layout !== 'masonry'" [stack]="stack" [viewStrategy]="ViewModeStrategy.Display"></app-stack>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>


<ng-container  *ngIf="isHandset$ | async">
  <app-masonry *ngIf="layout === 'masonry'" [feed]="feed" [columns]="columns$ | async" (loadNextPage)="loadNextPage()" [isLoading]="loadingNextPage"></app-masonry>
  <cdk-virtual-scroll-viewport
    *ngIf="layout !== 'masonry'"
    [class.layout--grid]="layout === 'grid'"
    [class.layout--feed]="layout === 'feed'"
    appendOnly
    autosize
    minBufferPx="1000"
    maxBufferPx="2000"
    class="scroll-viewport"
  >
    <div
      class="join-channel-container"
      *ngIf="!(channelRole$ | async) && (channelInvite$ | async) as channelInvite"
    >
      <h3>You have been invited to join</h3>
      <h1>{{ channelName$ | async }}</h1>
      <button
        *ngIf="!joiningChannel"
        mat-flat-button
        color="primary"
        appRoleGuard
        (click)="acceptChannelInvite(channelInvite.channelId, channelInvite.inviteKey)"
      >
        Join Channel
      </button>
      <app-loading-button-spinner *ngIf="joiningChannel"></app-loading-button-spinner>
    </div>
    <!--  breadcrumbs spacer-->
    <div style="width: 100%; height: 3rem"></div>
    <app-card-editor
      [class.hidden]="!showCreateCard"
      [show]="false"
      (cancelEdit)="onCancelCardCreator()"
    ></app-card-editor>
    <div *ngIf="feed.loading" class="spinner-container">
      <mat-spinner class="button-loading-spinner" diameter="50" strokeWidth="4"></mat-spinner>
    </div>
    <!--
    hiding with ngIf instead of class.hidden messes up the virtual viewport for some reason
    -->
    <div
      #stackRef
      class="content"
      *cdkVirtualFor="let stack of feed?.state?.stacks!; trackBy: trackByFn; let i = index"
    >
      <ng-container *ngIf="!feed.loading">
        <app-stack  [stack]="stack" (navigating)="onNaviagteTo($event, stackRef)" (clone)="scrollToTop(); showCreateCard = true" [viewStrategy]="ViewModeStrategy.Display"></app-stack>
      </ng-container>
    </div>

    <div *ngIf="loadingNextPage && (feed$ | async)" class="spinner-container">
      <mat-spinner class="button-loading-spinner" diameter="50" strokeWidth="4"></mat-spinner></div
    ></cdk-virtual-scroll-viewport>
</ng-container>
</ng-container>

