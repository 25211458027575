import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../authentication/authentication.service";
import {catchError, from, of} from "rxjs";
import {map, switchMap, tap} from "rxjs/operators";

export const initNavigationGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  const isLoggedIn$ = from(authService.isLoggedIn());

  return isLoggedIn$.pipe(
    catchError((err) => {
      router.navigateByUrl('/welcome')
      return of(err)
    }),
    tap((isLoggedIn) => {
      if(isLoggedIn) {
        router.navigateByUrl('/home')
      } else {
        router.navigateByUrl('/welcome')
      }
    }),
    switchMap((res) => {
      return of(true);
    })
  )
};
