import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContactComponent} from "./contact.component";
import {MatIconModule} from "@angular/material/icon";
import {
  LoadingButtonSpinnerModule
} from "../../../custom.components/loading-button-spinner/loading-button-spinner.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "../../../material.dependencies";
import {MatButtonModule} from "@angular/material/button";



@NgModule({
  declarations: [
    ContactComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    ContactComponent
  ]
})
export class ContactModule { }
