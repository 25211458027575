import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextComponent} from "./text.component";
import {MatMenuModule} from "@angular/material/menu";
import {FormsModule} from "@angular/forms";
import {TableSelectModule} from "../../../custom.components/table-select/table-select.module";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatIconModule} from "@angular/material/icon";
import {
  MarkdownCheatsheetComponent
} from "@app/card.components/card.elements/text/markdown-cheatsheet/markdown-cheatsheet.component";



@NgModule({
  declarations: [
    TextComponent,
    MarkdownCheatsheetComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    FormsModule,
    TableSelectModule,
    MatSlideToggleModule,
    MatIconModule
  ],
  exports: [
    TextComponent
  ]
})
export class TextModule { }
