import {Component, OnInit, signal} from "@angular/core";
import {Router} from "@angular/router";
import {CardService, getCardLink} from "@app/services/card.service";
import {Card, Stack} from "@mrbeany/stacks_shared";
import {WizardService} from "@app/pages/wizard/wizard.service";
import {environment} from "../../../../environments/environment";
import {AuthenticationService} from "@app/authentication/authentication.service";
import {UploadService} from "@app/services/upload.service";
import {dbGetStack, dbSaveStack} from "@app/pages/wizard/wizard-builder/wizard-builder.component";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {catchError, finalize, of} from "rxjs";

@Component({
  selector: 'app-wizard-share',
  templateUrl: './wizard-share.component.html',
  styleUrls: ['./wizard-share.component.scss']
})
export class WizardShareComponent implements OnInit{

  shareAddress?: string;
  contentId?: string;
  title?: string;
  id?: string;
  postingCard: boolean = false;
  showClaimUrl = true;
  showClaimUrlTaken = false;

  constructor(
    private router: Router,
    private cardService: CardService,
    private uploadService: UploadService,
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient,
    private wizardService: WizardService,

  ) {
  }
  ngOnInit() {
    //user had to login first and the stack has to be retrieved from memory
    dbGetStack('wizard-stack').then((stack: Stack) => {
      if (stack) {
        this.postingCard = true;
        this.cardService.postStack(stack, undefined).then((res) => {
          const savedStack: Stack = res.data;
          dbSaveStack(savedStack, 'wizard-stack');
          this.contentId = savedStack.id;
          this.title = savedStack.cards[0].header?.title;
          this.postingCard = false;
          //claim Stack/Card Url
          if(this.wizardService.appsyUrl &&  savedStack.id) {
            this.cardService.claimAddress(this.wizardService.appsyUrl, savedStack.id)
              .pipe(
                tap((_res: any) => {
                  if (_res.url) {
                    this.shareAddress = _res.url;
                  }
                }),
                catchError((err: HttpErrorResponse) => {
                  if (err.status === 409) {
                    this.showClaimUrlTaken = true;
                  }
                  return of(err);
                }),
                finalize(() => {
                })
              )
              .subscribe()
          }
        }).finally(() => {
          this.postingCard = false;
        });
        //upload new avatar based on the card header
        if (stack.cards[0].header?.avatarIMG?.startsWith('data:image/')) {
          const base64Avataar = stack?.cards[0]?.header?.avatarIMG;
          fetch(base64Avataar)
            .then(res =>  res.blob())
            .then(blob => this.uploadService.uploadAvatar(blob));
        }
        if (stack.cards[0].header?.username) {
          //upload username based on the card header
          this.authenticationService.setUserName(stack.cards[0].header.username).subscribe();
        }
      } else {
        this.router.navigateByUrl('/wizard/builder');
      }
    }).catch((err) => {
      this.router.navigateByUrl('/wizard/builder');
    });

  }


  // navigator.clipboard.writeText(link);
  // this.snackbar.showSnackbar("Link copied to Clipboard");

  saveStack() {

  }
}
