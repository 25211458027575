import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmailSubComponent} from "./email-sub.component";
import {MatIconModule} from "@angular/material/icon";
import {
  LoadingButtonSpinnerModule
} from "../../../custom.components/loading-button-spinner/loading-button-spinner.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "@app/material.dependencies";



@NgModule({
  declarations: [
    EmailSubComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    LoadingButtonSpinnerModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    EmailSubComponent
  ]
})
export class EmailSubModule { }
