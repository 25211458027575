import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {SpotifyComponent} from "./spotify.component";
import {FormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {PipesModule} from "../../../pipes/pipes.module";
import {MaterialModule} from "@app/material.dependencies";



@NgModule({
  declarations: [
    SpotifyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    PipesModule,
    MaterialModule,
    NgOptimizedImage
  ],
  exports: [
    SpotifyComponent
  ]
})
export class SpotifyModule { }
