import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  WritableSignal
} from "@angular/core";
import {VIEW_MODE} from "../../../enum";
import {CARD_ELEMENT_TYPE, CardText} from "@mrbeany/stacks_shared/lib/models/card-elements.module";
import TurndownService from "turndown";
import {buttonLinkPlugin} from "@app/card.components/card.elements/text/card-link.md-rule";
import {EditorService} from "@app/card.components/card.elements/text/editor.service";
import {DialogService} from "@app/services/dialog.service";
import {MarkdownCheatsheetComponent} from "@app/card.components/card.elements/text/markdown-cheatsheet/markdown-cheatsheet.component";
import {Button} from "@mrbeany/stacks_shared/lib/models/card-elements/button";
import {CardNavEvent} from "@app/card.components/card/card.component";

const turndownPluginGfm = require("joplin-turndown-plugin-gfm");
const MarkdownIt = require("markdown-it")({
  html: false,
  linkify: true,
})
  .use(buttonLinkPlugin)
  .disable("image");

@Component({
  selector: "app-text",
  templateUrl: "./text.component.html",
  styleUrls: ["./text.component.scss"],
})
export class TextComponent implements OnInit, AfterViewInit, OnDestroy {
  md = MarkdownIt;
  // Remember the old renderer if overridden, or proxy to the default renderer.


  gfm = turndownPluginGfm.gfm;
  td = new TurndownService({
    headingStyle: "atx",
    codeBlockStyle: "fenced",
  })
    //rule for converting html to .md
    //mirror rule of card-link.md-rule.ts
    .addRule("card-link", {
      filter: ["a"],
      replacement: function (content, node: any) {
        const classes : DOMTokenList = node.classList;
        if (classes.contains('card-text-link')) {
          return "+" + content + "+" + "(" + node.attributes.href.value + ")";
        } else {
          return "[" + content + "]" + "(" + node.attributes.href.value + ")";
        }
      }
    })
    //convert weblinks to weblinks, not card-links
    .use(this.gfm);
  html: string | undefined;
  clickContext: TableClickContext | undefined;
  _viewMode: VIEW_MODE = VIEW_MODE.DISPLAY;

  @Input() text!: WritableSignal<CardText>;
  @Input() viewMode: VIEW_MODE = VIEW_MODE.DISPLAY;
  @Input() submitted = false;
  @Output() navigateTo = new EventEmitter<CardNavEvent>();
  isValid = false;
  VIEW_MODE = VIEW_MODE;
  maxChar = 500;
  displayMarkdown = false;
  type = CARD_ELEMENT_TYPE.TEXT;
  @ViewChild("editorText") editorText!: ElementRef;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private editorService: EditorService,
    private renderer: Renderer2,
    private dialog: DialogService,
    private elementRef: ElementRef
  ) {
    //cancel default behavior of anker tags for card links
    this.renderer.listen(this.elementRef.nativeElement, "click", (event) => {
      const element = event.target || event.srcElement || event.currentTarget;
      if (element?.attributes?.class?.value === "card-text-link") {
        const cardId = element.attributes.href.value;
        this.navigateTo.emit({link: cardId});
        event.preventDefault();

      } else {
        event?.currentTarget?.dispatchEvent(event);
      }
    });

    if (this.md) {
      //@ts-ignore
      const defaultRender = this.md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
      };
      //@ts-ignore
      this.md.renderer.rules.link_open = function (tokens: any, idx: any, options: any, env: any, self: any) {
        // Add a new `target` attribute, or replace the value of the existing one.
        tokens[idx].attrSet('target', '_blank');

        // Pass the token to the default renderer.
        return defaultRender(tokens, idx, options, env, self);
      };
    }
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.renderHTML();
  }

  ngOnDestroy() {
  }

  toggleEditor() {
    if (this.displayMarkdown) {
      this.renderHTML();
      this.displayMarkdown = false;
    } else {
      this.updateMarkdown();
      this.displayMarkdown = true;
    }
  }

  renderHTML() {
    const markdown = this.text().text;
    if (markdown) {
      this.html = this.md.render(this.text().text);
    } else {
      this.html = `<p><br></p>`;
    }
  }

  updateMarkdown() {
    const html = this.editorText?.nativeElement.innerHTML;
    const markDown = this.td.turndown(html ? html : "");
    this.text.mutate((text) => {
      text.text = this.td.turndown(html ? html : "")
    });
  }

  //todo: refactor to use the renderer and move to the editor service
  addTable($event: {rows: number; columns: number}) {
    this.html = this.html?.concat(this.editorService.generateTable($event.rows, $event.columns)).concat('<p><br></p>');
  }

  addColumn() {
    if (!this.clickContext) {
      return;
    }
    this.editorService.addColumn(
      this.clickContext.target,
      this.clickContext.columns,
      this.clickContext.column
    );
  }

  removeColumn() {
    if (this.clickContext?.columns === 1) {
      this.deleteTable();
      return;
    }
    if (!this.clickContext) {
      return;
    }
    this.editorService.removeColumn(this.clickContext.target, this.clickContext.column);
  }

  removeRow() {
    if (this.clickContext?.rows === 1) {
      this.deleteTable();
      return;
    }
    if (!this.clickContext || !this.clickContext?.target?.parentElement?.parentElement) {
      return;
    }
    this.editorService.removeRow(this.clickContext.target);
    const tableBody = this.clickContext?.target?.parentElement?.parentElement;
    this.setClickContext(
      tableBody.children[this.clickContext.row - 1].children[this.clickContext.column] //TD
    );
  }

  addRow() {
    if (!this.clickContext) {
      return;
    }
    this.editorService.addRow(
      this.clickContext.target,
      this.clickContext.columns,
      this.clickContext.rows,
      this.clickContext.row
    );
  }

  deleteTable() {
    if (!this.clickContext) {
      return;
    }
    this.editorService.deleteTable(this.clickContext.target);
    this.clickContext = undefined;
  }
  setClickContext(node: any) {
    node = node as Node;
    if (!node || (node.nodeName !== "TD" && node.nodeName !== "TH")) {
      return;
    }
    if (node.nodeName === "TD" || node.nodeName === "TH") {
      const currTarget = node;
      const targetColumn = this.getColumnIndex(currTarget);
      const targetRow = this.getRowIndex(currTarget);
      const table = currTarget.parentElement.parentElement.parentElement;
      const header = table.children[0];
      const body = table.children[1];
      this.clickContext = {
        target: node,
        row: targetRow,
        column: targetColumn,
        rows: body?.children?.length + 1,
        columns: header.children[0].children.length,
      };
    } else {
      this.clickContext = undefined;
    }
  }

  getColumnIndex(elementTarget: any): number {
    let target = elementTarget;
    let targetColumn = 0;
    while (target !== undefined || null) {
      if (target?.previousSibling) {
        targetColumn++;
      }
      target = target?.previousSibling;
    }
    return targetColumn;
  }
  getRowIndex(elementTarget: any) {
    let target = elementTarget.parentElement; // TR, table row
    let targetRow = 0;
    if (target.parentElement.tagName === "THEAD") {
      return 0;
    }
    while (target !== undefined || null) {
      if (target?.parentElement?.previousSibling) {
        targetRow++;
      }
      target = target?.previousSibling;
    }
    return targetRow;
  }

  openMarkdownCheatSheet() {
    this.dialog.openDialog(MarkdownCheatsheetComponent, undefined, undefined, {});
  }
}
interface TableClickContext {
  target: Node;
  row: number;
  column: number;
  rows: number;
  columns: number;
}
