<app-share-card *ngIf="!postingCard && contentId"
                [title]="title"
                [address]="shareAddress"
                [id]="contentId" class="shadow">
</app-share-card>
<button *ngIf="!postingCard" class="continue-home-button button" routerLink="/home" mat-button>Continue Home</button>
<div class="spinner-background" *ngIf="postingCard">
  <mat-spinner strokeWidth="5" diameter="60"></mat-spinner>
  <h3>
    Saving your Appsy
  </h3>
</div>
