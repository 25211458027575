
<img routerLink="/" class="logo pointer" src="assets/images/logo.svg" alt="">

<h1>Privacy Policy</h1>
<p><em>Last Updated: 28.08.2024</em></p>

<p>This Privacy Policy explains how Lars Vink collects, uses, and discloses your personal information when you use our platform and services.</p>

<h2>1. Information We Collect</h2>
<p>We collect the following personal information from users:</p>
<ul>
  <li><strong>Email Address</strong>: Collected when users register on our platform or subscribe to our services.</li>
</ul>

<h2>2. How We Use Your Information</h2>
<p>We use the collected information for the following purposes:</p>
<ul>
  <li>To provide and maintain our services.</li>
  <li>To communicate with you, including responding to your inquiries and sending important updates and notifications regarding your use of the platform.</li>
</ul>

<h2>3. Data Sharing and Disclosure</h2>
<p>We do not share your personal information with third parties except in the following circumstances:</p>
<ul>
  <li><strong>Legal Requirements</strong>: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
</ul>

<h2>4. Data Security</h2>
<p>We take reasonable measures to protect the personal information we collect from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or method of electronic storage is completely secure, and we cannot guarantee the absolute security of your data.</p>

<h2>5. Your Rights</h2>
<p>You have the right to:</p>
<ul>
  <li><strong>Access</strong>: Request access to the personal information we hold about you.</li>
  <li><strong>Rectification</strong>: Request correction of any inaccurate or incomplete personal information.</li>
  <li><strong>Erasure</strong>: Request the deletion of your personal information, subject to certain legal obligations.</li>
  <li><strong>Restriction</strong>: Request the restriction of processing your personal information in certain circumstances.</li>
</ul>
<p>To exercise any of these rights, please contact us at <a href="mailto:contact@vinkweb.lu">contact@vinkweb.lu</a>.</p>

<h2>6. Cookies and Tracking Technologies</h2>
<p>Our platform does not use cookies or any other tracking technologies.</p>

<h2>7. Changes to This Privacy Policy</h2>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by email or through our platform. Your continued use of the platform after such notification constitutes your acceptance of the revised Privacy Policy.</p>

<h2>8. Contact Us</h2>
<p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:contact@vinkweb.lu">contact@vinkweb.lu</a>.</p>
