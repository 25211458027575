import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-create-channel-dialog',
  templateUrl: './create-channel-dialog.component.html',
  styleUrls: ['./create-channel-dialog.component.scss'],
})
export class CreateChannelDialogComponent {
  networkId: string | undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<CreateChannelDialogComponent>
  ) {
    if (this.data) {
      this.networkId = this.data;
    }
  }
  close() {
    this.dialogRef.close();
  }
}
