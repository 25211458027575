import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CardheaderComponent} from "./cardheader.component";
import {CardheaderTitleComponent} from "./cardheader-title/cardheader-title.component";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule} from "@angular/forms";
import {LoadingButtonSpinnerModule} from "@app/custom.components/loading-button-spinner/loading-button-spinner.module";
import {GalleryModule} from "@app/card.components/card.elements/gallery/gallery.module";
import {PipesModule} from "@app/pipes/pipes.module";
import {ConfirmationModule} from "@app/modules/confirmation/confirmation.module";



@NgModule({
  declarations: [CardheaderComponent, CardheaderTitleComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    FormsModule,
    LoadingButtonSpinnerModule,
    GalleryModule,
    PipesModule,
    ConfirmationModule,
  ],
  exports: [CardheaderComponent, CardheaderTitleComponent],
})
export class CardHeaderModule {}
