import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HyperlinkComponent} from "./hyperlink.component";
import {
  ConfirmExternalNavigationDialogComponent
} from "./confirm-external-navigation-dialog/confirm-external-navigation-dialog.component";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule} from "@angular/forms";
import {DirectivesModule} from "@app/directives/directives.module";

@NgModule({
  declarations: [HyperlinkComponent, ConfirmExternalNavigationDialogComponent],
  imports: [CommonModule, MatIconModule, FormsModule, DirectivesModule],
  exports: [HyperlinkComponent, ConfirmExternalNavigationDialogComponent],
})
export class HyperlinkModule {}
