<table (mouseleave)="highlight(undefined, undefined)">
  <tr *ngFor="let row of table; let i = index">
    <td
      *ngFor="let cell of row; let j = index"
      [class.hover-shadow]="
        highlightRow !== undefined &&
        highlightCol !== undefined &&
        j <= highlightCol && i <= highlightRow
      "
      (mouseenter)="highlight(i, j)"
      (click)="onCreate(i, j)"
    ></td>
  </tr>
</table>
