import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from "@angular/core";
import {AuthenticationService, guestUser} from "@app/authentication/authentication.service";
import {DialogService} from "@app/services/dialog.service";
import {SignupComponent} from "@app/authentication/signup/signup.component";
import {AuthCardComponent} from "@app/authentication/auth-card/auth-card.component";

@Directive({
  selector: '[appRoleGuard]',
  providers: [DialogService]
})
export class RoleGuardDirective{

  @Input() appRoleGuardRedirect?: string;

  constructor(
    private dialog: DialogService,
    private el: ElementRef,
    private authenticationService: AuthenticationService
  ) {
    this.el.nativeElement.addEventListener('click', (event: MouseEvent) => {
      this.authenticationService.isLoggedIn().catch((err) => {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.dialog.openDialog(AuthCardComponent, {
          redirectUrl: this.appRoleGuardRedirect,
          signin: false
        });
        return
      }).then((res) => {
        if(!res) {
          event.preventDefault();
          event.stopImmediatePropagation();
          this.dialog.openDialog(AuthCardComponent, {
            redirectUrl: this.appRoleGuardRedirect,
            signin: false
          });
          return
        }

      })
    })
  }


}
