export const RoutePath = {
  HOME: "home",
  AUTH: "auth",
  REGISTER: "register",
  SETTINGS: "settings",
  WELCOME: "welcome",
  GITHUBCALLBACK: "auth/callback/github",
  GOOGLECALLBACK: "auth/callback/google",
  USER: {
    CARDS: "cards",
  },
  CARD: "card",
  WIZARD: "wizard",
};
