import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Card, Stack} from "../../../../node_modules/@mrbeany/stacks_shared";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import {HttpErrorResponse} from "../../../../node_modules/@angular/common/http";
import {STACK_TYPE} from "../../../../node_modules/@mrbeany/stacks_shared/lib/models/stack";
import {catchError, Observable, throwError} from "rxjs";
import {map} from "rxjs/operators";
import {isCard} from "@app/services/feed/feed.service";
import JoinChannelDTO = DTOs.JoinChannelDTO;
import ChannelDTO = DTOs.ChannelDTO;
import ChannelConfigDTO = DTOs.ChannelConfigDTO;

@Injectable({
  providedIn: "root",
})
export class ChannelApiService {
  constructor(private http: HttpClient) {}

  getChannelCards(channelId: string, page: number, take: number): Observable<Array<Stack>> {
    return this.http
      .get<Array<Card | Stack>>(environment.baseUrlServer + "/channel/content", {
        params: {
          channelId: channelId,
          page: page,
          take: take,
        },
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        }),
        map((res) =>
          res.map((content) => {
            if (isCard(content)) {
              return new Stack(content.id, STACK_TYPE.SINGLE, [content]);
            }
            return content;
          })
        )
      );
  }

  getChannel(id: string) {
    return this.http.get<ChannelDTO>(environment.baseUrlServer + "/channel", {
      params: {
        channelId: id,
      },
    });
  }

  joinChannel(dto: JoinChannelDTO) {
    return this.http.post(environment.baseUrlServer + "/channel/membership", {
      dto: dto,
    });
  }

  leaveChannel(channelId: string) {
    return this.http.delete(environment.baseUrlServer + "/channel/membership", {
      params: {channelId: channelId},
    });
  }

  getUserChannels(): Observable<ChannelDTO[]> {
    return this.http.get<ChannelDTO[]>(environment.baseUrlServer + "/users/current/channels");
  }

  submitChannelSettings(config: ChannelConfigDTO, channelId: string): Observable<ChannelDTO> {
    return this.http.post<ChannelDTO>(environment.baseUrlServer + "/channel/config", config, {
      params: {
        channelId: channelId,
      },
    });
  }
}
