import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CardModule} from "../card/card.module";
import {StackComponent} from "./stack.component";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {MaterialModule} from "@app/material.dependencies";
import { ShareCardComponent } from './share-card/share-card.component';
import {FormsModule} from "@angular/forms";
import {LoadingButtonSpinnerModule} from "@app/custom.components/loading-button-spinner/loading-button-spinner.module";
import {RouterLink} from "@angular/router";
import {MatRippleModule} from "@angular/material/core";
import {DirectivesModule} from "@app/directives/directives.module";



@NgModule({
  declarations: [StackComponent, ShareCardComponent],
  imports: [
    CommonModule,
    CardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MaterialModule,
    FormsModule,
    LoadingButtonSpinnerModule,
    RouterLink,
    MatRippleModule,
    DirectivesModule,
  ],
  exports: [StackComponent, ShareCardComponent],
})
export class StackModule {}
