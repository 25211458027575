import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ChannelPermissions, Stack} from "@mrbeany/stacks_shared";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import {ICard} from "@mrbeany/stacks_shared/lib/models/card";
import {Card} from "../../../../node_modules/@mrbeany/stacks_shared";
import ChannelDTO = DTOs.ChannelDTO;
import NetworkDTO = DTOs.NetworkDTO;
import {NetworkPermissions} from "@mrbeany/stacks_shared/lib/models/network";
const clone = require("clone");

export type isLoading = "isLoading";
@Injectable({
  providedIn: "root",
})
export class FeedService {
  private feed = new BehaviorSubject<LoadingState<Feed>>({
    state: {
      stacks: undefined,
      page: 0,
    },
    error: undefined,
    loading: false,
  });
  feed$ = this.feed.asObservable();
  constructor(
  ) {}

  getFeedSnapshot(): Feed | undefined {
    return this.feed.value.state;
  }

  setFeed(
    stacks: Array<Stack> | undefined = this.getFeedSnapshot()?.stacks,
    page: number | undefined = this.getFeedSnapshot()?.page,
    loading: boolean,
    error?: any,
  ) {
    this.feed.next({
      state: {
        stacks: stacks,
        page: page,
      },
      loading: loading,
      error: error,
    });
  }

  isLoading(load: boolean) {
    this.feed.next({
      ...this.feed.value,
      loading: load,
    });
  }
  getCurrentPage(): number | undefined {
    return this.getFeedSnapshot()?.page;
  }

  appendCards(cards: Array<Stack>, page?:number) {
    this.setFeed([...this.getFeedStacks(), ...cards], page ? page : undefined, false);
  }

  updateFeedContent(content: Stack) {
    const oldContent = this.getFeedStacks();
    const newContent = oldContent.map((_content) => {
      if(_content.id === content.id) {
        return content;
      } else {
        return _content;
      }
    });
    this.setFeed(
      newContent,
      this.getFeedSnapshot()?.page,
      false,
      undefined,
    )
  }

  getFeedStacks() {
    const stacks = this.getFeedSnapshot()?.stacks;
    if (!stacks) {
      return [];
    }
    return stacks;
  }

  spliceFeed(start: number, deleteNr: number, stack?: Stack) {
    console.log('splicing feed with', stack);
    let stacks = clone(this.getFeedSnapshot()?.stacks);
    stacks?.splice(start, deleteNr, stack);
    stacks = stacks.filter((_stack: Stack) => _stack !== undefined);
    this.setFeed(stacks, undefined, false);
  }

  deleteStackFromFeed(stack: Stack) {
    const index = this.getFeedSnapshot()
      ?.stacks?.map((_stack) => _stack.id)
      .indexOf(stack.id);
    if (index !== undefined && index !== -1) {
      this.spliceFeed(index, 1);
    }
  }
}

export interface LoadingState<T> {
  state: T | undefined;
  error: any | undefined;
  loading: boolean;
}
export interface Feed {
  stacks: Array<Stack> | undefined;
  page: number | undefined;
}

export function isFeed(obj: any): obj is Feed {
  if (!obj || typeof obj !== "object") {
    return false;
  }
  return "stacks" in obj && "page" in obj;
}
export function isCard(obj: any): obj is Card {
  if (!obj || typeof obj !== "object") {
    return false;
  }
  return "id" in obj && "header" in obj && "elements" in obj && "public" in obj;
}

export function isNetworkDTO(obj: any): obj is NetworkDTO {
  if(!obj) {
    return false
  }
  const isNetwork = ("channels" in obj);
  return isNetwork;
}

export function isChannelDTO(obj: any): obj is ChannelDTO {
  if(!obj) {
    return false
  }
  return "id" in obj && "config" in obj && "channelName" in obj;
}

export function isChannelPermissions(obj: any): obj is ChannelPermissions {
  if(!obj) {
    return false
  }
  return "configureChannel" in obj;
}

export function isNetworkPermissions(obj: any): obj is NetworkPermissions {
  if(!obj) {
    return false
  }
  return "configureNetwork" in obj;
}

export type Content = Card | Stack
