import {Injectable} from "@angular/core";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import {BehaviorSubject, catchError, Observable, of} from "rxjs";
import NetworkDTO = DTOs.NetworkDTO;
import {distinctUntilChanged, mergeMap, tap} from "rxjs/operators";
import {NetworkApiService} from "@app/services/network-api/network-api.service";
import ChannelDTO = DTOs.ChannelDTO;
import {FeedService} from "@app/services/feed/feed.service";
import {SnackbarService} from "@app/services/snackbar.service";
import JoinNetworkDTO = DTOs.JoinNetworkDTO;
@Injectable({
  providedIn: "root",
})
export class NetworkService {
  private networks = new BehaviorSubject<Array<NetworkDTO>>([]);
  private selectedNetwork = new BehaviorSubject<NetworkDTO | undefined>(undefined);

  networks$ = this.networks.asObservable();
  selectedNetwork$ = this.selectedNetwork.asObservable().pipe(
    distinctUntilChanged()
  );
  constructor(
    private snackbarsService: SnackbarService,
    private networkApi: NetworkApiService,
    private feedService: FeedService
  ) {}

  selectNetwork(network: NetworkDTO | undefined) {
    this.selectedNetwork.next(network);
    if (!network) {
      this.selectedNetwork.next(undefined);
      return;
    }
  }

  getSelectedNetworkSnapshot() {
    return this.selectedNetwork.value;
  }

  loadFeed(networkId: string, take: number) {
    this.feedService.isLoading(true);
    this.networkApi
      .getNetworkCards(networkId, 0,take)
      .pipe(
        tap((cards) => {
          this.feedService.setFeed(cards, 0, false);
        }),
        catchError((err) => {
          this.feedService.isLoading(false);
          this.snackbarsService.showSnackbar("Something went wrong");
          return of(err);
        })
      )
      .subscribe();
  }

  nextPage(take: number): Observable<any> {
    let currPage = this.feedService.getCurrentPage();
    if (typeof currPage !== "number") {
      currPage = 0;
    } else {
      currPage++;
    }
    return this.networkApi
      .getNetworkCards(this.getSelectedNetworkSnapshot()?.id as string, currPage, take)
      .pipe(
        tap((_stacks) => {
          this.feedService.appendCards(_stacks, currPage);
        })
      );
  }

  applyConfig(network: NetworkDTO) {
    if(network) {
      this.setNetworks(
        this.getNetworksSnapshot().map((_network) => {
          return _network.id === network.id
            ? network
            : _network;
        })
      );
      this.selectNetwork(network);
    }
  }



  joinNetwork(dto: JoinNetworkDTO) {
    return this.networkApi.joinNetwork(dto).pipe(
      mergeMap(() => this.refreshNetwork(dto.networkId)),
      tap(() => {
        this.selectNetworkById(dto.networkId);
      })
    );
  }

  /**
   * get network from api and update it's state
   * @param networkId
   */
  refreshNetwork(networkId: string): Observable<NetworkDTO> {
    return this.networkApi.getNetwork(networkId).pipe(
      tap((res) => {
        if (res) {
          this.updateNetwork(res);
        }
      })
    );
  }

  leaveNetwork(networkId: string) {
    return this.networkApi.leaveNetwork(networkId).pipe(
      tap(() => {
        this.removeNetworkById(networkId);
      })
    );
  }

  removeNetworkById(networkId: string) {
    this.setNetworks(this.getNetworksSnapshot().filter((network) => networkId !== network.id));
  }

  selectNetworkById(id: string | undefined) {
    if (!id) {
      this.selectNetwork(undefined);
      return;
    }
    const network = this.networks.value.find((_network) => _network.id === id);
    if (network) {
      this.selectNetwork(network);
    } else {
      this.refreshNetwork(id)
        .pipe(
          tap((_network) => {
            this.selectNetwork(_network);
          })
        )
        .subscribe();
    }
  }

  updateUserNetworks() {
    return this.networkApi.getUserNetworks().pipe(
      tap((networks) => {
        this.networks.next(networks);
      })
    );
  }

  getNetworkById(id: string): NetworkDTO | undefined {
    return this.getNetworksSnapshot().find((_network) => _network.id === id);
  }

  getNetworksSnapshot() {
    return this.networks.value;
  }
  setNetworks(networks: Array<NetworkDTO>) {
    this.networks.next(networks);
  }
  addNetwork(network: NetworkDTO) {
    this.setNetworks([network, ...this.getNetworksSnapshot()]);
  }

  /**
   * updates a channel if present in a network
   * @param channel
   */
  updateChannel(channel: ChannelDTO) {
    const newNetworks = this.getNetworksSnapshot().map((_network) => {
      const newChannels = _network.channels.map((_channel) => {
        return _channel.id === channel.id ? channel : _channel;
      });
      _network.channels = newChannels;
      return _network;
    });
    this.networks.next(newNetworks);
  }

  /** updates or inserts a network int the network list with the given network
   * @param network
   */
  updateNetwork(network: NetworkDTO) {
    const networkIds = this.getNetworksSnapshot().map((_network) => _network.id);
    if (networkIds.includes(network.id)) {
      const newNetworks = this.getNetworksSnapshot().map((_network) => {
        if (_network.id === network.id) {
          return network;
        } else {
          return _network;
        }
      });
      this.setNetworks(newNetworks);
    } else {
      this.setNetworks([...this.getNetworksSnapshot(), network]);
    }
  }

  addChannelsToNetwork(channels: ChannelDTO[], networkId: string) {
    const newNetwork = this.getNetworkById(networkId);
    newNetwork?.channels.push(...channels);
    if (newNetwork) {
      this.updateNetwork(newNetwork);
    }
  }

  removeChannelFromNetwork(channelId: string, networkId: string) {
    const newNetwork = this.getNetworkById(networkId);
    if (!newNetwork) {
      return;
    }
    if (newNetwork?.channels) {
      newNetwork.channels = newNetwork?.channels.filter((channel) => channel.id !== channelId);
    }
    this.updateNetwork(newNetwork);
  }
  removeChannelFromNetworks(channelId: string) {
    const networks = this.getNetworksSnapshot();
    const newNetworks = networks.map((network) => {
      const newNetwork = network;
      network.channels = network.channels.filter((channel) => channel.id === channel.id);
      return newNetwork;
    });
    this.setNetworks(newNetworks);
  }
}
