import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-button-spinner',
  template: `
    <div class="spinner-container next-page-spinner">
      <mat-spinner [diameter]="diameter" [strokeWidth]="strokeWidth"></mat-spinner>
    </div>
  `,
})
export class LoadingButtonSpinnerComponent implements OnInit {
  @Input() diameter = 28;
  @Input() strokeWidth = 2;
  constructor() {}

  ngOnInit(): void {}
}
