import {AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidators {
    static confirmPassword(passwordControl: UntypedFormControl): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            }
            return (passwordControl.value !== value) ? {confirmPassword: true} : null;
        };
    }
    static lengthRange(minLength: number, maxLength: number) {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value.toString();
            if (!value) {
                return null;
            }
            return !(value.length >= minLength && value.length <= maxLength) ?  {lengthRange: true} : null;
        };
    }
    static lowerCase() {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value.toString();
            if (!value) {
                return null;
            }
            const hasLowerCase = /[a-z]+/.test(value);
            return !hasLowerCase ? {lowerCase: true} : null;
        };
    }
    static upperCase() {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value.toString();
            if (!value) {
                return null;
            }
            const hasUpperCase = /[A-Z]+/.test(value);
            return !hasUpperCase ? {upperCase: true} : null;
        };
    }
    static hasNumber() {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value.toString();
            if (!value) {
                return null;
            }
            const hasNumeric = /[0-9]+/.test(value);
            return !hasNumeric ? {numeric: true} : null;
        };
    }
    static hasSpecialChar() {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value.toString();
            if (!value) {
                return null;
            }
            const hasNumeric = value.match("[$&+,:;=?@#|'<>.^*()%!-]");
            return !hasNumeric ? {specialChar: true} : null;
        };
    }
    static email() {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value.toString();
            if (!value) {
                return null;
            }
            // recommended email pattern compliant to RFC 5322 http://emailregex.com/
            const emailPattern = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])'
            return !value.match(emailPattern) ? {email: true} : null;
        }
    }
    static passwordStrengthValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }
            const value = control.value;

            const hasUpperCase = /[A-Z]+/.test(value);

            const hasLowerCase = /[a-z]+/.test(value);

            const hasNumeric = /[0-9]+/.test(value);

            const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;

            return !passwordValid ? {passwordStrength: true} : null;
        };
    }
}

