import {Component, OnDestroy, OnInit} from "@angular/core";
import {Stack} from "@mrbeany/stacks_shared";
import {ChannelsService} from "@app/services/channels/channels.service";
import {map, tap} from "rxjs/operators";
import {Observable, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {NetworkService} from "@app/services/network.service";
import {AuthenticationService} from "@app/authentication/authentication.service";
import {VIEW_MODE} from "@app/enum";
import {AppService} from "@app/services/navbar/app.service";
import {SupertokenService} from "@app/services/supertoken.service";
import {calculateTake} from "@app/main/home/feed/feed.component";
import {FeedContextService} from "@app/services/feed-context.service";
import {FeedService} from "@app/services/feed/feed.service";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ChannelDTO = DTOs.ChannelDTO;
import NetworkDTO = DTOs.NetworkDTO;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  VIEW_MODE = VIEW_MODE;
  isHandset$: Observable<boolean> = this.appService.isHandset$;
  isLoggedIn$ = this.authenticationService.isLoggedIn$;
  routeNav$;
  channels$ = this.channelService.channelList$;
  networks$ = this.networkService.networks$;

  constructor(
    private authenticationService: AuthenticationService,
    private channelService: ChannelsService,
    private networkService: NetworkService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
  ) {
    // handles channel navigation via routes
    this.routeNav$ = this.route.queryParams.pipe(
      tap((params) => {
        const channelId = params["channel"];
        const networkId = params["network"];
        if (!channelId && !networkId) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {channel: "my_cards"},
            queryParamsHandling: "merge", // remove to replace all query params by provided
          });
          this.channelService.selectChannel("my_cards");
        } else {
          this.networkService.selectNetworkById(networkId);
          this.channelService.selectChannel(channelId);
        }
      })
    );

  }



  ngOnInit() {
    this.channelService.updateUserChannels().subscribe();
    // we need to populate the channelservice with network channels as well
    // else they can't be selected
    this.networkService.updateUserNetworks().pipe(
      tap((networks: NetworkDTO[]) => {
        const networkChannels = networks.reduce((acc: ChannelDTO[], curr: NetworkDTO) => {
          let res = [ ...curr.channels, ...acc];
          return res;
        } , []);
        this.channelService.addChannelsToChannelList(networkChannels)
      })
    ).subscribe();
    this.authenticationService.updateCurrentUser();
  }

  ngOnDestroy() {
    this.channelService.selectChannel(undefined);
    this.networkService.selectNetwork(undefined);
  }
}
