import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit} from "@angular/core";
import {Card, Stack} from "@mrbeany/stacks_shared";
import {CardService} from "@app/services/card.service";
import {ActivatedRoute, ActivatedRouteSnapshot} from "@angular/router";
import {tap} from "rxjs/operators";
import {STACK_TYPE} from "@mrbeany/stacks_shared/lib/models/stack";
import {BehaviorSubject, Subject} from "rxjs";
import {VIEW_MODE} from "@app/enum";
import {link} from "node:fs";
import {environment} from "../../../../environments/environment";
import {AppService} from "@app/services/navbar/app.service";
import {ViewModeStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";
import {RoutePath} from "@app/route-path";
import {FeedContext, FeedContextService} from "@app/services/feed-context.service";
import {ChannelsService} from "@app/services/channels/channels.service";

@Component({
  selector: 'app-single-card-view',
  templateUrl: './single-card-view.component.html',
  styleUrls: ['./single-card-view.component.scss'],

})
export class SingleCardViewComponent implements OnInit, OnDestroy {

  stack = new Subject<undefined | Stack>();
  stack$ = this.stack.asObservable().pipe(
    tap((stack) => {
      //prevent scroll jumps when the height of a stack changes
      requestAnimationFrame(() => {
        document.documentElement.scrollTop = 0;
      })
    })
  )
  protected readonly VIEW_MODE = VIEW_MODE;
  protected readonly ViewModeStrategy = ViewModeStrategy;
  constructor(
    private cardService: CardService,
    private route: ActivatedRoute,
    private appService: AppService,
    private el: ElementRef
  ) {}
  isHandset$ = this.appService.isHandset$;
  route$ = this.route.url.pipe(tap((res) => {
    if (res[0].path === 'card') {
      const stackId = res[1].path;
      if (stackId) {
        this.cardService.getStacks([stackId], false).pipe(
          tap((stacks: Stack[]) => {
            const stack = Object.assign(new Stack(), stacks[0]);
            this.stack.next(stack);
          })
        ).subscribe();
      }
    } else {
      this.cardService.getContentByUrl(res[0].path)
        .pipe(
          tap((_stack: Stack) => {
            const stack = Object.assign(new Stack(), _stack);
            this.stack.next(stack);
          })
        )
        .subscribe()
    }
   })).subscribe()

  ngOnInit() {
  }

  ngOnDestroy() {
    this.route$.unsubscribe();
  }

  onScrollTop() {
    //prevent scroll jumps when the height of a stack changes
    requestAnimationFrame(() => {
      document.documentElement.scrollTop = 0;
    })
  }
}
