import {Component} from "@angular/core";

@Component({
  selector: 'app-placeholder-element',
  templateUrl: './placeholder-element.component.html',
  styleUrls: ['./placeholder-element.component.scss'],
})
export class PlaceholderElementComponent {

}
