import {Injectable} from "@angular/core";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {CardService} from "../../services/card.service";
import {map, tap} from "rxjs/operators";
import {Card, Stack} from "@mrbeany/stacks_shared";
import {STACK_TYPE} from "@mrbeany/stacks_shared/lib/models/stack";
import {CardHeader} from "@mrbeany/stacks_shared/lib/models/card-elements/cardheader";
import {AuthenticationService} from "@app/authentication/authentication.service";
import {UserRole} from "@app/authentication/user";
import {createUserStack} from "@app/helpers/helpers";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  appsyUrl = ''

  templateCards = new BehaviorSubject<Stack[] | 'loading'>('loading');
  templateCards$ = this.templateCards.asObservable().pipe(
    tap((res) => console.log(res)),
  );

  selectedTemplateId = new BehaviorSubject<TemplateId>('none');
  selectedTemplateId$ = this.selectedTemplateId.asObservable();

  selectedTemplate$ : Observable<Stack | undefined | 'loading' | 'error'> = combineLatest(this.templateCards$, this.selectedTemplateId$).pipe(
    map(([stacks, id]) => {
        if (stacks === 'loading' && id !== 'none') {
          return 'loading'
        }
        if (id === 'error') {
          return 'error'
        }
        if (id === 'none' || id === null || !id) {
          const template = createUserStack(this.authService.getUser());
          return template;
        }
        if(stacks !== 'loading' && id !== 'none') {
          const template = stacks.find((stack) => stack.id === id);
          if(!template) {
            return 'error';
          }
          template.cards.forEach((card) => {
            if(card.header) {
              card.header.username = this.authService.getUser().name;
              card.header.avatarIMG = this.authService.getUser().avatarImg;
            }
          })
          return template;
        }
        return undefined;
      }
    ),
    // edit stack to be compatible with the template workflow
    map((res) => {
      if(res instanceof Stack) {
        let _stack = res;
        _stack.cards[0].id = undefined;
        if(_stack.cards[0].header && this.authService.getUser().role === UserRole.Guest) {
          _stack.cards[0].header.avatarIMG = undefined;
          _stack.cards[0].header.username = '';
        }
        return _stack
      }
      return res;
    })
  )

  constructor(
    private cardService: CardService,
    private authService: AuthenticationService,
  ) {
    this.selectedTemplateId.next('')
    this.cardService.getStacks(WizardTemplates.map((template) => template.id))
      .pipe(
        tap((stacks: Stack[]) => {
          this.templateCards.next(stacks);
        })
      )
      .subscribe();
  }

  setAppsyUrl(url?: string) {
    if(url) {
      this.appsyUrl = url;
    }
  }
}
export const WizardTemplates = [
  {
    name: 'Link-In-Bio',
    id: '01JAA37V9G1Z2T13FPVVFF4AND',
    img: 'assets/images/link-in-bio.png'
  },
  {
    name: 'Appsy Template',
    id: '01JBSFDBJVMCS6VE6C9EAEPH9A',
    img: 'assets/images/link-in-bio.png'
  }
]

type TemplateId = string | 'loading' | 'error' | 'none' | null
