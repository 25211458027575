import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {VIEW_MODE} from "@app/enum";
import {ViewModeStrategy, ViewStrategy} from "@app/card.components/element-list/element-list/viewModeStrategy";
import {tap} from "rxjs/operators";
import {Stack} from "@mrbeany/stacks_shared";

@Injectable()
export class StackService {

  private viewStrategy = new BehaviorSubject(ViewModeStrategy.Display)
  viewStrategy$ = this.viewStrategy.asObservable().pipe(
  );

  stack?: Stack;

  private menuOpen  = new BehaviorSubject<boolean>(false);
  public menuOpen$ = this.menuOpen.asObservable();

  constructor(
  ) {}

  init(viewStrategy?: ViewStrategy) {
    if (viewStrategy) {
      this.viewStrategy.next(viewStrategy);
    }
  }
  setStack(stack: Stack) {
    this.stack = stack;
  }
  setViewStrategy(strategy: ViewStrategy) {
    this.viewStrategy.next(strategy)
  }
  viewStrategySnapshot() {
    return this.viewStrategy.getValue();
  }
  openMenu() {
    this.menuOpen.next(true);
  }
  closeMenu() {
    this.menuOpen.next(false);
  }
}
