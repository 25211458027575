import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  message!: string;
  title!: string;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.message = this.data.message;
    this.title = this.data.title;
  }

  onConfirm() {
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
export interface DialogData {
  message: string;
  title: string;
}
