<div class="card" *ngIf="channel && channel?.channelRole?.permissions?.configureChannel">
  <div>
    <div class="cardheader-wrapper">
      <app-cardheader-title> Channel Settings </app-cardheader-title>
    </div>
  </div>
  <div class="card-elements">
    <form *ngIf="form" [formGroup]="form">
      <mat-expansion-panel>
        <mat-expansion-panel-header>General</mat-expansion-panel-header>
        <mat-checkbox formControlName="public" color="primary">Public</mat-checkbox>
        <mat-form-field appearance="outline">
          <input matInput formControlName="name" />
          <mat-label>Channel Name</mat-label>
          <mat-error *ngIf="form.hasError('maxLength')">Max 24 characters</mat-error>
          <mat-error *ngIf="form.hasError('required')">Max 24 characters</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Layout</mat-label>
          <mat-select
            formControlName="layout"
          >
            <mat-option value="feed" (click)="updateLayout()">
              Feed
            </mat-option>
<!--            <mat-option value="grid" (click)="updateLayout()">
              Grid
            </mat-option>-->
            <mat-option value="masonry" (click)="updateLayout()">
              Masonry
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>Templates</mat-expansion-panel-header>
        <div class="section-wrapper" *ngIf="channel?.config?.template">

          <section>
            <!--defaulte template-->
            <h4>Active Template</h4>
            <mat-form-field formGroupName="template" appearance="outline">
              <mat-label>Default Template</mat-label>
              <mat-hint
              >Starting Template for this channel.</mat-hint
              >
              <mat-select
                formControlName="defaultTemplate"
                [compareWith]="compareObjects"
                placeholder="Default Template"
              >
                <mat-option
                  [value]="{
                cardId: undefined,
                name: 'none'
              }"
                >- none -</mat-option
                >
                <mat-option
                  [value]="template"
                  *ngFor="let template of this.form?.get('template.templates')?.value"
                >
                  {{ template.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </section>
          <section>
            <!--templates-->
            <h4>Channel Templates</h4>
            <div class="template-list">
              <mat-selection-list
                #selectionList
                color="primary"
                (click)="selectionListChange(selectionList)"
              >
                <p *ngIf="this.form?.get('template.templates')?.value?.length < 1">
                  <span>Add a Template by linking a Card to here</span>
                  <button mat-flat-button color="primary" (click)="pasteCardID()">
                    <mat-icon>content_paste</mat-icon>
                    Paste
                  </button>
                </p>
                <mat-list-option
                  *ngFor="let template of this.form?.get('template.templates')?.value"
                  [value]="template"
                >
                  {{ template.name }}
                </mat-list-option>
              </mat-selection-list>
              <div
                class="buttons-wrapper"
                *ngIf="this.form?.get('template.templates')?.value?.length > 0"
              >
                <button
                  mat-button
                  *ngIf="selectedTemplates.length > 0"
                  (click)="removeTemplates(selectedTemplates)"
                  (click)="selectionListChange(selectionList)"
                >
                  <mat-icon>delete</mat-icon>
                  Delete
                </button>
                <button mat-flat-button color="primary" (click)="pasteCardID()">
                  <mat-icon class="rotate45">route</mat-icon>
                  Add Template
                </button>
              </div>
            </div>
          </section>


        </div>

      </mat-expansion-panel>
    </form>
    <button class="buttons-wrapper" mat-button color="warn" (click)="deleteChannel(channel.id)">
      <span *ngIf="!deletingChannel"> Delete Channel </span>
      <app-loading-button-spinner *ngIf="deletingChannel"></app-loading-button-spinner>
    </button>
  </div>
</div>

<div *ngIf="form.dirty" class="creater-buttons-wrapper">
  <button [disabled]="!form.dirty || form.invalid || configLoading" mat-button (click)="onCancel()">
    <mat-icon>close</mat-icon>
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="!form.dirty || form.invalid || configLoading"
    (click)="submitForm(form)"
  >
    <mat-icon *ngIf="!configLoading">save</mat-icon>
    <mat-icon *ngIf="configLoading">
      <mat-spinner [diameter]="24" [strokeWidth]="3"></mat-spinner>
    </mat-icon>
    <span> Save Settings </span>
  </button>
</div>
