import {BehaviorSubject, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Card, Stack} from '@mrbeany/stacks_shared';
import {HttpClient} from '@angular/common/http';
import {CardService} from '@app/services/card.service';
import {tap} from 'rxjs/operators';
import {ICard} from '@mrbeany/stacks_shared/lib/models/card';

@Injectable({
  providedIn: 'root',
})
export class StackCacheService {
  private cache: Array<Stack> = [];
  constructor() {}

  add(stacks: Array<Stack>) {
    this.remove(stacks.map((stack) => stack.id as string));
    this.cache.push(...stacks);
  }
  //
  /** checks if cards have already been loaded previously and replaces the id element
   * in the given array with a card or leaves it as an id if the card has not been
   * cached
   * @param card ids
   */
  checkCache(ids: string[]): Array<Stack | string> {
/*    const matchingCards = ids.map((id) => {
      const matchingCard = this.cardCache.find((card) => card.id === id);
      return matchingCard ? matchingCard : id;
    });
    return matchingCards;*/
    return ids;
  }

  remove(ids: string[]) {
    this.cache = this.cache.filter((card) => !ids.includes(card.id as string));
  }
}
