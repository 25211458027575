

<div class="buttons" *ngIf="editing">
  <app-button-container-button
    *ngFor="let button of buttonContainer.buttonList; let i = index"
    [button]="button"
    [index]="i"
    [edit]="button.edit"
    (deleteButton)="deleteButton($event)"
  ></app-button-container-button>
  <button  class="placeholder-button shadow" mat-flat-button (click)="linktToNewCard()">
    <mat-icon>add</mat-icon>
    New Appsy
  </button>
  <button *ngIf="(viewModeStrat$ | async) === ViewModeStrategy.Editor" mat-flat-button id="pasteButton" (click)="pasteCardID()">
    <mat-icon class="rotate45">route</mat-icon>
    Connect to Appsy
  </button>
</div>
<div class="buttons" *ngIf="!editing && buttonContainer.buttonList.length > 0">
  <button
    mat-flat-button
    *ngFor="let button of buttonContainer.buttonList; let i = index"
    [class.grow-self]="buttonContainer.buttonList.length > 2"
    [color]="!button.CardID && ((user$ | async)?.name === owner) ? 'accent' : 'primary'"
    [disabled]="!button.CardID && !((user$ | async)?.name === owner) || editing"
    (click)="navigateToCard(button)"
    class="shadow"
  >
    <span> {{ button.description ? button.description : "" }}</span>
    <mat-icon
      *ngIf="!button.CardID"
      [svgIcon]="'route_closed'"
      class="rotate45">
    </mat-icon>
    <mat-icon
      *ngIf="button.CardID"
      class="rotate45">
      route
    </mat-icon>
  </button>
</div>
