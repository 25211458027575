import {Injectable} from "@angular/core";
import axios, {AxiosPromise} from "axios";
import {
  BehaviorSubject,
  catchError, combineLatest,
  Observable,
  of,
  shareReplay,
  skip,
  takeUntil,
  takeWhile,
  throwError
} from "rxjs";
import {distinctUntilChanged, map, mergeMap, tap} from "rxjs/operators";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import {environment} from "../../../environments/environment";
import {Card, Stack} from "@mrbeany/stacks_shared";
import {SnackbarService} from "@app/services/snackbar.service";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {STACK_TYPE} from "@mrbeany/stacks_shared/lib/models/stack";
import ChannelDTO = DTOs.ChannelDTO;
import ChannelConfigDTO = DTOs.ChannelConfigDTO;
import JoinChannelDTO = DTOs.JoinChannelDTO;
import GetChannelInviteDTO = DTOs.GetChannelInviteDTO;
import {CardService} from "@app/services/card.service";
import {FeedService} from "@app/services/feed/feed.service";
import {ChannelApiService} from "@app/services/channel-api/channel-api.service";
import {NetworkService} from "@app/services/network.service";
import {calculateTake} from "@app/main/home/feed/feed.component";
import {Channel} from "@app/main/home/navigation/channel";

const clone = require("clone");

@Injectable({
  providedIn: "root",
})
export class ChannelsService {
  private selectedChannelId = new BehaviorSubject<string | undefined>(undefined);
  private channelList = new BehaviorSubject<ChannelDTO[]>([]);

  selectedChannelId$ = this.selectedChannelId.asObservable();
  channelList$ = this.channelList.asObservable();
  selectedChannel$ : Observable<ChannelDTO> = combineLatest([this.selectedChannelId$, this.channelList$]).pipe(
  distinctUntilChanged(),
    map(([channelId, channelList]) => {
      console.log(
        'channelId', channelId, 'channelList', channelList
      )
      const selectedChannelId = channelId;
      return channelList.find((channel) => channel.id === selectedChannelId);
    }),
    //@ts-ignore
    tap((channel: ChannelDTO | undefined) => {
      //pre-load channel templates
      if (channel?.config?.template?.templates) {
        this.cardService
          .getStacks(
            channel.config.template.templates.map((template) => template.cardId as string),
            true
          )
          .subscribe();
      }
      if(channel?.config) {
        this.loadFeed(channel.id, calculateTake(channel.config.layout));
      }
    }),
    shareReplay()
  )
  constructor(
    private snackbarsService: SnackbarService,
    private http: HttpClient,
    private cardService: CardService,
    private feedService: FeedService,
    private channelApi: ChannelApiService,
    private networkService: NetworkService
  ) {}

  /**
   * sets the currently selected Channel
   * @param channel
   */
  getChannelById(id: string) {
    return this.channelList.getValue().find((channel) => channel.id === id)
  }
  selectChannel(id?: string) {
    if(!id) {
      this.selectedChannelId.next(undefined);
    }
    this.selectedChannelId.next(id);
  }

  public loadFeed(channelId: string, take: number) {
    this.feedService.isLoading(true);
    this.channelApi
      .getChannelCards(channelId, 0, take)
      .pipe(
        tap((cards) => {
          this.feedService.setFeed(cards, 0, false);
        }),
        catchError((err: HttpErrorResponse) => {
          this.feedService.isLoading(false);
          if (err.status === 404) {
            this.snackbarsService.showSnackbar("Could not find channel");
          }
          if (err.status === 403) {
            this.snackbarsService.showSnackbar(
              "Forbidden. This channel is private and you are not a member"
            );
            return of(err);
          }
          this.snackbarsService.showSnackbar("Something went wrong, please try reloading the page");
          return of(err);
        })
      )
      .subscribe();
  }

  leaveChannel(id: string) {
    return this.channelApi.leaveChannel(id).pipe(
      tap((res) => {
        this.removeChannelById(id);
      })
    );
  }

  public setChannels(channels: ChannelDTO[]) {
    this.channelList.next(channels);
  }

  /**
   * add channels to the channelList. If channel is already present, it gets updated.
   * @param channels
   */
  public addChannelsToChannelList(channels: ChannelDTO[]) {
    const newChannelList = [
      ...this.channelList.getValue().filter((channel) => !channels.includes(channel)), //filter out channels to update
      ...channels
    ]
    this.channelList.next(newChannelList);
  }

  public getChannelsSnapshot(): ChannelDTO[] {
    return this.channelList.value;
  }

  public updateUserChannels() {
    return this.channelApi.getUserChannels().pipe(
      tap((channelList) => {
        this.addChannelsToChannelList(channelList);
      })
    );
  }

  applyConfig(channel?: ChannelDTO) {
    if(channel) {
      this.addChannelsToChannelList([channel]);
      this.selectChannel(channel.id);
    }

  }


  selectChannelByIndex(index: number) {
    this.selectChannel(this.channelList.getValue()[index].id);
  }

  nextPage(take: number): Observable<any> {
    let currPage = this.feedService.getCurrentPage();
    if (typeof currPage !== "number") {
      currPage = 0;
    } else {
      currPage++;
    }
    return this.channelApi
      .getChannelCards(this.getSelectedChannelSnapshot()?.id as string, currPage, take)
      .pipe(
        tap((_stacks) => {
          this.feedService.appendCards(_stacks, currPage);
        })
      );
  }


  addChannel(channel: ChannelDTO) {
    this.addChannelsToChannelList([channel]);
  }

  /**
   * create a new channel.
   * Add a network-id to automatically add the channel to the network
   * @param name
   * @param networkId
   */
  createChannel(name: string, networkId?: string): Observable<ChannelDTO> {
    return this.http.post<ChannelDTO>(environment.baseUrlServer + "/channels", {
      name: name,
      networkId: networkId,
    });
  }


  removeChannelById(id: string) {
    const newChannelList = this.channelList.getValue().filter((channel) => id !== channel.id);
    this.channelList.next(newChannelList);
  }

  inviteToChannel(channelId: string) {
    return this.http.get<GetChannelInviteDTO>(environment.baseUrlServer + "/channel/membership", {
      params: {channelId: channelId},
    });
  }

  joinChannel(dto: JoinChannelDTO) {
    return this.channelApi.joinChannel(dto).pipe(
      mergeMap(() =>
        this.refreshChannel(dto.channelId).pipe(
          tap((_channel) => {
            this.selectedChannelId.next(_channel.id)
          })
        )
      )
    );
  }

  refreshChannel(id: string): Observable<ChannelDTO> {
    return this.channelApi.getChannel(id).pipe(
      tap((res) => {
        if (res) {
          this.addChannel(res);
        }
      })
    );
  }

  deleteChannel(id: string): AxiosPromise {
    return axios
      .delete(environment.baseUrlServer + "/channels", {
        params: {id: id},
      })
      .then((res) => {
        if (res.status === 200) {
          this.updateUserChannels().subscribe();
          const newChannels = this.getChannelsSnapshot().filter((channel) => channel.id !== id);
          this.setChannels(newChannels);
          this.selectChannelByIndex(0);
          this.snackbarsService.showSnackbar("Successfully deleted channel");
        }
        return res;
      });
  }

  getSelectedChannelSnapshot(): ChannelDTO | undefined {
    const channelId = this.selectedChannelId.getValue()
    if (!channelId) {
      return undefined
    }
    return this.getChannelById(channelId);
  }



  getChannel(index: number) {
    return this?.channelList.value[index] ? this.channelList.value[index] : undefined;
  }

}
