import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../authentication/authentication.service';
import {tap} from 'rxjs/operators';
import {catchError, finalize, throwError} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-set-username',
  templateUrl: './set-username.component.html',
  styleUrls: ['./set-username.component.scss'],
})
export class SetUsernameComponent implements OnInit {
  userNameForm: FormGroup;
  savingUsername = false;
  usernameTaken = false;
  constructor(private authenticationService: AuthenticationService) {
    this.userNameForm = new FormGroup<any>({
      name: new FormControl<string | undefined>(this.authenticationService.getUser()?.name, [
        Validators.maxLength(24),
        Validators.required,
      ]),
    });
  }

  ngOnInit(): void {}

  onSaveUsername(username: string) {
    this.savingUsername = true;
    this.authenticationService
      .setUserName(username)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error === 'SEC-001') {
            this.usernameTaken = true;
          }
          return throwError(() => err);
        }),
        tap(() => {
          this.authenticationService.setDTOUser({
            ...this.authenticationService.getCurrentUser(),
            username: username,
          });
        }),
        finalize(() => {
          this.savingUsername = false;
        })
      )
      .subscribe();
  }
}
