import {PlaceholderElementComponent} from "./placeholder-element.component";
import {NgModule} from "@angular/core";

@NgModule({
  declarations: [
    PlaceholderElementComponent
  ],
  imports: [
  ],
  exports: [
    PlaceholderElementComponent
  ]
})
export class PlaceholderElementModule{ }
