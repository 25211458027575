
<div class="wrapper" *ngIf="compSocials && compSocials.length > 1">
  <div *ngIf="editing" class="social-inputs">
    <h4>Social Links</h4>
    <span *ngFor="let compSocial of compSocials" class="social-item">
      <span class="social-slug">{{compSocial.slug}}</span>
      <input class="social-username" placeholder="username" (ngModelChange)="updateSocials()" [(ngModel)]="compSocial.username" type="text">
    </span>
  </div>


  <div class="icons" *ngIf="!editing">
    <ng-container *ngFor="let compSocial of compSocials;">
      <a *ngIf="compSocial.username"
         [href]="compSocial.src + compSocial.username"
         mat-ripple
         [class.facebook-icon]="compSocial.key === 'facebook'"
         [class.instagram-icon]="compSocial.key === 'instagram'"
         [class.x-icon]="compSocial.key === 'x'"
         [class.linkedin-icon]="compSocial.key === 'linkedin'"
         [class.youtube-icon]="compSocial.key === 'youtube'"
         [class.tiktok-icon]="compSocial.key === 'tiktok'"
         [class.github-icon]="compSocial.key === 'github'"
         [class.pinterest-icon]="compSocial.key === 'pinterest'"

      >
        <img [src]="compSocial.icon">
      </a>
    </ng-container>
  </div>

</div>

