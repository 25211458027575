<div class="messages">
  <div *ngFor="let message of chat.messages" class="message">
    <img [ngSrc]="message.avatar" height="40" width="40" alt="user avatar">
    <div class="bubble">
      <div class="username">{{message.usernname}}</div>
      <p class="message">{{message.message}}</p>
    </div>
  </div>
</div>
<div class="input-container">
  <input placeholder="Send a message" type="text">
  <button color="primary" mat-icon-button>
    <mat-icon>send</mat-icon>
  </button>
</div>
