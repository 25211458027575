<ng-container *ngIf="editing">
  <form [formGroup]="contactForm">
    <div class="form-elements">
      <div formGroupName="location">
        <h3 class="name-field">
          <input formControlName="name" type="text" placeholder="Name">
        </h3>
        <!--        Location-->
        <div class="form-element" [class.form-element--align-center]="!showLocationForm">
          <ng-container *ngIf="!showLocationForm">
            <button mat-mini-fab (click)="addLocationForm()" class="add-button">
              <mat-icon>add</mat-icon>
            </button>
            <h4>Address</h4>
          </ng-container>
          <ng-container>
            <button *ngIf="showLocationForm" mat-mini-fab (click)="deleteLocationForm()" class="remove-button">
              <mat-icon>remove</mat-icon>
            </button>
            <div class="location-fields" *ngIf="showLocationForm">
              <input formControlName="address1" type="text" placeholder="Address Line 1">
              <input formControlName="address2" type="text" placeholder="Address Line 2">
              <input formControlName="province" type="text" placeholder="Province">
              <input formControlName="city" type="text" placeholder="City">
              <input formControlName="state" type="text" placeholder="State">
              <input formControlName="postalCode" type="text" placeholder="Postal Code">
              <input formControlName="country" type="text" placeholder="Country">
            </div>
          </ng-container>
        </div>
      </div>
      <!--      Phone-->
      <div class="form-element">
        <ng-container *ngIf="!showPhone">
          <button mat-mini-fab (click)="addPhone()" class="add-button">
            <mat-icon>add</mat-icon>
          </button>
          <h4 class="pointer" (click)="addPhone()">Phone</h4>
        </ng-container>
        <ng-container *ngIf="showPhone">
          <button mat-mini-fab (click)="deletePhone()" class="remove-button">
            <mat-icon>remove</mat-icon>
          </button>
          <input formControlName="phone" type="text" placeholder="00123 345 678">
        </ng-container>
      </div>
      <!--      Email-->
      <div class="form-element">
        <ng-container *ngIf="!showEmail">
          <button mat-mini-fab (click)="addEmail()" class="add-button">
            <mat-icon>add</mat-icon>
          </button>
          <h4 class="pointer" (click)="addEmail()">Email</h4>
        </ng-container>
        <ng-container *ngIf="showEmail">
          <button mat-mini-fab (click)="deleteEmail()" class="remove-button">
            <mat-icon>remove</mat-icon>
          </button>
          <input formControlName="email" type="text" placeholder="example@email.com">
        </ng-container>
      </div>
    </div>

  </form>
</ng-container>
<ng-container *ngIf="!editing">
  <div class="form-elements form-elements--editing">
      <h3 *ngIf="contact.location?.name" class="name-field">
        {{contact.location?.name}}
      </h3>
      <!--        Location-->
      <a target="_blank" [href]="
      'https://maps.google.com/maps/dir/?api=1&destination='
      + (contact.location?.address1 || '') + ','
      + (contact.location?.address2 || '') + ','
      + (contact.location?.province || '') + ','
      + (contact.location?.city || '') + ','
      + (contact.location?.state || '') + ','
      + (contact.location?.postalCode || '') + ','
      + (contact.location?.country || '')"
       class="form-element">
          <button mat-mini-fab (click)="addLocationForm()">
            <mat-icon>map</mat-icon>
          </button>
          <div class="location-fields">
            <h5 *ngIf="contact.location?.address1">{{contact.location?.address1}}</h5>
            <h5 *ngIf="contact.location?.address2">{{contact.location?.address2}}</h5>
            <h5 *ngIf="contact.location?.province">{{contact.location?.province}}</h5>
            <h5 *ngIf="contact.location?.city">{{contact.location?.city}}</h5>
            <h5 *ngIf="contact.location?.state">{{contact.location?.state}}</h5>
            <h5 *ngIf="contact.location?.postalCode">{{contact.location?.postalCode}}</h5>
            <h5 *ngIf="contact.location?.country">{{contact.location?.country}}</h5>
          </div>
      </a>
    <!--      Phone-->
    <a  class="form-element"  *ngIf="contact?.phone" [href]="'tel:'+contact.phone">
      <button mat-mini-fab>
        <mat-icon>phone</mat-icon>
      </button>
      <h5>{{contact?.phone}}</h5>
    </a>
    <!--      Email-->
    <a  class="form-element"  *ngIf="contact?.email" [href]="'mailto:'+contact.email">
      <button mat-mini-fab>
        <mat-icon>email</mat-icon>
      </button>
      <h5>{{contact?.email}}</h5>
    </a>
  </div>
</ng-container>

