import {Component, EventEmitter, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Validators} from "../../../../../node_modules/@angular/forms";
import {NetworkService} from "@app/services/network.service";
import {SnackbarService} from "@app/services/snackbar.service";
import {catchError, finalize, of} from "rxjs";
import {tap} from "rxjs/operators";
import {HttpErrorResponse} from "../../../../../node_modules/@angular/common/http";
import {NetworkApiService} from "@app/services/network-api/network-api.service";

@Component({
  selector: "app-create-network-form",
  templateUrl: "./create-network-form.component.html",
  styleUrls: ["./create-network-form.component.scss"],
})
export class CreateNetworkFormComponent {
  @Output() success = new EventEmitter<void>();

  saving = false;
  form: FormGroup;
  usernameTaken = false;
  constructor(
    private networkApi: NetworkApiService,
    private networkService: NetworkService,
    private snackbarService: SnackbarService
  ) {
    this.form = new FormBuilder().group({
      name: ["", [Validators.maxLength(42), Validators.required]],
    });
  }

  submit(rawValue: {name: string}) {
    this.saving = true;
    this.networkApi
      .createNetwork(rawValue.name, undefined)
      .pipe(
        tap((res) => {
          this.success.emit();
          this.networkService.addNetwork(res);
          this.snackbarService.showSnackbar("Successfully created " + rawValue.name);
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.error === "SEC-001") {
            this.usernameTaken = true;
          }
          this.snackbarService.showSnackbar("An unknown error occured while creating the Network");
          return of(err);
        }),
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe();
  }
}
