import {Injectable, TemplateRef} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  openDialog(
    template: ComponentType<any> | TemplateRef<any>,
    data?: any,
    callback?: (prop: any) => void,
    config?: MatDialogConfig,
    inputs?: {name: string, value: any}[]
  ) {
    this.dialog.closeAll();
    const defaultConfig: MatDialogConfig = {
      width: '450px',
      maxWidth: '90vw',
      closeOnNavigation: true,
      disableClose: false,
      enterAnimationDuration: '500',
      exitAnimationDuration: '500',
      hasBackdrop: true,
      autoFocus: false,
      restoreFocus: false,
      role: 'dialog',
      data: data,
    };
    const dialogRef = this.dialog.open(
      template,
      config ? {...defaultConfig, ...config} : defaultConfig
    );
    if(inputs) {
      inputs.forEach((input) => {
        dialogRef.componentInstance[input.name] = input.value;
      })
    }
    dialogRef.afterClosed().subscribe((res) => {
      if (res && callback) {
        callback(res);
      }
    });
    return dialogRef;
  }
}
