import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GalleryComponent} from "./gallery.component";
import {GalleryEditorComponent} from "./list-editor/gallery-editor.component";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {GallerizeDirective} from "ng-gallery/lightbox";
import {MatMenuModule} from "@angular/material/menu";
import {HandleImageSrcPipe} from "../../../pipes/handleGalleryImgSrc.pipe";
import {PipesModule} from "../../../pipes/pipes.module";
import {MaterialModule} from "@app/material.dependencies";



@NgModule({
  declarations: [
    GalleryComponent,
    GalleryEditorComponent,
    HandleImageSrcPipe
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    GallerizeDirective,
    MatMenuModule,
    MaterialModule,
    PipesModule
  ],
  exports: [
    GalleryComponent,
    HandleImageSrcPipe
  ]
})
export class GalleryModule { }
