import {Injectable, Renderer2, RendererFactory2} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EditorService {
  private renderer: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  /**
   *
   * @param node table cell node
   */
  addColumn(node: any, numberOfColumns: number, columnIndex: number) {
    const table = node.parentElement.parentElement.parentElement;
    const header = table.children[0];
    const body = table.children[1];
    const bodyRows = body.children.length;

    if (numberOfColumns === columnIndex + 1) {
      this.renderer.appendChild(header.children[0], document.createElement("TD")); //insert column in header
      for (let i = 0; i < bodyRows; i++) {
        this.renderer.appendChild(body.children[i], document.createElement("TD")); //insert column in body
      }
    } else {
      //insert before target cell if it is not the last child
      this.renderer.insertBefore(
        header.children[0],
        document.createElement("TD"),
        header.children[0].children[columnIndex + 1]
      );
      for (let i = 0; i < bodyRows; i++) {
        this.renderer.insertBefore(
          body.children[i],
          document.createElement("TD"),
          body.children[i].children[columnIndex + 1]
        );
      }
    }
  }
  /**
   *
   * @param node table cell node
   */
  removeColumn(node: any, columnIndex: number) {
    const table = node.parentElement.parentElement.parentElement;
    const header = table.children[0];
    const body = table.children[1];
    const bodyRows = body.children.length;
    this.renderer.removeChild(header.children[0], header.children[0].children[columnIndex]); //remove column in header
    for (let i = 0; i < bodyRows; i++) {
      this.renderer.removeChild(body.children[i], body.children[i].children[columnIndex]); //remove column in body
    }
  }

  /**
   *
   * @param node table cell node
   */
  deleteTable(node: any) {
    const container = node.parentElement.parentElement.parentElement.parentElement;
    const table = node.parentElement.parentElement.parentElement;
    this.renderer.removeChild(container, table);
  }
  addRow(node: any, numberOfColumns: number, numberOfRows: number, rowIndex: number) {
    const row = document.createElement("TR");
    const tableBody = node.parentElement.parentElement.parentElement.children[1];
    row.innerHTML = this.generateCols(numberOfColumns - 1);
    //case: last row selected or no row in body yet
    if (numberOfRows === rowIndex + 1 || !tableBody.children[0]) {
      this.renderer.appendChild(tableBody, row);
    }
    //case: header selected
    if (rowIndex === 0) {
      this.renderer.insertBefore(tableBody, row, tableBody.children[0]);
    }
    //case: default
    else {
      this.renderer.insertBefore(tableBody, row, tableBody.children[rowIndex]);
    }
  }
  removeRow(node: any) {
    const tableBody = node.parentElement.parentElement;
    const refRow = node.parentElement;
    this.renderer.removeChild(tableBody, refRow);
  }

  generateCols(cols: number): string {
    let col = "";
    for (let i = 0; i <= cols; i++) {
      col = col.concat(`<td><br></td>`);
    }
    return col;
  }
  generateRows(rows: number, cols: string) {
    const headerRow = `<tr>${cols}</tr>`;
    let bodyRows = "";
    for (let i = 0; i <= rows; i++) {
      bodyRows = bodyRows.concat(`<tr>${cols}</tr>`);
    }
    return `<thead>${headerRow}</thead><tbody>${bodyRows}</tbody>`;
  }

  generateTable(rows: number, cols: number) {
    return `<table>${this.generateRows(rows, this.generateCols(cols))}</table>`;
  }
}
