<form [formGroup]="form" class="element">
  <mat-form-field color="accent">
    <input formControlName="email" matInput placeholder="Email" />
    <mat-error *ngIf="form.get('email')?.errors?.['required']">Required</mat-error>
    <mat-error *ngIf="form.get('email')?.errors?.['email']">Please enter a valid email address</mat-error>
  </mat-form-field>

  <mat-form-field>
    <input formControlName="password" [type]="showPW.checked ? 'text' :  'password'" matInput placeholder="Password"/>
    <mat-error *ngIf="form.get('email')?.errors?.['required']">Required</mat-error>
  </mat-form-field>
  <mat-checkbox #showPW labelPosition="after" color="primary" class="show-password">show password</mat-checkbox>
  <mat-checkbox formControlName="acceptTerms" labelPosition="after" color="primary" class="show-password">I agree to the
    <button class="button underlined-button" (click)="showLegal.emit('terms')">Terms of Use</button>
    and the
    <button class="button underlined-button" (click)="showLegal.emit('privacy')">Privacy Policy</button>
  </mat-checkbox>


  <div class="hints">
    <ul>
      <li [class.correct-color]="!form.get('password')?.errors?.['minlength'] && form.get('password')?.dirty" >
        At least 8 characters
      </li>
      <li [class.correct-color]="!form.get('password')?.errors?.['numeric'] && form.get('password')?.dirty">
        1 number
      </li>
      <li [class.correct-color]="!form.get('password')?.errors?.['lowerCase'] && form.get('password')?.dirty">
        1 lowercase letter
      </li>
      <li style="color: darkred" *ngIf="form.get('acceptTerms')?.invalid && form.dirty">
        Agree to the terms of use
      </li>
    </ul>
    <p class="error-message" *ngIf="showErrorMssg">
      Hmm something doesn't seem to be quite right. Please check the form again.
    </p>
  </div>
  <div class='flex-buttons'>
      <button [disabled]="isLoading | async" (submit)="onSignup(form.getRawValue())" mat-flat-button color="primary" (click)='onSignup(form.getRawValue())'>
        <span *ngIf="!(isLoading | async)">
          Register
        </span>
        <app-loading-button-spinner
          *ngIf="isLoading | async"
        ></app-loading-button-spinner>
      </button>
      <div class="navigate">
        Already have an account?
        &nbsp;
        <a role="button" (click)="navigateToSignIn.emit()"> Login</a>
      </div>
  </div>
</form>

