import {Component, Input} from "@angular/core";
import { Social } from "@mrbeany/stacks_shared/lib/models/card-elements/socials";
import {VIEW_MODE} from "../../../enum";
import {BehaviorSubject} from "rxjs";


@Component({
  selector: 'app-socials',
  templateUrl: './socials.component.html',
  styleUrls: ['./socials.component.scss']
})
export class SocialsComponent {

  compSocials = defaultCompSocials;
  _social!: Social;
  @Input() set social(_social: Social) {
    this._social = _social;
    if (!_social || _social.socials.length < 1) {
      return;
    }
    this.compSocials = defaultCompSocials.map((compSocial) => {
      //@ts-ignore
      const userName = _social.socials.find((social) => social.key === compSocial.key)?.username;
      return {
        ...compSocial,
        username:  userName || undefined
      }
    })
  };
  editing = false;
  get social() {
    // @ts-ignore
    return this._social;
  }

  updateSocials() {
    this._social.socials = this.compSocials.reduce((acc, curr) => {
      if (curr.username) {
        const newSocial = {
          key: curr.key,
          username: curr.username
        }
        return [ ...acc, newSocial]
      } else {
        return acc;
      }
    }, new Array())
  }
}
const defaultCompSocials: CompSocial[] = [
  {
    key: 'facebook',
    slug: 'facebook.com/',
    username: undefined,
    src: 'https://www.facebook.com/',
    label: 'Facebook',
    icon: 'assets/icons/social/facebook.svg',
  },
  {
    key: 'instagram',
    slug: 'instagram.com/',
    username: undefined,
    src: 'https://www.instagram.com/',
    label: 'Instagram',
    icon: 'assets/icons/social/instagram.svg',
  },
  {
    key: 'x',
    slug: 'x.com/',
    username: undefined,
    src: 'https://www.x.com/',
    label: 'X',
    icon: 'assets/icons/social/x.svg',
  },
  {
    key: 'linkedin',
    slug: 'linkedin.com/in/',
    username: undefined,
    src: 'linkedin.com/in/',
    label: 'Linkedin',
    icon: 'assets/icons/social/linkedin.svg',
  },
  {
    key: 'youtube',
    slug: 'youtube.com/c/',
    username: undefined,
    src: 'https://youtube.com/c/',
    label: 'Youtube',
    icon: 'assets/icons/social/youtube.svg',
  },
  {
    key: 'tiktok',
    slug: 'tiktok.com/@',
    username: undefined,
    src: 'https://tiktok.com/@',
    label: 'TikTok',
    icon: 'assets/icons/social/tiktok.svg',
  },
  {
    key: 'github',
    slug: 'github.com/',
    username: undefined,
    src: 'https://www.github.com/',
    label: 'Github',
    icon: 'assets/icons/social/github.svg',
  },
  {
    key: 'pinterest',
    slug: 'pinterest.com/',
    username: undefined,
    src: 'https://www.pinterest.com/',
    label: 'Pinterest',
    icon: 'assets/icons/social/pinterest.svg',
  }
]

interface CompSocial {
    slug: string;
    username?: string;
    src: string;
    label: string;
    icon: string;
    key: string
}
