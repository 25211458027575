import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {catchError, Observable, throwError} from "rxjs";
import {RoutePath} from "@app/route-path";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  private handleError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      this.router.navigate([RoutePath.AUTH]);
      return throwError(() => err);
    }
    return throwError(() => err);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err) => this.handleError(err)));
  }
}
