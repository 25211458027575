<div class="wrapper">
  <span mat-dialog-title>Add Channel</span>

  <div mat-dialog-content *ngIf="channels$ | async as channels">
    <mat-selection-list #selectionList color="primary" (click)="selectionListChange(selectionList)">
      <button mat-button (click)="createChannel()">
        <mat-icon>add</mat-icon>
        Create Channel
      </button>
      <mat-list-option *ngFor="let channel of channels" [value]="channel">
        {{ channel.channelName }}
      </mat-list-option>
    </mat-selection-list>
    <button
      [disabled]="loading || selectedChannelIds.length < 1"
      mat-flat-button
      color="primary"
      (click)="addChannelsToNetwork(selectedChannelIds, networkId)"
    >
      <mat-icon *ngIf="loading">
        <mat-spinner [diameter]="24" [strokeWidth]="3"></mat-spinner>
      </mat-icon>
      <mat-icon *ngIf="!loading">send</mat-icon>
      Submit
    </button>
  </div>
</div>
