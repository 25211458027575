import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {AuthenticationService} from "../authentication/authentication.service";
import {DialogService} from "../services/dialog.service";
import {AuthCardComponent} from "../authentication/auth-card/auth-card.component";
import {catchError, from, of} from "rxjs";
import {map, tap} from "rxjs/operators";

export const wizardAuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const dialogService = inject(DialogService);
  const router = inject(Router);

  const isLoggedIn$ = from(authService.isLoggedIn());
  return isLoggedIn$.pipe(
    tap((isLoggedin) => {
      if(!isLoggedin) {
        dialogService.openDialog(AuthCardComponent, {
          redirectUrl: '/wizard/share',
          signing: false
        });
      }
    })
  );
};


