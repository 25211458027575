import {Component, Inject, inject, Input, OnInit} from "@angular/core";
import {IdentityProvider, identityProviders, SupertokenService} from "@app/services/supertoken.service";
import {Observable} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map, tap} from "rxjs/operators";
import {SupertokenSocialSigninUp} from "../../../main";
import {environment} from "../../../environments/environment";
import {Location} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthenticationService, ProviderCallback} from "@app/authentication/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {SnackbarService} from "@app/services/snackbar.service";

@Component({
  selector: 'app-auth-card',
  templateUrl: './auth-card.component.html',
  styleUrls: ['./auth-card.component.scss']
})
export class AuthCardComponent implements OnInit{

  signin= false;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait, Breakpoints.WebPortrait])
    .pipe(map((result) => result.matches));
  signinIn: string | undefined = undefined;
  isLoading = false;
  IdentityProviders = identityProviders;
  path = "";
  @Input() redirectUrl : string | undefined;
  showLegal: false | undefined | 'terms' | 'privacy' = undefined;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private location: Location,
    public dialogRef: MatDialogRef<AuthCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      redirectUrl: string,
      signIn: boolean
    },
    private route: ActivatedRoute,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    if(!this.redirectUrl) {
      this.redirectUrl = this.data.redirectUrl
    }
    this.signin = this.data.signIn;
    this.route.queryParamMap.pipe(
      tap((params) => {
        const isRedirect = (params.get('code') && params.get('state'));
        if(isRedirect) {
          new ProviderCallback(
            this.snackbarService
          ).handleCallback();
        }
      })
    ).subscribe()
  }

  signinup(provider: IdentityProvider) {
    this.isLoading = true;
    this.signinIn = provider.thirdPartyId;
    const defaultRedirect: string = `${environment.baseUrlGui}/${this.location.path()}`;
    const redirect = this.redirectUrl ? `${environment.baseUrlGui}${this.redirectUrl}` : defaultRedirect;
    SupertokenSocialSigninUp(provider, redirect).catch((err) => {
      this.isLoading = false;
      this.signinIn = undefined;
    });
  }
}
