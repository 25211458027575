import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SafePipe} from "./safe.pipe";
import {SignalifyPipe} from "@app/pipes/signalify";
import {ExpansionPanelAssign} from "@app/pipes/ExpansionPanelAssign";



@NgModule({
  declarations: [
    SafePipe,
    SignalifyPipe,
    ExpansionPanelAssign
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    SignalifyPipe,
    ExpansionPanelAssign
  ]
})
export class PipesModule { }
