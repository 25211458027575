import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../../authentication/authentication.service";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import {VIEW_MODE} from "../../../enum";
import {ChannelsService} from "../../../services/channels/channels.service";
import {AppService} from "../../../services/navbar/app.service";
import {finalize, merge, Observable, of} from "rxjs";
import {filter, map, mergeMap, tap} from "rxjs/operators";
import {FormArray, FormControl} from "@angular/forms";
import {CardService} from "@app/services/card.service";
import {environment} from "../../../../environments/environment";
import {createUserStack} from "@app/helpers/helpers";
import {User} from "@app/authentication/user";
import ChannelDTO = DTOs.ChannelDTO;
import Template = DTOs.Template;
import {NetworkService} from "@app/services/network.service";
import NetworkDTO = DTOs.NetworkDTO;
import {Breakpoints} from "../../../../../node_modules/@angular/cdk/layout";
import {isNetworkDTO} from "@app/services/feed/feed.service";
import {FeedContextService} from "@app/services/feed-context.service";
import {Stack} from "@mrbeany/stacks_shared";
import {CardHeader} from "@mrbeany/stacks_shared/lib/models/card-elements/cardheader";

@Component({
  selector: "app-requests",
  templateUrl: "./requests.component.html",
  styleUrls: ["./requests.component.scss"],
})
export class RequestsComponent implements OnInit {
  VIEW_MODE = VIEW_MODE;
  showUserSettings = false;
  joiningChannel = false;

  canJoin$ = this.feedContext.canJoinFeed$;
  user$: Observable<User | undefined> | undefined = this.authenticationService.user$;
  userAvatar$: Observable<string | undefined> | undefined = this.authenticationService.user$.pipe(
    map((user) => user?.avatarImg)
  );
  userName$: Observable<string | undefined> | undefined = this.authenticationService.user$.pipe(
    map((user) => user?.name ? user.name: 'Guest')
  );
  isLoggedIn$: Observable<boolean | undefined> | undefined = this.authenticationService.isLoggedIn$;

  templateConfig$ = this.channelService.selectedChannel$.pipe(
    map((channel) => channel?.config?.template)
  );
  isHandset$ = this.appService.isHandset$;
  selectedTemplate = new FormControl<Template | undefined>(undefined);
  templates = this.channelService.getSelectedChannelSnapshot()?.config?.template?.templates
    ? new Array(this.channelService.getSelectedChannelSnapshot()?.config?.template?.templates)
    : [];
  templateArrayControls = [new FormControl([])];
  templatesArray: FormArray | undefined;
  channel$: Observable<ChannelDTO | undefined> = this.channelService.selectedChannel$;
  network$: Observable<NetworkDTO | undefined> = this.networkService.selectedNetwork$;
  selectedFeed$: Observable<undefined | NetworkDTO | ChannelDTO> = merge(
    this.channel$,
    this.network$
  ).pipe(
    filter((res) => typeof res !== "undefined"),
  );

  createrStack$ = this.channelService.selectedChannel$.pipe(
    mergeMap((channel) => {
      if (channel?.config?.template?.defaultTemplate?.cardId) {
        return this.cardService.getStacks([channel.config.template.defaultTemplate.cardId]).pipe(
          map((stacks) => {
            const newStack = Object.assign(new Stack(), stacks[0]);
            newStack.cards.map((card) => {
              const newCard = card;
              newCard.header = new CardHeader(
                card?.header?.title || '',
                this.authService.getUser().name,
                this.authService.getUser().avatarImg
              )
              return newCard;
            })
            return newStack;
          })
        );
      } else {
        return of(createUserStack(this.authService.getUser() as User));
      }
    })
  );
  isExpanded = false;
  imgPrefix = environment.baseUrlServer + "/images/";
  showCreateCard = false;

  constructor(
    private appService: AppService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private channelService: ChannelsService,
    private cardService: CardService,
    private authService: AuthenticationService,
    private networkService: NetworkService,
    private feedContext: FeedContextService
  ) {
  }

  ngOnInit() {
    this.selectedTemplate.setValue(
      this.channelService.getSelectedChannelSnapshot()?.config?.template.defaultTemplate
    );
  }

  onLogout() {
    this.authenticationService.logout();
  }

  joinChannel() {
    this.joiningChannel = true;
    if (this.channelService.getSelectedChannelSnapshot()) {
      this.channelService
        .joinChannel({
          channelId: this.channelService.getSelectedChannelSnapshot()?.id as string,
          key: undefined,
        })
        .pipe(finalize(() => (this.joiningChannel = false)))
        .subscribe();
    }
    if (this.networkService.getSelectedNetworkSnapshot()) {
      this.networkService
        .joinNetwork({
          networkId: this.networkService.getSelectedNetworkSnapshot()?.id as string,
          key: undefined,
        })
        .pipe(finalize(() => (this.joiningChannel = false)))
        .subscribe();
    }
  }

  protected readonly isNetworkDTO = isNetworkDTO;
}
