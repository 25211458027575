<div class="card">
  <ng-container *ngIf="showLegal">
    <div class="card-elements">
      <div class="terms-of-use-wrapper">
        <app-terms-of-use *ngIf="showLegal === 'terms'"></app-terms-of-use>
        <app-privacy-policy  *ngIf="showLegal === 'privacy'"></app-privacy-policy>
        <button mat-flat-button color="primary" (click)="showLegal = false">
          Close
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showLegal">
    <div class="cardheader-wrapper">
      <div class="cardheader-title">
        <span *ngIf="!signin">Register</span>
        <span *ngIf="signin">Login</span>
      </div>
    </div>
    <p class="terms-disclaimer">
      By logging in or registering you agree to the
      <a (click)="showLegal = 'terms'" class="button underlined-button">Terms of Use</a>
      <br>
      and the
      <a (click)="showLegal = 'privacy'" class="button underlined-button">Privacy Policy</a>
    </p>
    <div class="card-elements">
        <app-signup (navigateToSignIn)="signin = true" *ngIf="!signin" (showLegal)="showLegal = $event"></app-signup>
        <app-signin (navigateToSignUp)="signin = false" *ngIf="signin"></app-signin>
        <app-divider>or</app-divider>
        <div class="flex-buttons">
          <button
            (click)="signinup(IdentityProviders.github)"
            mat-flat-button
            color="accent"
            [disabled]="signinIn !== undefined"
          >
          <span *ngIf="!(signinIn === IdentityProviders.github.thirdPartyId)">
            Continue with Github
          </span>
            <app-loading-button-spinner
              *ngIf="signinIn === IdentityProviders.github.thirdPartyId"
            ></app-loading-button-spinner>
          </button>
          <button
            [disabled]="signinIn !== undefined"
            (click)="signinup(IdentityProviders.google)"
            mat-flat-button
            color="warn"
          >
          <span *ngIf="!(signinIn === IdentityProviders.google.thirdPartyId)">
            Continue with Google
          </span>
            <app-loading-button-spinner
              *ngIf="signinIn === IdentityProviders.google.thirdPartyId"
            ></app-loading-button-spinner>
          </button>
        </div>
    </div>
  </ng-container>
</div>
