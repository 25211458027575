import { Component } from '@angular/core';
import { WizardTemplates } from '../wizard.service';

@Component({
  selector: 'app-wizard-templates',
  templateUrl: './wizard-templates.component.html',
  styleUrls: ['./wizard-templates.component.scss']
})
export class WizardTemplatesComponent {
  protected readonly templates = WizardTemplates;
}
