import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Card, Stack} from "@mrbeany/stacks_shared";
import {User} from "../authentication/user";
import {createUserStack} from "../helpers/helpers";
import {CardService} from "./card.service";
import {AuthenticationService} from "@app/authentication/authentication.service";
import {finalize, map, tap} from "rxjs/operators";
import {CardHeader} from "@mrbeany/stacks_shared/lib/models/card-elements/cardheader";
import {Button} from "@mrbeany/stacks_shared/lib/models/card-elements/button";
import {FeedService} from "@app/services/feed/feed.service";
import {STACK_TYPE} from "@mrbeany/stacks_shared/lib/models/stack";
import {CardNavEvent} from "@app/card.components/card/card.component";

@Injectable({
  providedIn: 'root'
})
export class CardEditorService {

  private stack = new BehaviorSubject<Stack>(createUserStack(this.authenticationService.getUser()));
  private _showEditor = new BehaviorSubject(false)
  stack$ = this.stack.asObservable().pipe(
    map((stack)=>{
      stack.cards[0].id = undefined;
      return stack;
    })
  );
  showEditor$ = this._showEditor.asObservable();
  linkedCard?: Card;
  button?: Button;

  constructor(
    private cardService: CardService,
    private authenticationService: AuthenticationService,
    private feedService: FeedService
  ) { }

  /**
   * card or cardId
   * @param card
   */
  init(stack?: Stack | string) : Promise<any> {
    this.linkedCard = undefined;
    this.button = undefined;

    const newStack = createUserStack(this.authenticationService.getUser());

    return new Promise((resolve, reject) => {
      if(!stack) {
        this.stack.next(newStack);
        resolve(newStack);
      }
      if(typeof stack === 'string') {
        this.cardService.getStacks([stack]).pipe(
          tap((res) => {
            newStack.cards = res[0].cards;
          }),
          finalize(() => {
            this.stack.next(newStack);
            resolve(newStack);
            return;
          })
        ).subscribe();
        return
      }
      if(stack) {
        newStack.cards = stack.cards;
        this.stack.next(newStack)
        resolve(newStack);
        return;
      }
    })
  }

  async sendStack(stack?: Stack, channelId?: string) {
    if (!stack || !channelId) {
      return;
    }
    //case true if editing a linked card
    if (this.linkedCard && this.button) {
      await this.cardService.postStack(stack, channelId).then((_stack) => {
        const savedStack: Stack = _stack.data;
        //@ts-ignore
        this.button.CardID = savedStack.id;
        //@ts-ignore
        this.button.description = savedStack.header?.title || '';
        //@ts-ignore
        this.cardService.editCard(this.linkedCard).then((res) => {
          const _savedStack = Object.assign(new Stack(), res.data);
          this.feedService.updateFeedContent(_savedStack);
        });
        return new Promise((resolve, reject) => {
          resolve(savedStack);
        })
      })
    }
    return this.cardService.postStack(stack, channelId);
  }


  initLinkedCard(card: Card, $event: CardNavEvent) {
    this.button = new Button($event.label, $event.link);
    this.linkedCard = card;
    const newStack = createUserStack(this.authenticationService.getUser());
    newStack.cards = [
      new Card(
        undefined,
        new CardHeader(
          $event.label,
          this.authenticationService.getUser().name,
          this.authenticationService.getUser().avatarImg,
      )),
      card
    ];
    this.stack.next(newStack);
    this._showEditor.next(true);
  }

  showEditor(show: boolean) {
    this._showEditor.next(show);
  }
  updateUser(user: User) {
    const newStack = this.stack.getValue();
    newStack!.cards[0]!.header!.username = user?.name;
    newStack!.cards[0]!.header!.avatarIMG = user?.avatarImg;
    this.stack.next(newStack);
  }
}
