<ng-container *ngIf="columns">
  <cdk-virtual-scroll-viewport
    *ngFor="let column of [].constructor(columns); let columnI = index"
    #scrollContainer
    autosize
    appendOnly
    minBufferPx="0"
    maxBufferPx="1000"
    class="scroll-viewport"
  >

    <!--
    hiding with ngIf instead of class.hidden messes up the virtual viewport for some reason
  -->
    <ng-container
      *cdkVirtualFor="let stack of feed?.state?.stacks!; let i = index; trackBy: trackByFn"
    >
      <div
        class="content"
        *ngIf="i % columns === columnI"
      >
        <app-stack [stack]="stack" [viewStrategy]="ViewModeStrategy.Display"></app-stack>
      </div>
    </ng-container>

    <ng-container *ngIf="columnI === 1 && feed?.state?.stacks" [ngTemplateOutlet]="scrollFooter"></ng-container>
  </cdk-virtual-scroll-viewport>
  <ng-template #scrollFooter class="footer">
    <div class="footer">
      <button mat-ripple class="button create-card-cta card" (click)="isLoading ? '' : loadNextPage.emit()">
    <span *ngIf="!isLoading">
      <div class="add-container">
            <mat-icon>refresh</mat-icon>
      </div>
      Load More
    </span>
        <mat-spinner *ngIf="isLoading" class="button-loading-spinner" diameter="50" strokeWidth="4"></mat-spinner>
      </button>

    </div>
  </ng-template>

</ng-container>
