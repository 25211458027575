<div class="wrapper">
  <div class="header" *ngIf="editing">
    <h4
      #title
      contenteditable="true"
      class="title"
      [class.placeholder]="!emailSubElement.title"
      (click)="emailSubElement.title ? '' : title.innerText = ''"
      (blur)="emailSubElement.title ? '' : title.innerText = 'Add a header'"
      (input)="emailSubElement.title = title.innerText">
      <mat-icon>edit</mat-icon>
    </h4>
    <p
      #description
      contenteditable="true"
      class="subtitle"
      [class.placeholder]="!emailSubElement.description"
      (click)="emailSubElement.description ? '' : description.innerText = ''"
      (blur)="emailSubElement.description ? '' : description.innerText = 'Add a description'"
      (input)="emailSubElement.description = description.innerText">
      <mat-icon>edit</mat-icon>
    </p>
  </div>
  <div class="header" *ngIf="!editing">
    <h4>
      <div class="round-background">
        <mat-icon>email</mat-icon>
      </div>
      {{emailSubElement.title || 'Mailing List'}}
    </h4>
    <p
      class="subtitle"
      *ngIf="emailSubElement.description">
      {{emailSubElement.description}}
    </p>
  </div>
  <div class="email-container">
    <div class="input-container" (input)="emailSubLoadState.set('none')" (keydown.enter)="subscribeEmail(emailFormControl.value)">
      <input [attr.disabled]="editing || emailSubLoadState() === 'loading'" [formControl]="emailFormControl" placeholder="example@email.com" type="text">
      <button *ngIf="emailFormControl.dirty || editing" [disabled]="editing || emailSubLoadState() === 'success'" color="primary" mat-icon-button (click)="subscribeEmail(emailFormControl.value)">
        <mat-icon *ngIf="emailSubLoadState() === 'none'">send</mat-icon>
        <mat-icon *ngIf="emailSubLoadState() === 'success'">check</mat-icon>
        <mat-icon *ngIf="emailSubLoadState() === 'error'">replay</mat-icon>
        <app-loading-button-spinner *ngIf="emailSubLoadState() === 'loading'"></app-loading-button-spinner>
      </button>
    </div>
    <span *ngIf="emailFormControl.invalid && validateEmail">Email not valid</span>
  </div>
  <app-loading-button-spinner *ngIf="csvDownloadState() === 'loading'" color="'white'"></app-loading-button-spinner>
  <button *ngIf="(isOwner$ | async) && csvDownloadState() === 'none'" [disabled]="editing || !emailSubElement.id" mat-button (click)="downloadEmails()">
    Download Email List
    <mat-icon >download</mat-icon>
  </button>
</div>
