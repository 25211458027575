import {Component, OnInit} from '@angular/core';
import {ChannelsService} from '@app/services/channels/channels.service';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {tap} from "rxjs/operators";
import {SupertokenService} from "@app/services/supertoken.service";
import {AuthenticationService, ProviderCallback} from "@app/authentication/authentication.service";
import {SnackbarService} from "@app/services/snackbar.service";
import {DialogService} from "@app/services/dialog.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private dialogService: DialogService
  ) {
    this.matIconRegistry.addSvgIcon(
      'route_closed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/route_closed.svg')
    ),
    this.matIconRegistry.addSvgIcon(
      'menu_add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu_add.svg')
    ),
    this.matIconRegistry.addSvgIcon(
      'menu_hamburger',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu_hamburger.svg')
    )
  }
  ngOnInit() {
    this.authService.updateCurrentUser();
    }

}
