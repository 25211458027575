<div class="card">
  <div class="element">
    <div class="cardheader-wrapper">
      <span class="cardheader-title">Set Username</span>
    </div>
  </div>
  <form [formGroup]="userNameForm">
    <mat-form-field class="element">
      <input matInput placeholder="Username" formControlName="name" />
      <mat-error *ngIf="usernameTaken"> Username is taken </mat-error>
    </mat-form-field>
    <button
      mat-flat-button
      color="primary"
      [disabled]="userNameForm.invalid || savingUsername"
      (click)="onSaveUsername(userNameForm.getRawValue().name)"
    >
      <app-loading-button-spinner *ngIf="savingUsername"></app-loading-button-spinner>
      <span *ngIf="!savingUsername">Save</span>
    </button>
  </form>
</div>
