import {HomeComponent} from "@app/main/home/home.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {TextFieldModule} from "@angular/cdk/text-field";
import {FeedComponent} from "@app/main/home/feed/feed.component";
import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppRoutingModule} from "@app/app-routing.module";
import {LightboxModule} from "ng-gallery/lightbox";
import {SettingsComponent} from "@app/main/home/settings/settings.component";
import {GalleryModule} from "ng-gallery";
import {CarouselComponent} from "@app/custom.components/carousel/carousel.component";
import {SetUsernameComponent} from "@app/custom.components/set-username/set-username.component";
import {LayoutModule} from "@angular/cdk/layout";
import {UserSettingsComponent} from "@app/custom.components/user-settings/user-settings.component";
import {SingleCardViewComponent} from "@app/main/home/single.card.view/single-card-view.component";
import {ChannelComponent} from "@app/main/home/navigation/channel.component";
import {MaterialModule} from "@app/material.dependencies";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatRippleModule} from "@angular/material/core";
import {BrowserModule} from "@angular/platform-browser";
import {RequestsComponent} from "@app/main/home/requests/requests.component";
import {SetProfilePictureButtonComponent} from "@app/custom.components/set-profile-picture-button/set-profile-picture-button.component";
import {AppComponent} from "@app/app.component";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {TabsModule} from "@app/modules/tabs/tabs.module";
import {TypeofPipe} from "@app/pipes/type.pipe";
import {BlobToUrlPipe} from "@app/pipes/blobToUrl";
import {DialogModule} from "@app/modules/dialog/dialog.module";
import {ShareComponent} from "./custom.components/dialogs/share/share.component";
import {AuthInterceptor} from "./interceptors/error.interceptor";
import {Router} from "@angular/router";
import {ChannelInviteComponent} from "./custom.components/dialogs/channel-invite/channel-invite.component";
import {ChannelConfigComponent} from "./custom.components/channel-config/channel-config.component";
import {DesktopViewComponent} from "./main/home/desktop-view/desktop-view.component";
import {MobileViewComponent} from "./main/home/mobile-view/mobile-view.component";
import {FeedOverlayComponent} from "@app/main/home/feed-overlay/feed-overlay.component";
import {CreateNetworkFormComponent} from "./custom.components/forms/create-network-form/create-network-form.component";
import {CreateNetworkDialogComponent} from "./custom.components/dialogs/create-network-dialog/create-network-dialog.component";
import {CreateChannelDialogComponent} from "./custom.components/dialogs/create-channel-dialog/create-channel-dialog.component";
import {CreateChannelFormComponent} from "./custom.components/forms/create-channel-form/create-channel-form.component";
import {SelectNetworkDialogComponent} from "./custom.components/dialogs/select-network-dialog/select-network-dialog.component";
import {SelectChannelDialogComponent} from "@app/custom.components/dialogs/select-channel-dialog/select-channel-dialog.component";
import {NetworkConfigComponent} from "./custom.components/network-config/network-config.component";
import {FilterPipe} from "./pipes/filter.pipe";
import {IncludesPipe} from "@app/pipes/includes.pipe";
import {FindChannelPipe} from "@app/pipes/find.pipe";
import {AuthenticationModule} from "@app/authentication/authentication.module";
import {LoadingButtonSpinnerModule} from "@app/custom.components/loading-button-spinner/loading-button-spinner.module";
import { MessageDialogComponent } from './custom.components/dialogs/message/message-dialog.component';
import * as Sentry from "@sentry/angular-ivy";
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatIconModule} from "@angular/material/icon";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MasonryComponent} from "@app/custom.components/masonry/masonry.component";
import {WelcomeModule} from "@app/pages/welcome/welcome.module";
import {WizardModule} from "@app/pages/wizard/wizard.module";
import {ElementListModule} from "@app/card.components/element-list/element-list.module";
import {PipesModule} from "@app/pipes/pipes.module";
import {StackModule} from "@app/card.components/stack/stack.module";
import {CardModule} from "@app/card.components/card/card.module";
import {CardHeaderModule} from "@app/card.components/card.elements/card-header/card-header.module";
import {CardEditorComponent} from "@app/main/home/requests/create-card/card-editor.component";
import {DirectivesModule} from "@app/directives/directives.module";
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { LoadingPageComponent } from './pages/loading/loading-page/loading-page.component';
import {CardElementsModule} from "@app/card.components/card.elements/card-elements.module";
import { LegalNoticeComponent } from './legal/legal-notice/legal-notice.component';
import {FooterModule} from "@app/main/footer/footer.module";
import { TermsOfUsePageComponent } from './legal/terms-of-use-page/terms-of-use-page.component';
import {TermsOfUseComponent} from "@app/legal/terms-of-use/terms-of-use.component";
import {PlaceholderElementModule} from "@app/core/placeholder/placeholder-element.module";
import {ConfirmationModule} from "@app/modules/confirmation/confirmation.module";
@NgModule({
  declarations: [
    AppComponent,
    FeedComponent,
    RequestsComponent,
    HomeComponent,
    SingleCardViewComponent,
    SettingsComponent,
    CarouselComponent,
    TypeofPipe,
    BlobToUrlPipe,
    ChannelComponent,
    UserSettingsComponent,
    SetUsernameComponent,
    SetProfilePictureButtonComponent,
    ShareComponent,
    ChannelInviteComponent,
    ChannelConfigComponent,
    DesktopViewComponent,
    MobileViewComponent,
    FeedOverlayComponent,
    CreateNetworkFormComponent,
    CreateNetworkDialogComponent,
    CreateChannelDialogComponent,
    CreateChannelFormComponent,
    SelectNetworkDialogComponent,
    SelectChannelDialogComponent,
    NetworkConfigComponent,
    FilterPipe,
    IncludesPipe,
    FindChannelPipe,
    MessageDialogComponent,
    MasonryComponent,
    CardEditorComponent,
    LoadingPageComponent,
    LegalNoticeComponent,
    TermsOfUsePageComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    ScrollingModule,
    CommonModule,
    AppRoutingModule,
    TextFieldModule,
    GalleryModule,
    LightboxModule,
    HttpClientModule,
    TabsModule,
    MatRippleModule,
    DialogModule,
    AuthenticationModule,
    LoadingButtonSpinnerModule,
    NgOptimizedImage,
    DragDropModule,
    MatIconModule,
    MatSlideToggleModule,
    WelcomeModule,
    WizardModule,
    ElementListModule,
    PipesModule,
    StackModule,
    CardModule,
    CardHeaderModule,
    DirectivesModule,
    MatDialogModule,
    CardElementsModule,
    FooterModule,
    PlaceholderElementModule,
    TermsOfUseComponent,
    ConfirmationModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (router: Router) {
        return new AuthInterceptor(router);
      },
      multi: true,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
