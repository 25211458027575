import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-card-body',
  templateUrl: './loading-card-body.component.html',
  styleUrls: ['./loading-card-body.component.scss']
})
export class LoadingCardBodyComponent {

}
