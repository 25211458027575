<h1>Terms of Use</h1>
<p><em>Last Updated: 28.08.2024</em></p>

<p>Welcome to the platform operated by Lars Vink. These Terms of Use ("Terms") govern your access to and use of the platform and services provided by Lars Vink. By accessing or using our platform, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our services.</p>

<h2>1. Acceptance of Terms</h2>
<p>By accessing or using our platform, you confirm that you are at least 18 years of age or have obtained parental or guardian consent to use our platform. You agree to comply with all applicable laws and these Terms when using our platform.</p>

<h2>2. Services Provided</h2>
<p>Our platform provides a software-as-a-service (SaaS) that allows users to create and share micro applications using various blocks, including but not limited to text, images, links to external websites, email subscriptions, Spotify, and social media links.</p>

<h2>3. User Conduct</h2>
<p>You agree not to use the platform for any purpose that is unlawful or prohibited by these Terms. You are solely responsible for your conduct while using our platform and agree to refrain from:</p>
<ul>
  <li>Spamming or engaging in any form of unsolicited communication.</li>
  <li>Harassing, threatening, or otherwise harming other users.</li>
  <li>Posting or sharing hate speech, illegal content, or any content portraying nudity or violence.</li>
</ul>

<h2>4. User-Generated Content</h2>
<p>Our platform allows users to generate content such as images, text, and links to external websites, Spotify, and social networks. You agree that any content you post will adhere to the guidelines outlined in these Terms. Prohibited content includes, but is not limited to:</p>
<ul>
  <li>Nudity</li>
  <li>Violence</li>
  <li>Content that is illegal or violates the rights of others.</li>
</ul>
<p>We reserve the right to remove any content that violates these guidelines at our sole discretion.</p>

<h2>5. Privacy and Data Collection</h2>
<p>We collect and store your email address as part of providing our services. We do not share your personal data with third parties without your consent, except as required by law.</p>
<p>For more information on how we handle your personal data, please refer to our Privacy Policy.</p>

<h2>6. Intellectual Property</h2>
<p>All intellectual property rights in the platform and services are owned by Lars Vink. You may not use any of our trademarks, logos, or proprietary information without our prior written consent.</p>

<h2>7. Limitation of Liability</h2>
<p>To the fullest extent permitted by applicable law, Lars Vink shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the platform or services, even if we have been advised of the possibility of such damages.</p>

<h2>8. Governing Law</h2>
<p>These Terms are governed by and construed in accordance with the laws of Luxembourg. Any disputes arising from these Terms or your use of the platform shall be resolved in the courts of Luxembourg.</p>

<h2>9. Dispute Resolution</h2>
<p>All disputes arising out of or in connection with these Terms or your use of the platform shall first be attempted to be resolved amicably. If a resolution cannot be reached, the dispute shall be submitted to arbitration or mediation in Luxembourg, as we may determine at our discretion.</p>

<h2>10. Changes to the Terms</h2>
<p>We reserve the right to modify these Terms at any time. If we make changes, we will notify you by email. Your continued use of the platform after such notification constitutes your acceptance of the revised Terms.</p>

<h2>11. Termination</h2>
<p>We reserve the right to terminate or suspend your access to the platform at any time, at our sole discretion, without notice or liability, for any reason, including but not limited to a violation of these Terms.</p>

<h2>12. Miscellaneous</h2>
<p>These Terms constitute the entire agreement between you and Lars Vink regarding your use of the platform. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>

<p>If you have any questions about these Terms, please contact us at contact@vinkweb.lu.</p>
