import { Injectable } from '@angular/core';
import {BehaviorSubject, combineLatest, merge, multicast, Observable, repeat, share, shareReplay, Subject} from "rxjs";
import {distinctUntilChanged, filter, map, tap} from "rxjs/operators";
import {ChannelsService} from "./channels/channels.service";
import {NetworkService} from "./network.service";
import { isChannelDTO, isNetworkDTO} from "./feed/feed.service";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ChannelDTO = DTOs.ChannelDTO;
import NetworkDTO = DTOs.NetworkDTO;
import {calculateTake} from "@app/main/home/feed/feed.component";
@Injectable({
  providedIn: 'root'
})
export class FeedContextService {

  _feedContextSnapshot : FeedContext;
  feedLoadingHandler$ = combineLatest([this.channelService.selectedChannel$, this.networkService.selectedNetwork$]).pipe(
    distinctUntilChanged(),
    tap(([channel, network]) => {
      if (channel) {
        this.channelService.loadFeed( channel.id,
          calculateTake(channel?.config?.layout)
        );
      }
      if (!channel && network) {
        this.networkService.loadFeed(
          network.id,
          calculateTake(network?.config?.layout)
        );
      }
    }),
    shareReplay()
  );
  feedContext$ : Observable<ChannelDTO | NetworkDTO | undefined> = merge(
    this.channelService.selectedChannel$,
    this.networkService.selectedNetwork$
  ).pipe(
    tap((res) => {
      this._feedContextSnapshot = res;
    }),
    filter((res) => typeof res !== "undefined"),
    shareReplay()
  )
  canJoinFeed$ = this.feedContext$.pipe(
    map((feed) => {
      if(isNetworkDTO(feed)) {
        return !feed.networkRole && feed?.config?.public
      }
      if(isChannelDTO(feed)) {
        return !feed.channelRole && feed?.config?.public
      }
      return false;
    }),
  );
  layout$ : Observable<string> = this.feedContext$.pipe(
    map((feed) => {
      if(!feed?.config?.layout) {
        return 'feed';
      }
      return feed.config.layout;
    }),
    shareReplay()
  );


  constructor(
    private channelService: ChannelsService,
    private networkService: NetworkService,
  ) {
    this.feedLoadingHandler$.subscribe()
  }
}

export type FeedContext = undefined | ChannelDTO | NetworkDTO
