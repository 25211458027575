<label
  for="avatarElem"
  [class.mdc-button--disabled]="uploading"
  class="mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
>
  <app-loading-button-spinner *ngIf="uploading"></app-loading-button-spinner>
  <span class="mat-button-wrapper" *ngIf="!uploading">
    <ng-content></ng-content>
  </span>
</label>

<input
  (change)="onFileSelected($event)"
  type="file"
  id="avatarElem"
  name="avatarElem"
  accept=".jpg, .jpeg, .png, .webp"
  class="visually-hidden"
/>
