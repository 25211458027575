import {Component, Inject} from "@angular/core";
import {NetworkService} from "@app/services/network.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NetworkApiService} from "@app/services/network-api/network-api.service";
import {ChannelsService} from "@app/services/channels/channels.service";
import {SnackbarService} from "@app/services/snackbar.service";
import {catchError, of} from "rxjs";
import {tap} from "rxjs/operators";
import {DTOs} from "@mrbeany/stacks_shared/lib/dto.module";
import ChannelDTO = DTOs.ChannelDTO;

@Component({
  selector: "app-select-network-dialog",
  templateUrl: "./select-network-dialog.component.html",
  styleUrls: ["./select-network-dialog.component.scss"],
})
export class SelectNetworkDialogComponent {
  networks$ = this.networkService.networks$;
  channelId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private networkService: NetworkService,
    private networkApi: NetworkApiService,
    private channelService: ChannelsService,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<SelectNetworkDialogComponent>
  ) {
    this.channelId = this.data;
  }

  addChannelToNetwork(channelId: string, networkId: string) {
    this.networkApi
      .addChannelsToNetwork(networkId, channelId)
      .pipe(
        tap(() => {
          this.networkService.addChannelsToNetwork(
            [this.channelService.getChannelById(channelId) as ChannelDTO],
            networkId
          );
        }),
        catchError((err) => {
          this.snackbarService.showSnackbar("Could not add Channel");
          return of(err);
        })
      )
      .subscribe();
  }
}
