import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef, EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges, ViewChildren
} from "@angular/core";
import {IdService} from "@app/services/id.service";
import {readAndCompressFiles, readAndCompressProductFiles} from "@app/services/upload.service";
import {ProductElement} from "@mrbeany/stacks_shared/lib/models/card-elements/product";
import {environment} from "../../../../environments/environment";
import {CardNavEvent} from "@app/card.components/card/card.component";

@Component({
  selector: 'app-product-element',
  templateUrl: './product-element.component.html',
  styleUrls: ['./product-element.component.scss']
})
export class ProductElementComponent implements OnInit {
  private _product!: ProductElement;
  @ViewChildren('price') prices?: ElementRef[];
  @Input({required: true}) set product(product: ProductElement) {
    this._product = Object.assign(new ProductElement(), product)
  }
  get product() {
    return this._product;
  }
  @Output() navigateTo  = new EventEmitter<CardNavEvent>();
  imgInputId: string;
  editing = false;
  constructor(
    private idService: IdService,
    private cdr: ChangeDetectorRef
  ) {
    this.imgInputId = this.idService.getId("img").toString();
  }

  //todo: setup polymorphism for card elements
  //this also shows up in app-quill and app-email-sub
  setEditing(editing: boolean) {
    //restores the state of contentEditable inputs
    this.editing = editing;
    setTimeout(() => {
      if(this.editing) {
        if(!this.prices || this.prices.length < 1) {
          return;
        }
        this.prices.forEach((priceEl, index) => {
          priceEl.nativeElement.innerHTML = this.product.products[index].price;
        })
      }
    }, 0)
  }

  @HostListener('click', ['$event'])
  log() {
    this.cdr.detectChanges();
  }


  ngOnInit() {
  }
  onSelectFiles($event: any, index: number) {
    const files: Array<File> = Array.from($event.target["files"]); // images to upload
    this.addImg(files, index);
  }

  onDropFiles($event: any, index: number) {
    $event.preventDefault();
    $event.stopPropagation();
    const files = Array.from($event.dataTransfer.files);
    this.addImg(files, index);
  }

  addProduct() {
    this.product.addProduct();
  }
  removeProduct(index: number) {
    this.product.deleteProduct(index)
  }

  getTemplateContext(index: number) {
    return {
      product: this.product.products[index]
    }
  }
  addImg(files: any, index: number) {
    // images to preview
    readAndCompressProductFiles(files).then((compressedFiles) => {
      const images = compressedFiles
        ?.filter((file) => file instanceof Blob)
        .map((file) => URL.createObjectURL(file as Blob));
      if (images && images.length === files.length) {
        this.product.products[index].img = images[0];
      }
    });
    this.cdr.detectChanges();
  }

  protected readonly environment = environment;
}
