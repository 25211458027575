import {QueryList} from "@angular/core";
import {CardElementComponent} from "@app/card.components/element-list/card-element/card-element.component";
import {VIEW_MODE} from "@app/enum";
import {CARD_ELEMENT_TYPE} from "@mrbeany/stacks_shared/lib/models/card-elements.module";

/**
 * editing refers to if an element is currently being edited
 * canEdit refers to weather the edit button is being shown
 * showEditActions refers to if the edit actions of the edit buttons are shown
 */
class TemlpateStrategy implements ViewStrategy {

  initElementViewMode(cardElementRefs: QueryList<any>, cardElementContainerRefs: QueryList<any>) {
    cardElementRefs.forEach((element: any, index) => {
      element.editing = true;
      if (element?.setEditing) {
        element.setEditing(true);
      }    });
    cardElementContainerRefs.forEach((container: CardElementComponent, index) => {
      container.canEdit = false;
      container.showEditActions.set(false);
    })
  }
  editElement(elementIndex: number, cardElementRefs:  QueryList<any>, cardElementContainerRefs: QueryList<any>) {
    cardElementContainerRefs.forEach((elementContainer: CardElementComponent, index) => {
      elementContainer.showEditActions.set((index === elementIndex));
    });
  }
}

class WizardStrategy implements ViewStrategy {

  initElementViewMode(cardElementRefs: QueryList<any>, cardElementContainerRefs: QueryList<any>) {
    cardElementRefs.forEach((element: any, index) => {
      element.editing = true;
      if (element?.setEditing) {
        element.setEditing(true);
      }    });
    cardElementContainerRefs.forEach((container: CardElementComponent, index) => {
      container.canEdit = true;
      container.showEditActions.set(false);
    })
  }
  editElement(elementIndex: number, cardElementRefs:  QueryList<any>, cardElementContainerRefs: QueryList<any>) {
    cardElementRefs.forEach((element: any, index) => {
      const elementRef = cardElementRefs.get(index);
      element.editing = true;
      if (element?.setEditing) {
        element.setEditing(true);
      }
    });
    cardElementContainerRefs.forEach((elementContainer: CardElementComponent, index) => {
      elementContainer.showEditActions.set(index === elementIndex);
    });
  }
}


class EditorStrategy implements ViewStrategy {
  initElementViewMode(cardElementRefs: QueryList<any>, cardElementContainerRefs: QueryList<any>) {
    cardElementRefs.forEach((element: any, index) => {
      element.editing = false;
      if (element?.setEditing) {
        element.setEditing(false);
      }
    });
    cardElementContainerRefs.forEach((container: CardElementComponent, index) => {
      container.canEdit = true;
    })
  }
  editElement(elementIndex: number, cardElementRefs:  QueryList<any>, cardElementContainerRefs: QueryList<any>) {
    cardElementRefs.forEach((element: any, index) => {
      const elementRef = cardElementRefs.get(index);
      element.editing = (index === elementIndex);
      if (element?.setEditing) {
        element.setEditing(index === elementIndex);
      }
    });
    cardElementContainerRefs.forEach((elementContainer: CardElementComponent, index) => {
      elementContainer.showEditActions.set(index === elementIndex);
    });
  }
}

class DisplayStrategy implements ViewStrategy {

  initElementViewMode(cardElementRefs: QueryList<any>, cardElementContainerRefs: QueryList<any>) {
    cardElementRefs.forEach((element: any, index) => {
      element.editing = false;
    });
    cardElementContainerRefs.forEach((elementContainer: CardElementComponent, index) => {
      elementContainer.canEdit = false;
    });
  }
  editElement(elementIndex: number, cardElementRefs:  QueryList<any>, cardElementContainerRefs: QueryList<any>) {
  }
}

export interface ViewStrategy {
  editElement(elementIndex: number, cardElementRefs:  QueryList<any>, cardElementContainerRefs: QueryList<any>) : void;
  initElementViewMode(cardElementRefs: QueryList<any>, cardElementContainerRefs: QueryList<any>) : void;
}

export class ViewModeStrategy {
  static Wizard: ViewStrategy = new WizardStrategy();
  static Template: ViewStrategy = new TemlpateStrategy();
  static Display: ViewStrategy = new DisplayStrategy();
  static Editor: ViewStrategy = new EditorStrategy();
}

