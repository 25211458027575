import {AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild} from "@angular/core";
import {SnackbarService} from "@app/services/snackbar.service";
import qrcode from "qrcode-generator";
import {CardService, getCardLink} from "@app/services/card.service";
import {tap} from "rxjs/operators";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {catchError, finalize, of} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-share-card',
  templateUrl: './share-card.component.html',
  styleUrls: ['./share-card.component.scss']
})
export class ShareCardComponent implements AfterViewInit, OnInit {

  @ViewChild('urlInput') urlInput!: ElementRef<HTMLInputElement>;

  @Input() address?: string;
  @Input({required: true}) id!: string;
  @Input() title?: string;
  @Input() description?: string;
  link : string | undefined;
  qr = qrcode(0, 'L');
  urlSlug?: string;
  addressStatus : 'default' | 'taken' | 'loading' = 'default';

  //todo: https://qr-code-styling.com/

  defaultTitle = `Ready To Share!`;
  defaultDescription = `Your Appsy is online and waiting for the first visitors.
        Copy the QR Code or the link below to share it with your community.
        Head over to <a routerLink="/home">the main page</a> to discover the Appsy ecosystem.
  `;

  @ViewChild('qrcontainer') qrcontainer!: ElementRef<HTMLDivElement>;
  fetchingAddress =  false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
      {
        id: string,
        title?: string,
        description?: string,
      },
    public dialogRef: MatDialogRef<ShareCardComponent>,
    private snackbar: SnackbarService,
    private cardService: CardService,
  ) {
  }


  ngOnInit() {
    console.log(this.id, this.address);
    if (this.data.id) {
      this.id = this.data.id;
      this.title = this.data.title ? this.data.title : this.title;
      this.description = this.data.description ? this.data.description : this.description;
    }

    if (!this.address) {
      this.fetchingAddress = true;
      this.cardService.fetchContentAddress(this.id)
        .pipe(
          tap((res: {address: string}) => {
            this.fetchingAddress = false;
            if (res.address) {
              this.address = res.address;
              this.link = getCardLink(undefined, this.address);
            }
          })
        )
        .subscribe();
    }

    if (this.id && !this.address) {
      console.log('makincardlink?')
      this.link = getCardLink(this.id)
    }
    if (this.address) {
      this.link = getCardLink(undefined, this.address);
    }
    if (!this.link) {
      return
    }
    this.qr.addData(this.link);
    this.qr.make();
  }

  ngAfterViewInit() {
    this.qrcontainer.nativeElement.innerHTML =  this.qr.createImgTag(8, 0);
    this.qrcontainer.nativeElement.children[0].setAttribute('height', '100%');
    this.qrcontainer.nativeElement.children[0].setAttribute('width', '100%')
  }

  claimAddress(url?: string) {
    if (!url || !this.id) {
      return
    }
    this.addressStatus = 'loading';
    this.cardService.claimAddress(
      url,
      this.id
    )
      .pipe(
        tap((_res: any) => {
          if (_res.url) {
            this.addressStatus = 'default';
            this.address = _res.url;
            this.link = getCardLink(undefined, this.address);
          }
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 409) {
            this.addressStatus = 'taken'
          }
          return of(err);
        })
      )
      .subscribe()  }


  updateUrlSlug(event: any) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    const value = event.target.value;
    const disallowedCharacters: RegExp = new RegExp(/[^a-zA-Z0-9-._~]/g); // Example: Exclude these special characters
    this.urlSlug = value.replace(disallowedCharacters, '');
    if (this.urlSlug) {
      this.urlInput.nativeElement.value = this.urlSlug;
    }
  }

  copyLink(url?: string) {
    if (!url) {
      return;
    }
    navigator.clipboard.writeText(url);
    this.snackbar.showSnackbar('Link copied to Clipboard!');
  }
}
