import {NgModule} from "@angular/core";
import {
  RouterModule,
  Routes,
} from "@angular/router";
import {RoutePath} from "./route-path";
import {AuthenticationComponent} from "./authentication/authentication.component";
import {HomeComponent} from "./main/home/home.component";
import {SettingsComponent} from "./main/home/settings/settings.component";
import {SingleCardViewComponent} from "./main/home/single.card.view/single-card-view.component";
import {WelcomeComponent} from "@app/pages/welcome/welcome.component";
import {WizardComponent} from "@app/pages/wizard/wizard/wizard.component";
import {WizardTemplatesComponent} from "@app/pages/wizard/wizard-templates/wizard-templates.component";
import {WizardBuilderComponent} from "@app/pages/wizard/wizard-builder/wizard-builder.component";
import {WizardShareComponent} from "@app/pages/wizard/wizard-share/wizard-share.component";
import {providerRedirectGuard} from "@app/guards/provider-redirect.guard";
import {wizardAuthGuard} from "@app/guards/wizard-auth.guard";
import {initNavigationGuard} from "@app/guards/init-navigation.guard";
import {LegalNoticeComponent} from "@app/legal/legal-notice/legal-notice.component";
import {PrivacyPolicyComponent} from "@app/legal/privacy-policy/privacy-policy.component";
import {TermsOfUsePageComponent} from "@app/legal/terms-of-use-page/terms-of-use-page.component";

const routes: Routes = [
  {
    path: '',
    canActivate: [initNavigationGuard],
    component: WizardComponent
  },
  {
    path: RoutePath.HOME,
    component: HomeComponent,
    canActivate: [providerRedirectGuard],
  },
  {
    path: RoutePath.WELCOME,
    component: WelcomeComponent,
    canActivate: [providerRedirectGuard],
  },
  {
    path: RoutePath.WIZARD,
    component: WizardComponent,
    canActivate: [providerRedirectGuard],
    children: [
      {
        path: '',
        redirectTo: 'templates',
        pathMatch: 'full'
      },
      {
        path: 'templates',
        component: WizardTemplatesComponent
      },
      {
        path: 'builder',
        component: WizardBuilderComponent
      },
      {
        path: 'share',
        component: WizardShareComponent,
        canActivate: [wizardAuthGuard]
      },
    ]
  },
  {
    path: RoutePath.AUTH,
    component: AuthenticationComponent,
    canActivate: [providerRedirectGuard],
  },
  {
    path: RoutePath.CARD,
    component: SingleCardViewComponent,
    canActivate: [providerRedirectGuard],
  },
  {
    path: RoutePath.SETTINGS,
    component: SettingsComponent,
    canActivate: [providerRedirectGuard],
  },
  {
    path: 'legal-notice',
    component: LegalNoticeComponent,
    canActivate: [providerRedirectGuard],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canActivate: [providerRedirectGuard],
  },
  {
    path: 'terms-of-use',
    component: TermsOfUsePageComponent,
    canActivate: [providerRedirectGuard],
  },
  {path: "**", component: SingleCardViewComponent, pathMatch: "full"},
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false, // only for testing purposes
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
