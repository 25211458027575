import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-external-navigation-dialog',
  templateUrl: './confirm-external-navigation-dialog.component.html',
  styleUrls: ['./confirm-external-navigation-dialog.component.scss']
})
export class ConfirmExternalNavigationDialogComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmExternalNavigationDialogComponent>) {
  }
  url?: string;

  close() {
    this.dialogRef.close();
  }
}
