import {AfterViewInit, Component, ElementRef, Input, ViewChild} from "@angular/core";
import {VIEW_MODE} from "../../../enum";
import Quill  from "quill";
import  RangeStatic  from "quill";

import {QuillElement} from "@mrbeany/stacks_shared/lib/models/card-elements.module";
import {Immediate} from "rxjs/internal/util/Immediate";

@Component({
  selector: 'app-quill',
  templateUrl: './quill.component.html',
  styleUrls: ['./quill.component.scss']
})
export class QuillComponent implements AfterViewInit {

  _editing = false
  @Input() set editing(canEdit: boolean) {
    this.setEditing(canEdit);
  }
  @Input() quillData!: QuillElement;
  quill?: Quill;
  table: any;
  @ViewChild('quillContainer') quillContainer?: ElementRef;
  @ViewChild('quillToolbar') quillToolbar?: ElementRef;
  selectionHistory: any = {
    lastValue: null,
    currentValue: null
  }

  get editing() {
    return this._editing;
  }

  ngAfterViewInit() {
    const toolbarOptions = [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline','link', 'table'],        // toggled buttons
      ['blockquote', 'code-block' ],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                                      // remove formatting button
    ];
    const options : any  = {
      modules: {
        toolbar: this.quillToolbar?.nativeElement,
        table: true,
        history: {          // Enable with custom configurations
          delay: 2500,
          userOnly: true
        },
      },
      placeholder: 'Type something...',
      theme: 'snow'
    };
    if(!this.quillContainer) {
      return
    }
    this.quill = new Quill(this.quillContainer.nativeElement, options);
    if(this.quill) {
      this.quill!.focus();
    }
    if(this.quillData) {
      this.quill.setContents(this.quillData.data);
    }
    this.quill.on("text-change", (delta) => {
      this.quillData.data = this.quill?.getContents();
      const selection = this.quill?.getSelection();
      this.selectionHistory = {
        lastValue: selection ? selection : this.selectionHistory.lastValue,
        currentValue: selection
      }
    });
    this.quill.enable(this._editing);
    //@ts-ignore
    this.table = this.quill?.getModule('table');
    this.quill.getSelection();
    this.quill.on('selection-change', (selection) => {
      this.selectionHistory = {
        lastValue: selection ? selection : this.selectionHistory.lastValue,
        currentValue: selection
      }
    });
  }



  setEditing(editing: boolean) {
    this._editing = editing;
    if(this.quill) {
      this.quill.enable(editing);
    }
  }
  createTable($event: {rows: number; columns: number}) {
    this.quill?.setSelection(this.selectionHistory.lastValue ? this.selectionHistory.lastValue : {index: 0, length: 0})
    //@ts-ignore
    this.table.insertTable($event.rows + 1, $event.columns + 1);
  }
  onDeleteTable() {
    this.setSelection()
    this.table.deleteTable();
  }
  onDeleteColumn() {
    this.setSelection()
    this.table.deleteColumn();
  }

  onAddColumn() {
    this.setSelection()
    this.table.insertColumnRight();
  }

  onDeleteRow() {
    this.setSelection()
    this.table.deleteRow();
  }

  onAddRow() {
    this.setSelection()
    this.table.insertRowBelow();
  }

  setSelection(){
    if(this.selectionHistory.lastValue) {
      this.quill?.setSelection(
        {
          index: this.selectionHistory.lastValue.index,
          length: 0
        }
      )
    } else {
      this.quill?.setSelection(
        {
          index: 0,
          length: 0
        }
      )
    }
  }


  save() {

  }


}
