import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import { WelcomeComponent } from './welcome.component';
import {RouterLink} from "@angular/router";
import {DirectivesModule} from "@app/directives/directives.module";
import {FooterModule} from "@app/main/footer/footer.module";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {LoadingButtonSpinnerModule} from "@app/custom.components/loading-button-spinner/loading-button-spinner.module";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatRippleModule} from "@angular/material/core";



@NgModule({
  declarations: [
    WelcomeComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterLink,
    DirectivesModule,
    FooterModule,
    MatIconModule,
    MatMenuModule,
    LoadingButtonSpinnerModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatRippleModule
  ],
  exports: [
    WelcomeComponent
  ]
})
export class WelcomeModule { }
