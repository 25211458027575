<h2>Delete Images</h2>
<div class="images-list">
  <div class="img-container" (click)="selectImage(i)"  *ngFor="let img of _images; let i = index">
    <mat-checkbox class="checkbox" color="accent" [checked]="img.checked" ></mat-checkbox>
    <img [src]="img.src" alt="">
  </div>
</div>

<div class="buttons-wrapper">
  <button mat-button (click)="onCancel()">
    Close
    <mat-icon>close</mat-icon>
  </button>
  <button mat-flat-button color="primary" (click)="onSave()">
    Delete
    <mat-icon>delete</mat-icon>
  </button>
</div>
