<div class="wrapper">
  <span mat-dialog-title>Subscribe Channel To Networks</span>

  <div mat-dialog-content class="list" *ngIf="networks$ | async as networks">
    <ng-container *ngFor="let network of networks">
      <div class="list-item">
        <span>{{ network.config.name }}</span>
        <button
          mat-flat-button
          color="primary"
          (click)="addChannelToNetwork(channelId, network.id)"
        >
          <mat-icon>send</mat-icon>
          Subscribe
        </button>
      </div>
    </ng-container>
  </div>
</div>
