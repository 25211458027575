<ng-container  *ngIf="viewMode === VIEW_MODE.EDIT">
  <div class="element-actions">
    <div class="element-actions-spacer"></div>
  </div>
  <input [(ngModel)]="file.description" type="text">
</ng-container>

<div class="main" *ngIf="viewMode === VIEW_MODE.DISPLAY">
<div>
  {{ file.description }}
</div>
<div>
  <mat-icon class="download-icon pointer" (click)="startDownload()" *ngIf="!progress"
  >download</mat-icon
  >
  <mat-progress-spinner
    class="spinner"
    *ngIf="progress && progress < 100"
    diameter="20"
    strokeWidth="4"
    [value]="progress"
  ></mat-progress-spinner>
  <mat-icon class="done-icon" *ngIf="progress === 100">done</mat-icon>
</div>
</div>
