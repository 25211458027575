import {Injectable} from "@angular/core";
import {RoutePath} from "../route-path";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {tap} from "rxjs/operators";
import SuperTokens from "supertokens-web-js/lib/build/supertokens";
import Session from 'supertokens-web-js/recipe/session';

@Injectable({
  providedIn: "root",
})
export class SupertokenService {
  constructor(private http: HttpClient) {}

  signout() {
    return this.http.post(environment.baseUrlServer + "/auth/signout", {});
  }
  async isLoggedIn() {
    return Session.doesSessionExist();
  }
}

export interface IdentityProvider {
  thirdPartyId: string;
  callbackUri: string;
}

export const identityProviders = {
  github: {
    thirdPartyId: "github",
    callbackUri: RoutePath.GITHUBCALLBACK,
  },
  google: {
    thirdPartyId: "google",
    callbackUri: RoutePath.GOOGLECALLBACK,
  },
};
