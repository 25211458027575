import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2, ViewChild
} from "@angular/core";

import {Card, Stack} from "@mrbeany/stacks_shared";
import {AuthenticationService} from "../../authentication/authentication.service";
import {VIEW_MODE} from "../../enum";
import {CardNavigationService} from "../../services/card-navigation/card-navigation.service";
import {StackCacheService} from "../../services/card-loader/stack-cache.service";
import {STACK_TYPE} from "@mrbeany/stacks_shared/lib/models/stack";
import {CardService} from "@app/services/card.service";
import {ChannelsService} from "@app/services/channels/channels.service";
import {map, startWith, tap} from "rxjs/operators";
import {BehaviorSubject, catchError, combineLatest, Observable, of} from "rxjs";
import {SnackbarService} from "@app/services/snackbar.service";
import {FeedService} from "@app/services/feed/feed.service";
import {CardEditorService} from "@app/services/card-editor.service";
import {StackService} from "@app/card.components/stack/stack.service";
import {
  ViewModeStrategy,
  ViewStrategy,
} from "@app/card.components/element-list/element-list/viewModeStrategy";
import {FeedContext, FeedContextService} from "@app/services/feed-context.service";
import {
  arrayItemMoveDown,
  arrayItemMoveUp,
  createUserCard
} from "@app/helpers/helpers";
import {AppService} from "@app/services/navbar/app.service";
import {CardComponent, getHeaderActions} from "@app/card.components/card/card.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {ShareCardComponent} from "@app/card.components/stack/share-card/share-card.component";
import {DialogService} from "@app/services/dialog.service";
import {environment} from "../../../environments/environment";
import {ShareComponent} from "@app/custom.components/dialogs/share/share.component";
import {ConfirmationDialogComponent} from "@app/modules/confirmation/confirmation-dialog/confirmation-dialog.component";
import {CardHeader} from "@mrbeany/stacks_shared/lib/models/card-elements/cardheader";

const clone = require("clone");

@Component({
  selector: "app-stack",
  providers: [StackCacheService, CardNavigationService, StackService],
  templateUrl: "./stack.component.html",
  styleUrls: ["./stack.component.scss"],
})
export class StackComponent implements DoCheck, OnInit, AfterViewInit, OnDestroy {
  @Input() set stack(param: Stack | null | undefined) {
    if (!param) {
      return;
    }
    this._stack = Object.assign(new Stack(), param);
    this.stackService.setStack(this._stack);
    this.initialStack = clone(this._stack);
    this._header.next(this._stack.cards[0].header);
  }
  @Input() allowSideMenu = false;
  @Output() navigating = new EventEmitter<string>();
  @Output() cancelEdit = new EventEmitter<void>();
  @Output() clone = new EventEmitter<void>();
  @Output() menuNavigationEvent = new EventEmitter<void>();
  get stack(): Stack {
    return this._stack;
  }
  private _stack!: Stack;
  VIEW_MODE = VIEW_MODE;
  STACK_TYPE = STACK_TYPE;
  editBackup!: Stack;
  initialStack!: Stack;
  postingCard = false;
  currCard$: any;
  menuIndex = 0;
  showPageActions = false;
  _header = new BehaviorSubject<CardHeader | undefined>(undefined);
  header$ = this._header.asObservable();
  cardWidth$: Observable<number> = of(0);


 //weather spotify should preload
  @Input() loadSpotify = false;
  @Input() isLoading = false;
  @Input() hideCreaterButtons = false;
  _viewStrategy = ViewModeStrategy.Display;
  @Input() set viewStrategy(strategy: ViewStrategy | null) {
    if (strategy) {
      this._viewStrategy = strategy;
      this.stackService.setViewStrategy(strategy);
    }
  }
  cardWidth = 0;
  menuOpen$ = this.stackService.menuOpen$;
  headerPermissions$ = combineLatest([this.header$, this.authService.user$]).pipe(
    map(([header, user]) => {
      if (!header) {
        return undefined;
      }
      return getHeaderActions(
        true,
        user,
        header.username,
        this.feedContext._feedContextSnapshot
      )
    })
  );

  constructor(
    public feedContext: FeedContextService,
    public channelService: ChannelsService,
    private cardService: CardService,
    private changeDetectorRef: ChangeDetectorRef,
    private cardNavigationService: CardNavigationService,
    private snackbarService: SnackbarService,
    private feedService: FeedService,
    private editorService: CardEditorService,
    private stackService: StackService,
    private contextService: FeedContextService,
    private authService: AuthenticationService,
    private appService: AppService,
    private dialogService: DialogService,
    private cardEditor: CardEditorService,
  ) {}

  channel$ = this.channelService.selectedChannel$.pipe(tap((res) =>  console.log(res)));
  viewStrat$ = this.stackService.viewStrategy$;
  isHandset$ = this.appService.isHandset$;

  ngOnInit() {
    this.stackService.init(this._viewStrategy);
    this.cardNavigationService.init(this.stack);
    this.changeDetectorRef.markForCheck();
    this.currCard$ = this.cardNavigationService.currCard$
      .pipe(
        tap((content: Stack) => {
          if (!content) {
            return;
          }
          this._stack = content;
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
  }

  getStackService() {
    return this.stackService;
  }
  ngDoCheck() {}

  onEdit() {
    this.editBackup = clone(this.stack);
    this.stackService.setViewStrategy(ViewModeStrategy.Editor);
  }

  onClone() {
    this.cardEditor.init(this._stack);
    this.cardEditor.showEditor(true);
    this.clone.emit();
  }
  sendContent() {
    const config: MatDialogConfig = {
      width: "400",
      minHeight: "500",
      closeOnNavigation: true,
      disableClose: false,
      enterAnimationDuration: "500",
      exitAnimationDuration: "500",
      hasBackdrop: true,
      autoFocus: false,
      restoreFocus: false,
      role: "dialog",
    };
    this.dialogService.openDialog(ShareComponent, {cardID: this.stack.id});
  }

  deleteStack() {
    const channelId = this.channelService.getSelectedChannelSnapshot()?.id;
    const feedContext = this.feedContext._feedContextSnapshot;
    if (channelId === 'my_cards' || !feedContext) {
      this.dialogService.openDialog(ConfirmationDialogComponent,
        {
          message: `Are you sure you want to delete "${this.stack?.cards[0]?.header?.title}"? This can not be undone.`,
        },
        (res) => {
          if (res) {
            this.removeStack('my_cards');
          }
        });
      return;
    }
    if (feedContext.id) {
      this.removeStack(feedContext.id);
    }
  }

  removeStack(channelId: string) {
    this.feedService.deleteStackFromFeed(this.stack);
    this.cardService.removeCard({
      id: this.stack.id as string,
      channelId: channelId,
    });
  }

  onCancelEdit() {
    if (this.editBackup) {
      this.stack = this.editBackup;
    }
    this.menuIndex = 0;
    this.stackService.setViewStrategy(ViewModeStrategy.Display);
    this.cancelEdit.emit();
  }

  menuNavigation(index: number) {
    this.menuIndex = index;
    this.menuNavigationEvent.emit();
    this.stackService.closeMenu();
  }

  shareStack() {
    const config: MatDialogConfig = {
      width: "600px",
      maxWidth: "600",
    };
    this.dialogService.openDialog(
      ShareCardComponent,
      {
        id: this.stack.id,
        title: this.stack.cards[0].header?.title ? this.stack.cards[0].header?.title : "Ready to Share",
        description: this.stack.cards[0].header?.title ? `<h4>Ready to Share</h4>` : "",
      },
      undefined,
      config
    );
  }

  onCopyLink() {
    const link = environment.baseUrlGui + "/card/" + this.stack.id;
    navigator.clipboard.writeText(link);
    this.snackbarService.showSnackbar("Link copied to Clipboard");
  }

  ngOnDestroy() {
    this.currCard$.unsubscribe();
  }

  //todo: sending linked cards should be more explicit
  onSaveCard(stack: Stack) {
    console.log('saving stack');
    this.postingCard = true;
    this.cardService.editCard(stack).then((res) => {
      const savedStack = Object.assign(new Stack(), res.data);
      this.postingCard = false;
      this.stackService.setViewStrategy(ViewModeStrategy.Display);
      this.cardNavigationService.updateNavigationHistoryState(stack);
      this.stack = savedStack;
      this.feedService.updateFeedContent(this.stack);
    });
  }

  onSendStack(stack?: Stack, channelId?: string) {
    console.log('sending stack', stack);
    if (!stack) {
      return;
    }
    this.postingCard = true;
    this.editorService
      .sendStack(stack, channelId)
      ?.then((res: any) => {
        console.log('saved stack', res);
        const savedStack = Object.assign(new Stack(), res.data);
        if (channelId === this.channelService.getSelectedChannelSnapshot()?.id && savedStack) {
          this.feedService.spliceFeed(0, 0, savedStack);
        }
        this.stack = clone(this.initialStack);
      })
      .finally(() => {
        this.postingCard = false;
      });
  }

  deleteCard(index: number) {
    if (this.stack.cards.length === 1) {
      return
    }
    this.stack.cards.splice(index, 1);
    if (this.stack.cards.length - 1 < index) {
      this.menuIndex = 0;
    }
    this.changeDetectorRef.detectChanges();
    this.stack.stackType =  this.stack.cards.length > 1 ? STACK_TYPE.MENU : STACK_TYPE.SINGLE;
  }

  moveCardUp(index: number) {
    arrayItemMoveUp(index, this.stack.cards);
  }

  moveCardDown(index: number) {
    arrayItemMoveDown(index, this.stack.cards);
  }



  onNavigateTo($event: string) {
    const link = $event;
    if (!link) {
      return;
    }
    this.navigating.emit($event);
    if (link) {
      //@ts-ignore
      this.cardNavigationService
        .navigateToStack(link)
        .pipe(
          catchError((err) => {
            this.snackbarService.showSnackbar("Error while loading card");
            return of(err);
          })
        )
        .subscribe();
    }
  }

  protected readonly ViewModeStrategy = ViewModeStrategy;
  sideMenuThreshold = sideMenuThreshold;

  closeStackMenu() {
    this.stackService.closeMenu();
  }

  addCard() {
    this.stack.addCard(createUserCard(this.authService.getUser()));
    this.stack.stackType =  this.stack.cards.length > 1 ? STACK_TYPE.MENU : STACK_TYPE.SINGLE;
  }
}

/**
 * the threshold at wich the stack menmu is shown at the side of the card instead of as an overlay
 */
export const sideMenuThreshold = 850;
