<ng-container *ngIf="(channels$ | async) && (networks$ | async)">
  <app-mobile-view *ngIf="isHandset$ | async"> </app-mobile-view>
  <app-desktop-view *ngIf="!(isHandset$ | async)"></app-desktop-view>
</ng-container>
<ng-container *ngIf="routeNav$ | async"></ng-container>

<div
  *ngIf="!(isLoggedIn$ | async) || !(channels$ | async) || !(networks$ | async)"
  class="loading-screen"
>
  <mat-spinner color="primary" diameter="64" [strokeWidth]="6"></mat-spinner>
</div>
