import {Component, OnInit} from "@angular/core";
import {AppService} from "@app/services/navbar/app.service";
import {Location} from "@angular/common";


@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit{


  constructor(
    private app: AppService,
    private location: Location
  ) {
  }
  isHandset$ = this.app.isHandset$;

  naviagteBack() {
    this.location.back();
  }

  ngOnInit() {

  }
}

