import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {AppService} from "@app/services/navbar/app.service";
import {signIn} from "supertokens-web-js/lib/build/recipe/emailpassword";
import {signOut} from "supertokens-web-js/lib/build/recipe/session";
import {DialogService} from "@app/services/dialog.service";
import {AuthCardComponent} from "@app/authentication/auth-card/auth-card.component";
import {WizardService} from "@app/pages/wizard/wizard.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements AfterViewInit {

  @ViewChild('urlInput') urlInput!: ElementRef<HTMLInputElement>;


  message1 =  getRandomText(questions);
  message2 =  getRandomText(answers);

  urlSlug?: string;


  @ViewChild('messageEl1') messageEl1!: ElementRef<HTMLSpanElement>;
  @ViewChild('messageEl2') messageEl2!: ElementRef<HTMLButtonElement>;

  constructor(
    private app: AppService,
    private dialogService: DialogService,
    private wizardService: WizardService,
    private router: Router
  ) {
  }
  isHandest$ = this.app.isHandset$;

  signUp() {
    this.dialogService.openDialog(AuthCardComponent, {
      redirectUrl: '/wizard',
      signIn: false
    })
  }
  signIn() {
    this.dialogService.openDialog(AuthCardComponent, {
      redirectUrl: '/home',
      signIn: true
    })
  }

  ngAfterViewInit() {
    setInterval(() => {
      this.messageEl1.nativeElement.classList.add('transition-out');
      this.messageEl1.nativeElement.classList.remove('transition-in');
        setTimeout(() => {
          this.messageEl1.nativeElement.classList.add('transition-in');
          this.messageEl1.nativeElement.classList.remove('transition-out');
          let newMessage = getRandomText(questions);
          while (newMessage === this.message1) {
            newMessage = getRandomText(questions);
          }
          this.message1 = newMessage;

        }, 1000);
      }, 6000)
  }

  updateUrlSlug(event: any) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    const value = event.target.value;
    const disallowedCharacters: RegExp = new RegExp(/[^a-zA-Z0-9-._~]/g); // Example: Exclude these special characters
    this.urlSlug = value.replace(disallowedCharacters, '');
    if (this.urlSlug) {
      this.urlInput.nativeElement.value = this.urlSlug;
    }
  }

  claimAppsy(url?: string) {
    this.wizardService.setAppsyUrl(url);
    this.router.navigateByUrl('/wizard');
  }
}
function getRandomText(array: string[]) {
  const newText = array[Math.floor(Math.random() * (array.length))]
  return newText;
}
function filterUrlCharacters(input: string): string {
  // Define a regex pattern that matches only alphanumeric and safe special characters
  const allowedCharactersPattern = /[a-zA-Z0-9-._~]/g;

  // Match the input string against the allowed characters pattern
  const filteredCharacters = input.match(allowedCharactersPattern);

  // If there are matches, join them into a string; otherwise, return an empty string
  return filteredCharacters ? filteredCharacters.join('') : '';
}
const questions = [
  'Need a link-in-bio?',
  'Are you selling T-shirts?',
  'Site for your yoga studio?',
  'Promoting your brand?',
  'Hosting an event?',
  'Just finished your painting?',
  'Dropped your new album?',
  'Celebrating your birthday?',
];
const answers = [
  'Make an Appsy',
];


