<mat-expansion-panel>
  <mat-expansion-panel-header *ngIf="!editing">
    {{ title }}
  </mat-expansion-panel-header>
  <mat-expansion-panel-header *ngIf="editing">
    <ng-content></ng-content>
  </mat-expansion-panel-header>
  <app-element-list
    (navigateTo)="navigateTo.emit($event)"
    [excludeElements]="[CARD_ELEMENT_TYPE.EXPANSION_PANEL]"
    [elements]="expansionPanel.elements"
  ></app-element-list>
</mat-expansion-panel>
