import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChannelsService} from '@app/services/channels/channels.service';
import {tap} from 'rxjs/operators';
import {finalize} from 'rxjs';
import {SnackbarService} from '@app/services/snackbar.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-channel-invite',
  templateUrl: './channel-invite.component.html',
  styleUrls: ['./channel-invite.component.scss'],
})
export class ChannelInviteComponent implements OnInit {
  isLoading = false;
  url: string | undefined;
  channelId!: string | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InviteData,
    public dialogRef: MatDialogRef<ChannelInviteComponent>,
    private channelService: ChannelsService,
    private snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.channelId = this.data.channelId;
    this.isLoading = true;
    this.channelService
      .inviteToChannel(this.channelId)
      .pipe(
        tap((res) => {
          this.url =
            environment.baseUrlGui + '/?channel=' + this.channelId + '&invitation=' + res.key;
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  close() {
    this.dialogRef.close();
  }

  copyLinkToClipbaord(url: string | undefined) {
    if (url) {
      navigator.clipboard.writeText(url);
      this.snackbar.showSnackbar('Link Copied To Clipboard');
    }
  }
}

export interface InviteData {
  channelId: string;
}
