<div class="wrapper">
  <span mat-dialog-title> Share </span>

  <div mat-dialog-content class="list">
    <ng-container *ngFor="let channel of channels | async">
      <div
        *ngIf="channel?.channelRole?.permissions?.postCard && channel.id !== 'my_stacks'"
        class="list-item"
      >
        <span>{{ channel.channelName }}</span>
        <button mat-flat-button color="primary" (click)="sendCardToChannel(cardId, channel.id)">
          <mat-icon>send</mat-icon>
          Send
        </button>
      </div>
    </ng-container>
  </div>
</div>
